import React, {PureComponent} from "react";
import SvgSchool from "../icons/SvgSchool";
import SelectClubListModal from "../../components-recruiter/modals/SelectClubListModal";
import {connect} from "react-redux";
import ClubLogo from "../../components-recruiter/atoms/ClubLogo";
import Checkbox from "../atoms/Checkbox";
import { isFeatureEnabled } from "../../config/featureFlags";
import { MdVerified } from "react-icons/md";
import { APP_ADMINS } from "../../utils/constants";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleClubPeek: () => {
            dispatch({
                type: "TOGGLE_CLUB_PEEK",
            })
        },
        setClubPeekClub: (user) => {
            dispatch({
                type: "SET_CLUB_PEEK_CLUB",
                user,
            })
        },
    }
}

class ClubSearchListItem extends PureComponent {
    state = {
        isSelectClubListModalOpen: false,
    }

    openClubPeek = () => {
        this.props.toggleClubPeek();
        this.props.setClubPeekClub(this.props.item);
    }

    render() {
        const club = this.props.item || {};
        const showVerifiedBadge = isFeatureEnabled('VERIFIED_CLUBS') && club.verified;
        
        return (
            <div
                className={`p-2 cursor-pointer pr-5 hover:bg-slate-50 rounded-xl ${this.props.className}`}
                onClick={this.openClubPeek}
                key={club.id}
            >
                <div className={this.props.grid ? 'col-plain' : 'row-ac-jb'}>
                    <div
                        className={`flex ${this.props.grid ? 'flex-row gap-2 items-center' : 'flex-row items-center'}`}>
                        <div className="flex items-center px-2">
                            <Checkbox checked={this.props.isSelected} onClick={() => this.props.onSelect(club)}/>
                        </div>
                        {!this.props.hideLogo &&
                            <ClubLogo clubId={club.id} size={this.props.grid ? 64 : 88} backupUrl={club.logo_url}
                                      className={'border border-slate-200'}/>
                        }
                        <div className={`${this.props.grid ? 'mt-0' : (!this.props.hideLogo && 'ml-3')} col-jc`}>
                            <div className='mr-2 flex flex-col gap-2'>
                                <div className="flex items-center gap-2">
                                    <p className='body1-bold m-0 text-gray-800'>
                                        {club.name}
                                    </p>
                                    {showVerifiedBadge && (
                                        <MdVerified className="text-blue-500" />
                                    )}
                                </div>
                                <div className='flex flex-row items-center text-neutral-400 gap-1'>
                                    <SvgSchool className={'w-4 h-4'}/>
                                    <p className='body2 m-0'>
                                        {club.college}&nbsp; 
                                        {!!club.founded && `• Est. ${club.founded}`}

                                        {club.num_members != null && APP_ADMINS.includes(this.props.authUser.uid) ? ` • ${club.num_members} member${club.num_members !== 1 ? 's' : ''}` : ''}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={this.props.grid ? 'row-plain items-start relative' : 'flex flex-row items-center gap-8'}>
                    </div>
                </div>
                <SelectClubListModal
                    items={[club]}
                    type={"club"}
                    isOpen={this.state.isSelectClubListModalOpen}
                    authUser={this.props.authUser}
                    closeModal={() => this.setState({isSelectClubListModalOpen: false})}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClubSearchListItem);
