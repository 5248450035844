import React, {Component} from "react";
import RecruiterNavbar from "./components/RecruiterNavbar";
import {Redirect, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import RecruiterDashboard from "./components-recruiter/pages/RecruiterDashboard";
import RecruiterJobListingPage from "./components-recruiter/pages/RecruiterJobListingPage";
import RecruiterJobsPage from "./components-recruiter/pages/RecruiterJobsPage";
import RecruiterEventsPage from "./components-recruiter/pages/RecruiterEventsPage";
import CreateJobModal from "./components-recruiter/modals/CreateJobModal";
import RecruiterCompanyPage from "./components-recruiter/pages/RecruiterCompanyPage";
import CreateEventModal from "./components-recruiter/modals/CreateEventModal";
import RecruiterStudentPeek from "./components-recruiter/peeks/RecruiterStudentPeek";
import RecruiterEnrichPage from "./components-recruiter/pages/RecruiterEnrichPage";
import RecruiterFavoritesPage from "./components-recruiter/pages/RecruiterFavoritesPage";
import RecruiterSearchPage from "./components-recruiter/pages/RecruiterSearchPage";
import RecruiterEngagePage from "./components-recruiter/pages/RecruiterEngagePage";
import RecruiterEventListingPage from "./components-recruiter/pages/RecruiterEventListingPage";
import RecruiterClubPeek from "./components-recruiter/peeks/RecruiterClubPeek";
import MessagesComingSoon from "./components-recruiter/atoms/MessagesComingSoon";
import RecruiterSettingsPage from "./components-recruiter/pages/RecruiterSettingsPage";
import ImageViewerModal from "./components/modals/ImageViewerModal";
import RecruiterSavedClubsPage from "./components-recruiter/pages/RecruiterSavedClubsPage";
import RecruiterEmailPage from "./components-recruiter/pages/RecruiterEmailPage";
import RecruiterStudentProfilePage from "./components-recruiter/pages/RecruiterStudentProfilePage";
import RecruiterFullListPage from "./components-recruiter/pages/RecruiterFullListPage";
import RecruiterJobPage from "./components-recruiter/pages/RecruiterJobPage";
import RecruiterEventPage from "./components-recruiter/pages/RecruiterEventPage";
import RecruiterBulkEmailPage from "./components-recruiter/pages/RecruiterBulkEmailPage";
import RecruiterEmailHistoryPage from "./components-recruiter/pages/RecruiterEmailHistoryPage";
import RecruiterSavedSearchesPage from "./components-recruiter/pages/RecruiterSavedSearchesPage";
import PersonPeek from "./components-club-admin/peeks/PersonPeek";
import RecruiterApplicationPeek from "./components-recruiter/peeks/RecruiterApplicationPeek";
import AdminManagement from "./components/pages/AdminManagement";
import { APP_ADMINS } from "./utils/constants";
import { QualifiedOnlyProvider } from "./contexts/QualifiedOnlyContext";
import RecruiterEngagePageV2 from "./components-recruiter/pages/RecruiterEngagePageV2";

function mapDispatchToProps(dispatch) {
    return {
        toggleCreateJobModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_JOB_MODAL",
            })
        },
        toggleCreateEventModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_EVENT_MODAL",
            })
        },
        toggleStudentPeek: () => {
            dispatch({
                type: "TOGGLE_STUDENT_PEEK",
            })
        },
        closeStudentPeek: () => {
            dispatch({
                type: "CLOSE_STUDENT_PEEK",
            })
        },
        closeClubPeek: () => {
            dispatch({
                type: "CLOSE_CLUB_PEEK",
            })
        },
        toggleClubPeek: () => {
            dispatch({
                type: "TOGGLE_CLUB_PEEK",
            })
        },
        closePersonPeek: () => {
            dispatch({
                type: "SET_PERSON_PEEK_CLOSED",
            })
        },
        openApplicationPeek: (application) => {
            dispatch({
                type: "SET_APPLICATION_PEEK_OPEN",
                application,
            })
        },
        closeApplicationPeek: () => {
            dispatch({
                type: "SET_APPLICATION_PEEK_CLOSED",
            })
        }
    }
}

function mapStateToProps(state) {
    return {
        createJobModalOpen: state.userReducer.createJobModalOpen,
        createEventModalOpen: state.userReducer.createEventModalOpen,
        studentPeekOpen: state.userReducer.studentPeekOpen,
        studentPeekStudent: state.userReducer.studentPeekStudent,
        clubPeekOpen: state.userReducer.clubPeekOpen,
        clubPeekClub: state.userReducer.clubPeekClub,
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        tier: state.userReducer.tier,
        currentClubs: state.userReducer.currentClubs,
        constants: state.userReducer.constants,
        personPeekOpen: state.userReducer.personPeekOpen,
        personPeekPerson: state.userReducer.personPeekPerson,
        applicationPeekOpen: state.userReducer.applicationPeekOpen,
        applicationPeekApplication: state.userReducer.applicationPeekApplication,
    }
}

class RecruiterSideApp extends Component {
    state = {
        studentDrawerOpen: false
    }

    render() {
        const canAccessAdmin = APP_ADMINS.includes(this.props.user?.id) || this.props.user?.company_id === "recruitu";
        return (
            <div className='w-full h-full flex flex-col relative'>
                <RecruiterNavbar/>
                <div className='flex flex-col flex-1 mt-[60px] bg-white overflow-y-auto'>
                    <Switch>
                        <Route exact path="/"
                               render={(routeProps) =>
                                   <RecruiterDashboard {...routeProps}/>
                               }/>
                        <Route exact path="/engage"
                               render={(routeProps) =>
                                   <RecruiterEngagePage {...routeProps}/>
                               }/>
                        <Route exact path="/engage/v2"
                               render={(routeProps) =>
                                   <RecruiterEngagePageV2 {...routeProps}/>
                               }/>
                        <Route exact path="/enrich"
                               render={(routeProps) =>
                                   <RecruiterEnrichPage {...routeProps}/>
                               }/>
                        <Route exact path="/jobs"
                               render={(routeProps) =>
                                   <RecruiterJobsPage {...routeProps}/>
                               }/>
                        <Route exact path="/events"
                               render={(routeProps) =>
                                   <RecruiterEventsPage {...routeProps}/>
                               }/>
                        <Route exact path="/job/:jobId"
                               render={(routeProps) =>
                                   <RecruiterJobPage {...routeProps}/>
                               }/>
                        <Route exact path="/event/:eventId"
                               render={(routeProps) =>
                                   <RecruiterEventPage {...routeProps}/>
                               }/>
                        <Route path="/job-listing"
                               render={(routeProps) =>
                                   <RecruiterJobListingPage {...routeProps}/>
                               }/>
                        <Route path="/event-listing"
                               render={(routeProps) =>
                                   <RecruiterEventListingPage {...routeProps}/>
                               }/>
                        <Route exact path="/candidates"
                               render={(routeProps) =>
                                   <RecruiterFavoritesPage {...routeProps}/>
                               }/>
                        <Route exact path="/settings"
                               render={(routeProps) =>
                                   <RecruiterSettingsPage {...routeProps}/>
                               }/>
                        <Route exact path="/company-page"
                               render={(routeProps) =>
                                   <RecruiterCompanyPage {...routeProps}/>
                               }/>
                        <Route exact path="/messages"
                               render={(routeProps) =>
                                   <MessagesComingSoon {...routeProps}/>
                               }/>
                        <Route path="/search"
                               render={(routeProps) =>
                                   <RecruiterSearchPage {...routeProps}/>
                               }/>
                        <Route exact path="/saved-lists"
                               render={(routeProps) =>
                                   <RecruiterSavedClubsPage {...routeProps}/>
                               }/>
                        <Route exact path="/email"
                               render={(routeProps) =>
                                   <RecruiterEmailPage {...routeProps}/>
                               }/>
                        <Route exact path="/email-history"
                               render={(routeProps) =>
                                   <RecruiterEmailHistoryPage {...routeProps}/>
                               }/>
                        <Route exact path="/saved-searches"
                               render={(routeProps) =>
                                   <RecruiterSavedSearchesPage {...routeProps}/>
                               }/>
                        <Route exact path="/bulk-email"
                               render={(routeProps) =>
                                   <RecruiterBulkEmailPage {...routeProps}/>
                               }/>
                        {canAccessAdmin && (
                            <Route exact path="/admin"
                                   render={(routeProps) =>
                                       <AdminManagement {...routeProps}/>
                                   }/>
                        )}
                        <Route exact path="/list/:type/:listId"
                               render={(routeProps) =>
                                   <RecruiterFullListPage {...routeProps}/>
                               }/>
                        {/*<Route exact path="/studentsearch"*/}
                        {/*       render={(routeProps) =>*/}
                        {/*           <RecruiterStudentSearch {...routeProps}/>*/}
                        {/*       }/>*/}
                        <Route exact path="/profile/:profileId"
                               render={(routeProps) =>
                                   <RecruiterStudentProfilePage {...routeProps}/>
                               }/>
                        <Redirect to="/"/>
                    </Switch>
                </div>
                <QualifiedOnlyProvider>
                    <CreateJobModal
                        isOpen={this.props.createJobModalOpen}
                        closeModal={this.props.toggleCreateJobModal}
                        authUser={this.props.authUser}
                        tier={this.props.tier}
                        currentClubs={this.props.currentClubs}
                        constants={this.props.constants}
                    />
                </QualifiedOnlyProvider>
                <QualifiedOnlyProvider>
                    <CreateEventModal
                        isOpen={this.props.createEventModalOpen}
                        closeModal={this.props.toggleCreateEventModal}
                        authUser={this.props.authUser}
                        recruiter
                        user={this.props.user}
                        tier={this.props.tier}
                        currentClubs={this.props.currentClubs}
                        constants={this.props.constants}
                    />
                </QualifiedOnlyProvider>
                <PersonPeek
                    authUser={this.props.authUser}
                    person={this.props.personPeekPerson}
                    close={this.props.closePersonPeek}
                    open={this.props.personPeekOpen}
                />
                <RecruiterStudentPeek
                    authUser={this.props.authUser}
                    student={this.props.studentPeekStudent}
                    close={this.props.closeStudentPeek}
                    open={this.props.studentPeekOpen}
                />
                <RecruiterApplicationPeek
                    student={this.props.applicationPeekStudent}
                    authUser={this.props.authUser}
                    application={this.props.applicationPeekApplication}
                    close={this.props.closeApplicationPeek}
                    open={this.props.applicationPeekOpen}
                />
                <RecruiterClubPeek
                    authUser={this.props.authUser}
                    club={this.props.clubPeekClub}
                    close={this.props.closeClubPeek}
                    open={this.props.clubPeekOpen}
                />
                <ImageViewerModal/>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterSideApp);
