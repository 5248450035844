import React, {PureComponent} from "react";
import SvgSearch from "../icons/SvgSearch";
import {connect} from "react-redux";
import SvgClose from "../icons/SvgClose";
import JobBoardLoading from "../loading/JobBoardLoading";
import PageHeaderWrapper from "../wrappers/PageHeaderWrapper";
import CompanyEventItem from "../jobboard/CompanyEventItem";
import {NUM_EVENTS_SHOWN} from "../../utils/constants";
import EventPeek from './EventPeek';
import {Button} from "../atoms/Button";
import FadeInOnScroll from "../atoms/FadeInOnScroll";
import { discoverEvents } from "../../api/student/events";
function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
        constants: state.userReducer.constants,
    };
}

class DiscoverEvents extends PureComponent {
    constructor(props) {
        super(props);
        this.headingRef = React.createRef(); // Create a ref to access the component's DOM node
        this.state = {
            listingPreview: {},
            search: '',
            companyId: null,
            companyName: null,
            events: [],
            originalEvents: [],
            eventsLoading: false,
            moreEventsLoading: false,
            listingPreviewOpen: false,
            grid: false,
            hideMoreEvents: false,
            industry: [],
            eventModalOpen: false,
            selectedEvent: null,
            classYear: null,
            roles: [],
            location: null,
            cities: [],
            nextOffset: null,
        };
    }

    openListingPreview = (listing) => {
        this.setState({listingPreview: listing, listingPreviewOpen: true, selectedEvent: listing})
        // this.props.history.push(`/event/${listing?.id}`);
    }

    closeListingPreview = () => {
        this.setState({listingPreviewOpen: false})
    }

    handleSearchChange = (event) => {
        const text = event.target.value;
        this.setState({search: text});
        this.search({text});
    }




    getNextEventInfo = async () => {
        this.setState({moreEventsLoading: true});
        const newEvents = await this.search({shouldLoadMore: true});
        this.setState({
            events: [...this.state.events, ...newEvents],
            moreEventsLoading: false,
            hideMoreEvents: newEvents.length < NUM_EVENTS_SHOWN,
        });
    }

    componentDidMount = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const companyId = queryParams.get("companyId")
        const companyName = queryParams.get("companyName")
        if (!!companyId) {
            this.setState({companyId, companyName})
            this.search({companyId});
        } else {
            this.search({});
        }
    }


    openEventPage = (selectedEvent) => {
        this.props.history.push(`/event/${selectedEvent.id}`);
    }

    toggleGridView = (type) => {
        this.setState({grid: type === 'grid'})
    }

    setCompany = (result) => {
        if (!result.id) {
            this.resetSearch('companyId', null);
        }
        this.setState({
            companyId: result.id,
            companyName: result.name,
        })
        this.search({companyId: result.id});
    }

    resetSearch = (property, value) => {
        this.search({[property]: value});
    }

    updateClassYear = (classYear) => {
       if (classYear === null) {
        this.resetSearch('classYear', classYear);
       }
       this.setState({classYear});
       this.search({ classYear: classYear });
    }

    updateRoles = (role) => {

        if (role === null) {
            this.resetSearch('roles', []);
            this.setState({ roles: [] });
        } else {
            const updatedRoles = this.state.roles.includes(role) ? 
                this.state.roles.filter(item => item !== role) : 
                [...this.state.roles, role];
            this.setState({ roles: updatedRoles });
            this.search({ roles: updatedRoles });
        }
      
    }

    updateLocation = (location) => {
        if (location === null) {
            this.resetSearch('location', null);
        }
        this.setState({location});
        this.search({ location: location });
    }

    updateIndustry = (industry) => {
        if (industry === null) {
            this.resetSearch('industry', []);
            this.setState({ industry: [] });
        } else {
            const updatedIndustry = this.state.industry.includes(industry) ? 
                this.state.industry.filter(item => item !== industry) : 
                [...this.state.industry, industry];
            this.setState({ industry: updatedIndustry });
            this.search({ industry: updatedIndustry });
        }
    }

    updateCities = (city) => {
        if (city === null) {
            this.resetSearch('cities', []);
            this.setState({ cities: [] });
        } else {
            const updatedCities = this.state.cities.includes(city) ? 
                this.state.cities.filter(item => item !== city) : 
                [...this.state.cities, city];
            this.setState({ cities: updatedCities });
            this.search({ cities: updatedCities });
        }
    }


    search = async({
        text,
        location,
        industry,
        roles,
        companyId,
        classYear,
        cities,
        shouldLoadMore = false,
    }) => {
        text = text === undefined ? this.state.search : text;
        location = location === undefined ? this.state.location : location;
        industry = industry === undefined ? this.state.industry : industry;
        roles = roles === undefined ? this.state.roles : roles;
        companyId = companyId === undefined ? this.state.companyId : companyId;
        classYear = classYear === undefined ? this.state.classYear : classYear;
        cities = cities === undefined ? this.state.cities : cities;
  
        const params = {
            query: text,
            location: location,
            industry: industry,
            roles: roles,
            company_id: companyId,
            class_year: classYear,
            cities: cities,
            limit: NUM_EVENTS_SHOWN,
            offset: shouldLoadMore ? this.state.nextOffset : 0,
        }
        const {events, next_offset} = await discoverEvents(this.props.authUser, params)
        this.setState({events, loading: false, nextOffset: next_offset});
        return events;
    }
    


    render() {
        const INDUSTRY_TAGS = this.props.constants.industry_tags.map(tag => ({title: tag, value: tag})); 
        const SECONDARY_TAGS = this.props.constants.secondary_tags.map(tag => ({title: tag, value: tag})); 
        const LOCATION_TAGS = this.props.constants.locations.map(loc => ({title: loc.city})); 
        return (
            <>
                <PageHeaderWrapper
                    className={'pt-2'}
                    search={{
                        placeholder: 'Search events...',
                        value: this.state.search,
                        onChange: this.handleSearchChange,
                    }}
                    selects={[
                        {
                            label: 'Location',
                            value: this.state.cities,
                            setValue: this.updateCities,
                            options:LOCATION_TAGS,
                            multiSelect: true,
                        },
                        {
                            label: 'Event type',
                            value: this.state.location,
                            setValue: this.updateLocation,
                            options: [{title: "Remote",}, {title: "In-person",}]
                        },
                        {
                            label: 'Class year',
                            value: this.state.classYear,
                            setValue: this.updateClassYear,
                            options: [{title: "2025",}, {title: "2026"}, {title: '2027'}, {title: '2028'},]
                        },
                        // {
                        //     label: 'Industry',
                        //     value: this.state.industry,
                        //     setValue: this.updateIndustry,
                        //     options: INDUSTRY_TAGS,
                        //     multiSelect: true,
                        // },
                        // {
                        //     label: 'Roles',
                        //     value: this.state.roles,
                        //     setValue: this.updateRoles,
                        //     options: SECONDARY_TAGS,
                        //     multiSelect: true,
                        // },
                    ]}
                    setCompany={this.setCompany}
                    company={{id: this.state.companyId, name: this.state.companyName}}

                />
                <div className='overflow-hidden flex-1'>
                    <div className={'overflow-y-scroll hide-scrollbar w-full flex-1 pb-5 px-5'}>
                        {this.state.eventsLoading ?
                            <JobBoardLoading/>
                            :
                            this.state.events.length === 0 ?
                                <div className='my-8 flex text-neutral-400 flex-col items-center justify-center flex-1'>
                                    <SvgSearch/>
                                    <p className='body2 mt-2 mb-6'>
                                        No events found...
                                    </p>
                                </div>
                                :
                                <div
                                    className={`flex flex-col gap-3 ${this.state.grid && 'grid grid-cols-3 xl:grid-cols-4 gap-4'}`}>
                                    {this.state.events.map((item, index) =>
                                        <FadeInOnScroll key={item.id} triggerImmediately
                                                        delay={(index * 50) + 10}>
                                            <CompanyEventItem key={item.id} item={item} list={!this.state.grid}
                                                              openEventPage={this.openListingPreview}/>
                                        </FadeInOnScroll>
                                    )}
                                </div>
                        }

                        {(!!this.state.nextOffset && (!this.state.eventsLoading && this.state.events.length !== 0)) &&
                            <div className='flex flex-row items-center justify-center py-8 body2-bold'
                                 onClick={this.getNextEventInfo}>
                                <Button loading={this.state.moreEventsLoading} variant={'ghost'}>
                                    Load more events
                                </Button>
                            </div>
                        }
                    </div>
                    <EventPeek listing={this.state.listingPreview}
                               open={this.state.listingPreviewOpen}
                               close={this.closeListingPreview}
                               authUser={this.props.authUser} event={this.state.selectedEvent}/>
                </div>
            </>
        )
    }
}

export default connect(mapStateToProps)(DiscoverEvents);
