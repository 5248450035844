import React, { useState, useEffect } from "react";
import {wait} from "@testing-library/user-event/dist/utils";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import ProgressStepper from "../atoms/ProgressStepper";
import JobEventQualifications from "./create-event-job/JobEventQualifications";
import {createEvent} from "../../api/recruiter/events";
import CreateEventDetailsStep from "./create-event/CreateEventDetailsStep";
import CreateEventDateAndLocationStep from "./create-event/CreateEventDateAndLocationStep";
import {withRouter} from "react-router-dom";
import SearchCompany from "../../components/addjobflow/SearchCompany";
import {searchCompanies} from "../../utils/typesense";
import {Button} from "../../components/atoms/Button";
import CreateEventPreviewStep from "./create-event/CreateEventPreviewStep";
import { toast } from "react-toastify";
import {stateToHTML} from 'draft-js-export-html';
import {DefaultEditorState} from "../../utils/richEditor";
import { sanitizeHtml } from "../../utils/strings";
import { createJobEventModalParams } from "../../utils/createJobEventModalParams";
import { useQualifiedOnly } from "../../contexts/QualifiedOnlyContext";

const ZERO_STATE = {
    // Basic event info
    step: 0,
    eventTitle: '',
    eventDescription: '',
    editorState: DefaultEditorState,
    showConfirmation: false,
    
    // Event details
    startTime: '10:00',
    endTime: '11:00',
    virtual: false,
    meetingLink: '',
    companyId: '',
    creator: '',
    role: '',
    
    // UI state
    stepLabels: ['Event Details', 'Date and Location', 'Attendees', 'Preview'],
    loading: false,
    numSteps: 4,

    // Date and Location
    address: '',
    city: '',
    state: '',
    date: null,
    rsvpDate: null,
    hasRsvpDate: false,
    
    // Registration settings
    qualifiedOnly: false,
    registerOnRecruit: false,
};

const CreateEventModal = ({ isOpen, closeModal, tier, user, authUser, currentClubs, constants, history }) => {
    const [state, setState] = useState({ ...ZERO_STATE });
    const {filters, selectionMode, lists} = useQualifiedOnly()

    const setInfo = () => {
        const companyId = user.company_id || "";
        setState(prev => ({
            ...prev,
            creator: companyId,
            companyId,
            step: 1,
            clubs: currentClubs
        }));
    };

    useEffect(() => {
        setInfo();
        if (tier === 'blk') {
            setState(prev => ({
                ...prev,
                stepLabels: ['Event Details', 'Date and Location', 'Preview']
            }));
        }
    }, []);

    useEffect(() => {
        if (isOpen) {
            wait(10).then(() => {
                setInfo();
            });
        }
    }, [isOpen]);

    const submit = async () => {
        setState(prev => ({ ...prev, loading: true }));
        const contentState = state.editorState.getCurrentContent();
        const htmlContent = sanitizeHtml(stateToHTML(contentState));
        
        const params = createJobEventModalParams(
            { 
                ...state, 
                htmlContent,
                filters,
                selectionMode,
                lists
            },
            'event',
            tier
        );

        toast.promise(createEvent(authUser, params).then(() => {
            closeModal();
            
            setState(prev => ({ ...prev, loading: false }));
        }).finally(() => {
            history.push("/events");
        }), {
            pending: "Creating event...",
            success: "Event created successfully!",
            error: "Failed to create event."
        });
    };

    const nextStep = () => {
        if (state.step === state.stepLabels.length) {
            setState(prev => ({
                ...prev,
                showConfirmation: true,
            }));
            return;
        }
        setState(prev => ({ ...prev, step: prev.step + 1 }));
    };

    const prevStep = () => {
        if (state.step === 1) {
            closeModal();
            return;
        }
        setState(prev => ({ ...prev, step: prev.step - 1 }));
    };

    const handleInputChange = (e, inputName) => {
        setState(prev => ({ ...prev, [inputName]: e.target.value }));
    };

    const handleSelectChange = (inputName, value) => {
        setState(prev => ({ ...prev, [inputName]: value }));
    };

    const handleQualifiedOnlyChange = (boolean) => {
        setState(prev => ({ ...prev, qualifiedOnly: boolean }));
    };

    const toggleInput = (stateKey) => {
        setState(prev => ({ ...prev, [stateKey]: !prev[stateKey] }));
    };

    const handleDateChange = (e, stateKey) => {
        setState(prev => ({ ...prev, [stateKey]: e }));
    };

    const handleTimeChange = (e, stateKey) => {
        setState(prev => ({ ...prev, [stateKey]: e }));
    };

    const handleVirtualChange = (value) => {
        setState(prev => ({ ...prev, virtual: value }));
    };

    const setCompany = (result) => {
        setState(prev => ({
            ...prev,
            searchResults: [],
            companyId: result.id,
            companyName: result.name,
        }));
        nextStep();
    };

    const companyChanged = async (text) => {
        const searchResults = await searchCompanies(text);
        setState(prev => ({ ...prev, searchResults }));
    };

    const switchDisabled = () => {
        switch (state.step) {
            case 0:
                return true;
            case 1:
                return !state.eventTitle;
            case 2:
                return state.date === null || (state.virtual ? !state.meetingLink : (!state.city || !state.state));
            default:
                return false;
        }
    };

    const handleEditorStateChange = (editorState) => {
        setState(prev => ({ ...prev, editorState }));
    };

    const handleConfirmSubmit = () => {
        setState(prev => ({ ...prev, showConfirmation: false }));
        submit();
    }

    const handleCancelSubmit = () => {
        setState(prev => ({ ...prev, showConfirmation: false }));
    }

    return (
        <>
            <ModalComponent
                isOpen={isOpen}
                backgroundColor={'white'}
                header={'Create A New Event'}
                headerToggle
                size={'lg'}
                toggle={closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button variant={'secondary'} onClick={prevStep}>
                            {state.step === 1 ? "Cancel" : "Back"}
                        </Button>
                        <Button onClick={nextStep} disabled={switchDisabled()} loading={state.loading}>
                            {state.step !== state.stepLabels.length ? "Next" : "Submit"}
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
                showScrollBar
            >
                <div id={'add-joblisting-modal'}>
                    <ProgressStepper
                        step={state.step}
                        stepLabels={state.stepLabels}
                    />
                    {/*Todo: START AT STEP 0 FOR STUDENTS OR NO COMPANY PASSED IN*/}
                    {state.step === 0 &&
                        <SearchCompany
                            nextStep={nextStep}
                            setCompany={setCompany}
                            companyChanged={companyChanged}
                            searchResults={state.searchResults}
                        />
                    }

                    {state.step === 1 &&
                        <CreateEventDetailsStep
                            eventTitle={state.eventTitle}
                            editorState={state.editorState}
                            setEditorState={handleEditorStateChange}
                            role={state.role}
                            classYears={state.classYears}
                            updateClassYears={state.updateClassYears}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            constants={constants}
                        />
                    }
                    {state.step === 2 &&
                        <CreateEventDateAndLocationStep
                            date={state.date}
                            handleDateChange={handleDateChange}
                            startTime={state.startTime}
                            endTime={state.endTime}
                            handleTimeChange={handleTimeChange}
                            virtual={state.virtual}
                            meetingLink={state.meetingLink}
                            address={state.address}
                            state={state.state}
                            city={state.city}
                            registerOnRecruit={state.registerOnRecruit}
                            toggleInput={toggleInput}
                            handleVirtualChange={handleVirtualChange}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            hasRsvpDate={state.hasRsvpDate}
                            rsvpDate={state.rsvpDate}
                        />
                    }
                    {state.step === 3 &&
                        <JobEventQualifications
                            event
                            qualifiedOnly={state.qualifiedOnly}
                            handleQualifiedOnlyChange={handleQualifiedOnlyChange}
                        />
                    }
                    {state.step === 4 &&
                        <CreateEventPreviewStep
                            listingState={{
                                created_at: new Date().toISOString(),
                                event_date: state.date?.toISOString(),
                                class_years: state.classYears,
                                name: state.eventTitle,
                                location: state.virtual ? "Remote" : `${state.city}, ${state.state}`,
                                attendees: [],
                                exclusive: true,
                                industry: state.role.endsWith('Consulting') ? 'Consulting' : 'Finance',
                                secondary_specialties: [state.role],
                                description: sanitizeHtml(stateToHTML(state.editorState.getCurrentContent())),
                            }}
                        />
                    }
                </div>
            </ModalComponent>
            <ModalComponent
                isOpen={state.showConfirmation}
                backgroundColor={'white'}
                header={'Confirm Event Creation'}
                size={'sm'}
                toggle={handleCancelSubmit}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button variant={'secondary'} onClick={handleCancelSubmit}>
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmSubmit} loading={state.loading}>
                            Confirm
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className="p-4 text-center">
                    <p className="mb-4">Are you sure you want to create this event?</p>
                    <p className="text-gray-600">This event will be immediately visible to students on the platform.</p>
                </div>
            </ModalComponent>
        </>
    );
};

export default withRouter(CreateEventModal);
