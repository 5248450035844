import {Button} from "../components/atoms/Button";
import SvgMail from "../components/icons/SvgMail";
import RecruiterMessageStudentModal from "./modals/RecruiterMessageStudentModal";
import {useState} from "react";
import {withRouter} from "react-router-dom";
import LoadingSpinner from "../components/Spinner";


function MessageStudentFlow({user, users, buttonVariant, history, pocs, onEmailBlast, loadingUsers}) {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const navigateToEmail = async () => {   
        let usersToEmailBlast = users;
        if (onEmailBlast) {
            const usersResult = await onEmailBlast();
            usersToEmailBlast = usersResult;
        }
        history.push({
            pathname: `/email`,
            state: {
                user,
                users: usersToEmailBlast
            }
        })
    }
    if (pocs && users && !users.length) return null
    return (
        <div>
            <Button
                variant={buttonVariant || 'default'}
                onClick={(e) => {
                    e.stopPropagation();
                    navigateToEmail();
                }}>
                {loadingUsers ? <LoadingSpinner size={16} /> : <SvgMail className={'w-4 h-4 mr-1'}/>}
                Message {pocs && "Club Points of Contact"}
            </Button>
            <RecruiterMessageStudentModal
                isOpen={isModalOpen}
                closeModal={() => setIsModalOpen(false)}
                user={user}
                users={users}
            />
        </div>
    )
}

export default withRouter(MessageStudentFlow);

