export const filterUsers = (users, filters) => {
    // First filter the users
    const filteredUsers = users.filter(user => {
        // Basic filters
        const nameMatch = !filters.search || (user.first_name + ' ' + user.last_name).toLowerCase().includes(filters.search.toLowerCase());
        
        // Early return if name doesn't match to avoid unnecessary checks
        if (!nameMatch) return false;
        
        // Major filter - check if any of the user's majors match any of the selected filter majors
        const majorMatch = filters.majors.length === 0 || 
            (user.major && filters.majors.some(major => 
                user.major.some(userMajor => userMajor.toLowerCase().includes(major.toLowerCase()))
            ));
            
        // Early return if major doesn't match
        if (!majorMatch) return false;
        
        // Class year filter
        const classYearMatch = filters.classYears.length === 0 || 
            (user.grade && filters.classYears.includes(user.grade));
            
        // Early return if class year doesn't match
        if (!classYearMatch) return false;
        
        // GPA filter
        const gpaMatch = !filters.gpa || 
            (user.gpa && parseFloat(user.gpa) >= parseFloat(filters.gpa));
        
        // Diversity filters
        const genderMatch = !filters.gender || 
            (user.diversity?.gender === filters.gender);
        
        const raceMatch = filters.race.length === 0 || 
            (user.diversity?.race && filters.race.some(race => 
                user.diversity.race.includes(race)
            ));
        
        const veteranMatch = filters.veteran === null || 
            (user.diversity?.veteran === (filters.veteran ? "Yes" : "No"));
        
        const lgbtqMatch = filters.lgbtq === null || 
            (user.diversity?.lgbtq === (filters.lgbtq ? "Yes" : "No"));
        
        const disabledMatch = filters.disabled === null || 
            (user.diversity?.disabled === (filters.disabled ? "Yes" : "No"));
        
        const authorizedMatch = filters.authorized === null || 
            (user.diversity?.authorized === (filters.authorized ? "Yes" : "No"));
        
        // School filter - check if college contains the filter text
        const collegeMatch = !filters.college || 
            (user.college && user.college.toLowerCase().includes(filters.college.toLowerCase()));
        
        // Club filter - check if any of the user's clubs match any of the selected club IDs
        const clubMatch = !filters.clubs.length || 
            (user.clubs && filters.clubs.some(club => 
                user.clubs.includes(club)
            ));
        
        // First generation and sponsorship filters
        const firstGenerationMatch = filters.first_generation === null || 
            (user.diversity?.first_generation === (filters.first_generation ? "Yes" : "No"));
        
        const requireSponsorshipMatch = filters.require_sponsorship === null || 
            (user.diversity?.require_sponsorship === (filters.require_sponsorship ? "Yes" : "No"));

        // Graduation season filter
        const graduationSeasonMatch = !filters.graduationSeason || 
            (user.graduation_season === filters.graduationSeason);
            
        // Current companies filter
        const currentCompaniesMatch = filters.currentCompanies.length === 0 || 
            (user.experiences && user.experiences.some(experience => 
                experience.company && filters.currentCompanies.some(company => 
                    experience.company.toLowerCase().includes(company.toLowerCase())
                )
            ));
            
        // Previous companies filter
        const previousCompaniesMatch = filters.previousCompanies.length === 0 || 
            (user.experiences && user.experiences.some(experience => 
                experience.company && filters.previousCompanies.some(company => 
                    experience.company.toLowerCase().includes(company.toLowerCase())
                )
            ));
            
        // Roles filter (all roles/verticals)
        const verticalsMatch = filters.verticals.length === 0 || 
            (user.experiences && user.experiences.some(experience => 
                experience.title && filters.verticals.some(vertical => 
                    experience.title.toLowerCase().includes(vertical.toLowerCase())
                )
            ));
            
        // Current roles/verticals filter
        const currentVerticalsMatch = filters.previousVerticals.length === 0 || 
            (user.experiences && user.experiences.some(experience => 
                experience.title && filters.previousVerticals.some(vertical => 
                    experience.title.toLowerCase().includes(vertical.toLowerCase())
                )
            ));

        // Compute remaining filters only when needed
        return gpaMatch && genderMatch && raceMatch && veteranMatch && 
               lgbtqMatch && disabledMatch && authorizedMatch && collegeMatch && 
               clubMatch && firstGenerationMatch && requireSponsorshipMatch && 
               graduationSeasonMatch && currentCompaniesMatch && previousCompaniesMatch && 
               verticalsMatch && currentVerticalsMatch;
    });


    
    // Sort by name if searchSortState is active
    if (filters.searchSortState === 1) {
        filteredUsers.sort((a, b) => {
            const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
            const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
            return nameA.localeCompare(nameB);
        });
    } else if (filters.searchSortState === 2) {
        filteredUsers.sort((a, b) => {
            const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
            const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
            return nameB.localeCompare(nameA);
        });
    }
    
    // Sort by school/college if schoolSortState is active
    if (filters.schoolSortState === 1) {
        filteredUsers.sort((a, b) => {
            const collegeA = (a.college || '').toLowerCase();
            const collegeB = (b.college || '').toLowerCase();
            return collegeA.localeCompare(collegeB);
        });
    } else if (filters.schoolSortState === 2) {
        filteredUsers.sort((a, b) => {
            const collegeA = (a.college || '').toLowerCase();
            const collegeB = (b.college || '').toLowerCase();
            return collegeB.localeCompare(collegeA);
        });
    }
    
    return filteredUsers;
};