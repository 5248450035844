import React from "react";
import { Button } from "../../components/atoms/Button";
import SvgClose from "../../components/icons/SvgClose";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import SvgSearchFeather from "../../components/icons/SvgSearchFeather";
import SvgCheck from "../../components/icons/SvgCheck";
import SelectComponent from "../../components/atoms/SelectComponent";
import MultiSelectComponent from "../../components/atoms/MultiSelectComponent";
import MultiSearchSchools from "../../components/search/MultiSearchSchools";
import MultiSearchClubs from "../../components/search/MultiSearchClubs";
import MultiSearchCompanies from "../../components/search/MultiSearchCompanies";
import MultiSearchRace from "../../components/search/MultiSearchRace";
import MultiSearchVerticals from "../../components/search/MultiSearchVerticals";
import MajorAdvancedSelector from "./MajorAdvancedSelector";
import { BsSortAlphaDown, BsSortAlphaDownAlt } from "react-icons/bs";

const defaultTagLimits = {
  schools: 2,
  clubs: 2,
  majors: 2,
  currentCompanies: 2,
  previousCompanies: 2,
  verticals: 2,
  previousVerticals: 2
}

const RecruiterSearchFilters = ({
  // Search
  search,
  
  // Schools
  schools,
  updateCollege,
  toggleSchoolSort,
  
  // Clubs
  clubs,
  setClubs,
  
  // Majors
  majors,
  updateMajor,
  
  // Class Years
  classYears,
  handleFilterClassYearsSelection,
  
  // Graduation Season
  graduationSeason,
  updateGraduationSeason,
  
  // GPA
  gpa,
  setGpaFilter,
  
  // Companies
  currentCompanies,
  setCurrentCompany,
  previousCompanies,
  setPreviousCompany,
  
  // Verticals
  verticals,
  setVerticals,
  previousVerticals,
  setPreviousVerticals,
  
  // Demographics
  gender,
  updateDiversity,
  race,
  updateRace,
  veteran,
  lgbtq,
  disabled,
  authorized,
  first_generation,
  require_sponsorship,
  
  // Other
  clearFilters,
  
  // Sort states
  searchSortState = 0,
  schoolSortState = 0,
  
  // Layout flag
  useFullLayout = false,
  
  // Hide search
  hideSearch = false,

  // Limit
  tagLimits = {}
}) => {

  const tagLimitsMerged = {
    ...defaultTagLimits,
    ...tagLimits
  }

  const {
    schools: schoolsLimit,
    clubs: clubsLimit,
    majors: majorsLimit,
    currentCompanies: currentCompaniesLimit,
    previousCompanies: previousCompaniesLimit,
    verticals: verticalsLimit,
    previousVerticals: previousVerticalsLimit
  } = tagLimitsMerged


  // Helper function for sort icons
  const getSortIcon = (sortState) => {
    switch (sortState) {
      case 1:
        return <BsSortAlphaDown className="text-blue-500" size={20}/>;
      case 2:
        return <BsSortAlphaDownAlt className="text-blue-500" size={20}/>;
      default:
        return <BsSortAlphaDown className="text-slate-400" size={20}/>;
    }
  };

  // Common checkbox component to avoid duplication
  const DiversityCheckbox = ({ field, label, value, onClick }) => {
    const checkboxStyle = useFullLayout 
      ? "flex items-center gap-2 p-2 rounded border border-gray-200 hover:border-gray-300"
      : "flex flex-row items-center base-white-100 w-full";
      
    const checkboxInnerStyle = useFullLayout
      ? `w-4 h-4 flex items-center justify-center rounded border ${value ? 'bg-blue-500 border-blue-500' : 'border-gray-300'}`
      : `${value ? 'checkbox-active' : 'checkbox border-slate-300'} border-[1px] mr-12`;
      
    const labelStyle = useFullLayout
      ? "text-sm text-gray-600"
      : "body2 text-slate-500";
    
    return (
      <button 
        onClick={() => onClick(field)}
        className={checkboxStyle}
      >
        <div className={checkboxInnerStyle}>
          {value && <SvgCheck className={useFullLayout ? "text-white w-3 h-3" : "base-white-100"}/>}
        </div>
        <p className={labelStyle}>
          {label}
        </p>
      </button>
    );
  };

  // Common section header
  const SectionHeader = ({ title }) => {
    return useFullLayout ? (
      <h3 className="text-sm font-medium text-gray-700 mb-3">{title}</h3>
    ) : (
      <p className="text-xs opacity-50">{title}</p>
    );
  };

  // Render education filters
  const renderEducationFilters = () => {
    return (
      <>
        <SectionHeader title="Education" />
        
        <div className={useFullLayout ? "grid grid-cols-2 gap-4" : "flex flex-col gap-2"}>
          <div className={useFullLayout ? "col-span-2" : ""}>
            <MultiSearchSchools
              selectedSchools={schools}
              setSelectedSchools={updateCollege}
              placeholder={useFullLayout ? "Search schools..." : "Search by school..."}
              rightIcon={
                toggleSchoolSort && (
                  <div className="cursor-pointer" role="button" onClick={toggleSchoolSort}>
                    {getSortIcon(schoolSortState)}
                  </div>
                )
              }
              limit={schoolsLimit}

            />
          </div>
          
          <div>
            <MultiSearchClubs
              selectedClubs={clubs}
              setSelectedClubs={setClubs}
              placeholder={useFullLayout ? "Search clubs..." : "Search by club..."}
              disabled={!schools.length}
              limit={clubsLimit}
              colleges={schools}
            />
          </div>
          
          <div>
            <MajorAdvancedSelector
              maxToShow={majorsLimit}
              selectedMajors={majors}
              setSelectedMajors={updateMajor}
            />
          </div>
          
          <MultiSelectComponent
            label="Class Year"
            light
            value={classYears}
            setValue={handleFilterClassYearsSelection}
            clearFilter={() => handleFilterClassYearsSelection(null)}
            options={[{title: "2025"}, {title: "2026"}, {title: "2027"}, {title: "2028"}]}
            multiSelect={true}
            hasCollapse={useFullLayout}
            scrollable={!useFullLayout}
          />
          
          <SelectComponent
            label="Graduation Season"
            light
            value={graduationSeason}
            setValue={updateGraduationSeason}
            clearFilter={() => updateGraduationSeason(null)}
            options={[{title: "Fall"}, {title: "Spring"}]}
            hasCollapse={useFullLayout}
            scrollable={!useFullLayout}
          />
          
          <SelectComponent
            label="Min GPA"
            light
            value={gpa}
            setValue={setGpaFilter}
            clearFilter={() => setGpaFilter(null)}
            options={[
              {title: "3.9"}, {title: "3.8"}, {title: "3.7"}, 
              {title: "3.6"}, {title: "3.5"}, {title: "3.4"}, 
              {title: "3.3"}, {title: "3.2"}, {title: "3.1"}, 
              {title: "3.0"}
            ]}
            hasCollapse={useFullLayout}
            scrollable={!useFullLayout}
          />
        </div>
      </>
    );
  };

  // Render experience filters
  const renderExperienceFilters = () => {
    return (
      <>
        <SectionHeader title="Experience" />
        
        <div className={useFullLayout ? "grid grid-cols-2 gap-4" : "flex flex-col gap-2"}>
          <div className={useFullLayout ? "col-span-2" : ""}>
            <MultiSearchCompanies
              limit={currentCompaniesLimit}
              selectedCompanies={currentCompanies}
              setSelectedCompanies={setCurrentCompany}
              placeholder="Current companies"
            />
          </div>
          
          <div className={useFullLayout ? "col-span-2" : ""}>
            <MultiSearchCompanies
              limit={previousCompaniesLimit}
              selectedCompanies={previousCompanies}
              setSelectedCompanies={setPreviousCompany}
              placeholder="Previous companies"
            />
          </div>
          
          <div className={useFullLayout ? "col-span-2" : ""}>
            <MultiSearchVerticals
              limit={verticalsLimit}
              selectedValues={verticals}
              setSelectedValues={setVerticals}
              placeholder="Current vertical"
            />
          </div>
          
          <div className={useFullLayout ? "col-span-2" : ""}>
            <MultiSearchVerticals
              limit={previousVerticalsLimit}
              selectedValues={previousVerticals}
              setSelectedValues={setPreviousVerticals}
              placeholder="Previous vertical"
            />
          </div>
        </div>
      </>
    );
  };

  // Render demographics filters
  const renderDemographicsFilters = () => {
    return (
      <>
        <SectionHeader title="Demographics" />
        
        <div className={useFullLayout ? "grid grid-cols-2 gap-4" : "flex flex-col gap-2"}>
          <div>
            <SelectComponent
              label="Gender"
              light
              value={gender}
              setValue={(value) => updateDiversity('gender', value)}
              clearFilter={() => updateDiversity('gender', null)}
              options={[{title: "Male"}, {title: "Female"}, {title: "Prefer Not to Specify"}]}
              hasCollapse={useFullLayout}
              scrollable={!useFullLayout}
            />
          </div>
          
          <div>
            <MultiSearchRace
              selectedValues={race}
              setSelectedValues={updateRace}
              hasCollapse={useFullLayout}
            />
          </div>
          
          <div className={useFullLayout ? "col-span-2" : "flex flex-col gap-2"}>
            {useFullLayout ? (
              <div className="grid grid-cols-2 gap-3">
                <DiversityCheckbox field="veteran" label="Veteran" value={veteran} onClick={updateDiversity} />
                <DiversityCheckbox field="lgbtq" label="LGBTQ" value={lgbtq} onClick={updateDiversity} />
                <DiversityCheckbox field="disabled" label="Disability" value={disabled} onClick={updateDiversity} />
                <DiversityCheckbox field="authorized" label={useFullLayout ? "Work Authorization" : "Authorized to work"} value={authorized} onClick={updateDiversity} />
                <DiversityCheckbox field="first_generation" label="First Generation" value={first_generation} onClick={updateDiversity} />
                <DiversityCheckbox field="require_sponsorship" label="Sponsorship Required" value={require_sponsorship} onClick={updateDiversity} />
              </div>
            ) : (
              <>
                <DiversityCheckbox field="veteran" label="Veteran" value={veteran} onClick={updateDiversity} />
                <DiversityCheckbox field="lgbtq" label="LGBTQ" value={lgbtq} onClick={updateDiversity} />
                <DiversityCheckbox field="disabled" label="Disability" value={disabled} onClick={updateDiversity} />
                <DiversityCheckbox field="authorized" label={useFullLayout ? "Work Authorization" : "Authorized to work"} value={authorized} onClick={updateDiversity} />
                <DiversityCheckbox field="first_generation" label="First Generation" value={first_generation} onClick={updateDiversity} />
                <DiversityCheckbox field="require_sponsorship" label="Sponsorship Required" value={require_sponsorship} onClick={updateDiversity} />
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  // Render clear filters button
  const renderClearFilters = () => {
    if (!clearFilters) return null;
    
    return useFullLayout ? (
      <div className="flex justify-end mt-4">
        <Button
          variant="secondary"
          onClick={clearFilters}
          icon={SvgClose}
          iconPosition="left"
          size="sm"
        >
          Clear All Filters
        </Button>
      </div>
    ) : (
      <div className="ml-a">
        <Button
          variant="secondary"
          onClick={clearFilters}
          icon={SvgClose}
          iconPosition="left"
          size="sm"
          className="w-full"
        >
          Clear
        </Button>
      </div>
    );
  };

  // Job layout style
  if (useFullLayout) {
    return (
      <div className="flex flex-col gap-6 p-4">
        {/* Education Section */}
        <div>
          {renderEducationFilters()}
        </div>

        {/* Experience Section */}
        <div>
          {renderExperienceFilters()}
        </div>

        {/* Demographics Section */}
        <div>
          {renderDemographicsFilters()}
        </div>
        
        {renderClearFilters()}
      </div>
    );
  }

  // Original layout
  return (
    <div className="flex flex-col flex-1 overflow-hidden relative">
      <div className="h-12 flex flex-row items-center justify-between">
        <p className="text-lg font-semibold">
          Filters
        </p>
        {renderClearFilters()}
      </div>
      
      <div className="flex flex-col flex-1 pb-20 relative overflow-y-scroll">
        <div className="flex flex-col gap-3 flex-1">
          {/* Name Search */}
          {!hideSearch && (
            <div className="flex flex-col gap-2">
              <p className="text-xs opacity-50">
                Name
              </p>
              <WrappedTextInput
                type="text"
                placeholder={search?.placeholder}
                className="pl-44 input-light body2 font-regular w-full pr-12"
                value={search?.value}
                onChange={search?.onChange}
                icon={<SvgSearchFeather width={20} height={20} className="absolute text-slate-500" style={{left: 12, top: 10}}/>}
                rightIcon={
                  search?.toggleSearchSort ? (
                    <div className="absolute right-3 top-2.5 cursor-pointer" onClick={search?.toggleSearchSort}>
                      {getSortIcon(searchSortState)}
                    </div>
                  ) : null
                }
              />
            </div>
          )}
          
          {/* Education Section */}
          {renderEducationFilters()}
          
          {/* Experience Section */}
          {renderExperienceFilters()}
          
          {/* Demographics Section */}
          {renderDemographicsFilters()}
        </div>
      </div>
    </div>
  );
};

export default RecruiterSearchFilters; 