import axios from '../axios';


export const getRecruiterJobs = async (user, limit) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const url = limit ? `/recruiter/jobs?limit=${limit}` : "/recruiter/jobs";
        const res = await axios.get(url, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getJob = async (user, job_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {job_id}
        };
        const res = await axios.get("/recruiter/job", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const editJob = async (user, job_id, params_to_edit) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit,
            job_id,
        }
        const res = await axios.put("/recruiter/job", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const deleteJob = async (user, job_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {job_id}
        };
        const res = await axios.delete("/recruiter/job", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const createJob = async (user, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/recruiter/jobs", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

