import axios from "../axios";
import qs from "qs";

const DEFAULT_PAGE_LIMIT = 20;

export const getUser = async (user, queried_user_id) => {
    if (!user) {
        return;
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                mode: "no-cors",
                "Access-Control-Allow-Origin": "*",
            },
            params: { queried_user_id },
        };
        const res = await axios.get("/recruiter/users", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

/**
 * @typedef {Object} SearchUsersParams
 * @property {Object} user - The authenticated user object
 * @property {string} name - Optional search query for name filtering
 * @property {number} page - Page number for pagination
 * @property {string[]} clubIds - Array of club IDs to filter by
 * @property {string[]} classYears - Array of class years to filter by
 * @property {string[]} majors - Array of majors to filter by
 * @property {string[]} colleges - Array of colleges to filter by
 * @property {number|null} gpa - GPA to filter by
 * @property {string|null} gender - Gender to filter by
 * @property {string[]} races - Array of races to filter by
 * @property {boolean|null} veteran - Veteran status to filter by
 * @property {boolean|null} lgbtq - LGBTQ status to filter by
 * @property {boolean|null} disabled - Disabled status to filter by
 * @property {boolean|null} authorized - Authorized status to filter by
 * @property {string[]} industries - Array of industries to filter by
 * @property {string[]} currentCompanies - Array of current companies to filter by
 * @property {string[]} previousCompanies - Array of previous companies to filter by
 * @property {boolean|null} isIb - IB status to filter by
 * @property {string} sortBy - Sort By
 * @property {string} searchSortState - search sort state
 * @property {string} schoolSortState - school sort state
 * @property {string[]} verticals - Array of verticals to filter by
 * @property {string[]} previousVerticals - Array of previous verticals to filter by
 * @property {string|null} graduationSeason - Graduation season to filter by
 * @property {boolean|null} firstGeneration - First generation status to filter by
 * @property {boolean|null} requireSponsorship - Sponsorship requirement to filter by
 * @property {number} count - Number of results per page
 */

/**
 * Searches for users based on the provided parameters.
 * @param {SearchUsersParams} params - The search parameters.
 * @returns {Promise<Object>} - Search results with users and total count
 */
export async function searchUsers({
    user,
    name,
    page = 1,
    clubIds = [],
    classYears = [],
    majors = [],
    colleges = [],
    gpa = null,
    gender = null,
    races = [],
    veteran = null,
    lgbtq = null,
    disabled = null,
    authorized = null,
    industries = [],
    sortBy = null,
    currentCompanies = [],
    previousCompanies = [],
    isIb = null,
    searchSortState = null,
    schoolSortState = null,
    verticals = [],
    previousVerticals = [],
    graduationSeason = null,
    firstGeneration = null,
    requireSponsorship = null,
    count = DEFAULT_PAGE_LIMIT
}) {

    if (!user) {
        return;
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                mode: "no-cors",
                "Access-Control-Allow-Origin": "*",
            },
            params: {
                name,
                page,
                club_ids: clubIds,
                class_years: classYears,
                majors,
                colleges: colleges,
                gpa,
                gender,
                races: races,
                veteran,
                lgbtq,
                disabled,   
                authorized,
                industries,
                current_companies: currentCompanies,
                previous_companies: previousCompanies,
                is_ib: isIb,
                search_sort_state: searchSortState,
                school_sort_state: schoolSortState,
                // verticals is the same as roles here, it uses roles because of legacy reasons
                roles: verticals,
                previous_roles: previousVerticals,
                graduation_season: graduationSeason,
                first_generation: firstGeneration,
                require_sponsorship: requireSponsorship,
                count
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        };
        const res = await axios.get("/recruiter/users/search", config);
   
        const users = res.data?.results?.map((user) => user.document) ?? [];
        return {
            students: users,
            total: res.data?.num_items ?? 0,
        };
    } catch (err) {
        console.log(err);
    }
};