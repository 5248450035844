import React, {Component} from "react";
import SvgArrowOutward from "./icons/SvgArrowOutward";
import ModalComponent from "./atoms/modals/ModalComponent";
import {Button} from "./atoms/Button";
import SvgAddCircle from "./icons/SvgAddCircle";
import {toast} from "react-toastify";
import {rsvpEvent} from "../api/student/events";
import {TOAST_OPTIONS} from "../utils/constants";
import {createTracker} from "../api/student/trackers";
import {connect} from "react-redux";
import Tooltip from "./atoms/modals/Tooltip";
import { DateTime } from "luxon";



function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        userId: state.userReducer.user.username,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateTracker: (tracker) => {
            dispatch({
                type: "UPDATE_TRACKER",
                tracker,
            })
        },
        setIsPreApplyModalOpen: () => dispatch({ type: "SET_PRE_APPLY_MODAL_OPEN" }),
    }
}


class RsvpNowButton extends Component {
    state = {
        modalOpen: false
    }

    handleRsvp = async () => {
        this.setState({rsvpProcessing: true});
        try {
            const eventResult = await toast.promise(
                rsvpEvent(this.props.authUser, this.props.event.id, true),
                {
                    pending: 'Saving event to calendar...',
                    success: 'Event saved to calendar',
                    error: 'Failed to save event'
                },
                TOAST_OPTIONS
            );

            if (eventResult) {
                this.setState({rsvp: true, rsvpProcessing: false});
                this.props.event.attendees.push(this.props.authUser.uid);

                const res = await createTracker(this.props.authUser, {type: "event", event_id: this.props.event.id});
                this.props.updateTracker(res.tracker);


              } else {
                this.setState({rsvp: false, rsvpProcessing: false});
            }
        } catch (error) {
            this.setState({rsvp: false, rsvpProcessing: false});
            toast.error('Failed to save event', TOAST_OPTIONS);
        }
    }

    openModal = (e, shouldOpenPreApplyModal) => {
        e.stopPropagation()

        if (shouldOpenPreApplyModal) {
            this.props.setIsPreApplyModalOpen();
            return;
        }
        
        const event = this.props.event || {}
        const attendees = event.attendees || [];
        if (!!attendees.includes(this.props.userId)) {
            this.openLink();
        } else if (event.register_direct) {
            this.handleRsvp();
        } else {
            this.setState({modalOpen: true});
            this.handleRsvp();
        }


    }

    closeModal = () => {
        this.setState({modalOpen: false})
    }

    saveAndApply = async () => {
        this.handleRsvp();
        this.openLink();
    }

    openLink = () => {
        const event = this.props.event || {};
        window.open(event.link, '_blank', 'noreferrer');
        this.closeModal();
    }

    isEnabled = () => {
        const event = this.props.event || {};
        const user = this.props.user;
        const userClubs = user.clubs || [];

        if (!event.qualified_only) return true;
        if (event.schools.length && !event.schools.includes(user.college)) return false;
        if (event.clubs.length && !event.clubs.some(club => userClubs.includes(club))) return false;
        if (event.class_years.length && !event.class_years.includes(user.grade)) return false;


        if (event.rsvp_date) {
            const rsvpDate = DateTime.fromISO(event.rsvp_date);
            if (rsvpDate.isValid && rsvpDate.setZone('UTC').endOf('day') < DateTime.utc().endOf('day')) {
                return false;
            }
        }

        return true;
    }


    render() {
        const event = this.props.event || {};
        const attendees = event.attendees || [];
        const enabled = this.isEnabled();
        const shouldOpenPreApplyModal = (this.props.user?.completed_personalization !== true || !this.props.user?.email_verified )&& !attendees.includes(this.props.userId)
        return (
            <div className='flex flex-1'>
                <Tooltip
                    toggleComponent={
                        <Button
                            onClick={(e) => enabled && this.openModal(e, shouldOpenPreApplyModal)}
                            variant={'secondary'}
                            className={!enabled && 'opacity-50'}
                            >
                            {attendees.includes(this.props.userId) ?
                                'Event Link' : event.register_direct ? 'RSVP Directly on RecruitU' : 'RSVP for Event'}
                            {event.exclusive && !attendees.includes(this.props.userId) ?
                                <SvgAddCircle className={'w-5 h-5'}/>
                                :
                                <SvgArrowOutward className={'w-5 h-5'}/>
                            }
                        </Button>
                    }
                    label={enabled ? "Mark you are attending this event" : 'You are not eligible to attend this event'}
                />
                
                <ModalComponent
                    isOpen={this.state.modalOpen}
                    backgroundColor={'white'}
                    header={'Just one more step!'}//'Would you like to save this event to your calendar first?'}
                    description={`${event.company_name} requires that you also register on an external site. This is where you will receive the meeting link.`}//'RecruitU is designed to help you track and manage all your events. Saving events to your calendar makes them easier to revisit and manage over time.'}
                    headerToggle
                    size={'md'}
                    toggle={this.closeModal}
                    FooterComponent={
                        <div className='flex-row flex flex-1 gap-3'>
                            {/* <button onClick={this.closeModal}
                                    className="secondary-button flex flex-1 body1-bold">Close
                            </button> */}
                            <button
                                onClick={this.openLink}
                                className={`primary-button flex flex-1 body1-bold`}>
                                Take me there
                            </button>
                        </div>
                    }
                >
                </ModalComponent>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RsvpNowButton)
