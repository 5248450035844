import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import Dropzone from "react-dropzone";
import SvgAddCircle from "../../components/icons/SvgAddCircle";
import SvgEdit from "../../components/icons/SvgEdit";
import {editCompany} from "../../api/recruiter/companies";
import {uploadDoc} from "../../api/firebase";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import {INDUSTRY_OPTIONS} from "../../utils/dummy";
import SelectComponent from "../../components/atoms/SelectComponent";
import {wait} from "@testing-library/user-event/dist/utils";
import SvgPlus from "../../components/icons/SvgPlus";
import {connect} from "react-redux";
import {Button} from "../../components/atoms/Button";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}
class RecruiterCompanyEditHeader extends Component {
    state = {
        logo_url: "",
        name: ''
    }

    componentDidMount = () => {
        this.setInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.company !== this.props.company) {
            this.setInfo();
        }
    }

    setInfo = () => {
        const company = this.props.company || {};
        this.setState({
            logoUrl: company.logo_url,
            coverPhoto: company.cover_photo,
            name: company.name,
            website: company.website,
            industry: company.sector,
            founded: company.founded,
            numEmployees: company.num_employees,
        })
    }

    save = async () => {
        await editCompany(this.props.authUser, {
            logo_url: this.state.logoUrl,
            cover_photo: this.state.coverPhoto,
            name: this.state.name,
            website: this.state.website,
            sector: this.state.industry,
            founded: this.state.founded,
            num_employees: this.state.numEmployees,
        });
        this.props.closeModal();
        window.location.reload();
        
    }

    onDrop = async (acceptedFiles, type) => {
        if (type === 'profile_picture') {
            const url = await uploadDoc("companies/profile_picture", this.props.company.id, acceptedFiles[0]);
            this.setState({logoUrl: url});
        } else {
            const url = await uploadDoc("companies/cover_photo", this.props.company.id, acceptedFiles[0]);
            this.setState({coverPhoto: url});
        }
    }

    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    handleSelectChange = (inputName, value) => {
        this.setState({[inputName]: value});
    };

    setSelectNode = () => {
        wait(300).then(() =>
            document.getElementById('edit-company-header-modal')
        )
    }

    render() {
        const company = this.props.company;
        const saveEnabled = this.state.logoUrl !== company.logo_url || company.name !== this.state.name || this.state.coverPhoto !== company.cover_photo || this.state.website !== company.website || this.state.founded !== company.founded || this.state.industry !== company.sector || this.state.numEmployees !== company.num_employees;
        const hasProfilePic = !!this.state.logoUrl;
        const hasCoverPic = !!this.state.coverPhoto;
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Edit company header'}
                headerToggle
                size={'lg'}
                showScrollBar
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.props.closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={!saveEnabled}
                            onClick={this.save}>
                            Save Changes
                        </Button>
                    </div>
                }
                // id={'edit-company-header-modal'}
                footerAlignment={'right'}
            >
                <div className='flex flex-col gap-5' id={'edit-company-header-modal'}>
                    <Dropzone onDrop={(files) => this.onDrop(files, 'cover_photo')}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className='cursor-pointer'>
                                <input {...getInputProps()} />
                                <div>
                                    {hasCoverPic ? (
                                        <div
                                            style={{width: '100%', height: 120}}
                                            className='bg-slate-50 overflow-hidden aspect-square flex flex-col items-center justify-center border border-gray-200 cursor-pointer hover:border-blue-500 rounded-lg'
                                        >
                                            <img
                                                className=' object-contain object-cover'
                                                style={{width: '100%', height: 120}}
                                                src={this.state.coverPhoto}
                                                alt="cover-photo"
                                            />
                                            {/* <div className='absolute flex flex-col items-center justify-center'>
                                                <SvgEdit className={'text-blue-500'}/>
                                                <p className='text-xs mt-2'>
                                                    Edit image
                                                </p>
                                            </div> */}
                                        </div>
                                    ) : (
                                        <div
                                            style={{width: '100%', height: 120}}
                                            className='bg-slate-50 aspect-square flex flex-col items-center justify-center border border-gray-200 cursor-pointer hover:border-blue-500 rounded-lg'
                                        >
                                            <SvgPlus className={'text-blue-500'}/>
                                            <p className='text-xs mt-2'>
                                                Add cover image
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    <Dropzone onDrop={(files) => this.onDrop(files, 'profile_picture')}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className='cursor-pointer'>
                                <input {...getInputProps()} />
                                <div style={{marginTop: -80, marginLeft: 12}} className='mb-5'>
                                    {hasProfilePic ? (
                                        <div
                                            className='h-24 h-24 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square overflow-hidden flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                        >
                                            <img
                                                className='h-24 h-24 object-contain bg-white aspect-square cursor-pointer  rounded-lg'
                                                src={this.state.logoUrl}
                                                alt="profile-pic"
                                            />
                                            {/* <div className='absolute flex flex-col items-center justify-center'>
                                                        <SvgEdit className={'text-blue-500'}/>
                                                        <p className='text-xs mt-2'>
                                                            Edit image
                                                        </p>
                                                    </div> */}
                                        </div>
                                    ) : (
                                        <div
                                            className='h-24 h-24 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                        >
                                            <SvgPlus className={'text-blue-500'}/>
                                            <p className='text-xs mt-2'>
                                                Add logo
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    <WrappedTextInput
                        type="text"
                        outerLabel={'Company Name'}
                        placeholder={'--'}
                        className='input-light px-3 w-full'
                        value={this.state.name}
                        onChange={(e) => this.handleInputChange(e, 'name')}
                    />
                    <WrappedTextInput
                        type="text"
                        outerLabel={'Website URL'}
                        placeholder={'--'}
                        className='input-light px-3 w-full'
                        value={this.state.website}
                        onChange={(e) => this.handleInputChange(e, 'website')}
                    />
                    <SelectComponent
                        className={'flex-1'}
                        outerLabel={'Industry'}
                        label={'ie. Investment Banking'}
                        value={this.state.industry}
                        light
                        setValue={this.handleSelectChange}
                        clearFilter={() => this.handleSelectChange('industry', null)}
                        stateName={'industry'}
                        options={INDUSTRY_OPTIONS}
                        node={document.getElementById('edit-company-header-modal')}

                    />
                    <WrappedTextInput
                        type="text"
                        outerLabel={'Founded'}
                        placeholder={'--'}
                        className='input-light px-3 w-full'
                        value={this.state.founded}
                        onChange={(e) => this.handleInputChange(e, 'founded')}
                    />
                    <WrappedTextInput
                        type="text"
                        outerLabel={'Employees'}
                        placeholder={'--'}
                        className='input-light px-3 w-full'
                        value={this.state.numEmployees}
                        onChange={(e) => this.handleInputChange(e, 'numEmployees')}
                    />
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(RecruiterCompanyEditHeader);
