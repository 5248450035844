import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {editCompany} from "../../api/recruiter/companies";
import {connect} from "react-redux";
import {Button} from "../../components/atoms/Button";
import RichTextEditor from "../../components/atoms/inputs/RichTextEditor";
import {EditorState, ContentState} from "draft-js";
import { sanitizeHtml } from "../../utils/strings";
import { stateToHTML } from 'draft-js-export-html';


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class RecruiterCompanyEditAbout extends Component {
    state = {
        editorState: null,
    }

    handleInputChange = (editorState) => {
        this.setState({ editorState });
        
    };

    save = async () => {
        const editorContent =  sanitizeHtml(stateToHTML(this.state.editorState.getCurrentContent()))
        await editCompany(this.props.authUser, {description: editorContent })
        this.props.closeModal();
        window.location.reload()
        }

    componentDidUpdate = (prevProps) => {
        if (this.props.company !== prevProps.company) {
            this.setInfo();
        }
    }

    componentDidMount = () => {
        this.setInfo();
    }

    setInfo = () => {
        const company = this.props.company || {};
        this.setState({
            editorState: EditorState.createWithContent(
                ContentState.createFromText(company.description || '')
            )
        })
    }

    render() {
        const saveEnabled = this.state.editorState !== null;
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Edit company description'}
                headerToggle
                size={'lg'}
                toggle={this.props.closeModal}
                showScrollBar
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.props.closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={!saveEnabled}
                            onClick={this.save}>
                            Save Changes
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className={`flex flex-col gap-1.5`}>
                    <div className='text-xs font-medium text-slate-500'>
                        Description
                    </div>
                    <div>
                        <RichTextEditor
                            initialHtml={this.props.company.description}
                            editorState={this.state.editorState} 
                            setEditorState={this.handleInputChange}
                        />
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(RecruiterCompanyEditAbout);
