import React, {PureComponent} from "react";
import {connect} from "react-redux";
import ModalComponent from "./ModalComponent";
import {wait} from "@testing-library/user-event/dist/utils";
import {Button} from "../Button";
import DiversityQuestions from "../../personalizationflow/DiversityQuestions";
import IndustryQuestions from "../../personalizationflow/IndustryQuestions";
import PreferenceQuestions from "../../personalizationflow/PreferenceQuestions";
import {editUser, addCompany, getUser} from "../../../api/student/users";
import { COMPANY_FOLLOW_OPTIONS, TOAST_OPTIONS } from "../../../utils/constants";
import { toast } from 'react-toastify';


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}

const ZERO_STATE = {
    gender: '',
    race: [],
    authorized: '',
    requireSponsorship: '',
    veteran: '',
    disabled: '',
    firstGeneration: '',
    lgbtq: '',
    seekingRole: "",
    locations: [],
    industry: [],
    companies: [],
    companyIds: [],
    step: 1,
}
/**
 * @deprecated This component is deprecated and will be removed in a future version.
 * Please use the new edit-profile page instead.
 */
class PersonalizationModal extends PureComponent {
    state = ZERO_STATE

    handleInputChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    };

    handleMultiSelectChange = (inputName, selectedValue) => {
        const array = [...this.state[inputName]];

        let updatedArray;
        if (array.includes(selectedValue)) {
            updatedArray = array.filter((item) => item !== selectedValue);
        } else if (selectedValue === null) {
            updatedArray = [];
        } else {
            updatedArray = [...array, selectedValue]
        }
        this.setState({[inputName]: updatedArray});
    }

    nextStep = () => {
        const newStep = this.state.step + 1
        this.setState({step: newStep})
        if (newStep) {
            }
    }

    previousStep = () => {
        this.setState({step: this.state.step - 1})
    }

    switchNextButtonDisabled = () => {
        switch (this.state.step) {
            case 1:
                return !this.state.locations.length || !this.state.seekingRole
            case 2:
                return !this.state.industry.length
            case 3:
                return false
            case 4:
                return !this.state.gender || !this.state.race.length || !this.state.authorized || !this.state.requireSponsorship || !this.state.disabled || !this.state.veteran || !this.state.firstGeneration || !this.state.lgbtq
            default:
                return true
        }
    }

    closeModal = () => {
        this.props.closeModal();
        this.setState(ZERO_STATE);
    }

    submit = async () => {
        try {
            this.setState({loading: true})
            const params = {
                completed_personalization: true,
                diversity: {
                    gender: this.state.gender,
                    race: this.state.race,
                    authorized: this.state.authorized,
                    require_sponsorship: this.state.requireSponsorship,
                    veteran: this.state.veteran,
                    disabled: this.state.disabled,
                    first_generation: this.state.firstGeneration,
                    lgbtq: this.state.lgbtq,
                },
                locations: this.state.locations,
                seeking_role: this.state.seekingRole !== "Not looking right now",
                job_status: this.state.seekingRole,
                industries: this.state.industry,
                secondary_email: this.state.secondaryEmail,
            }
            await editUser(this.props.authUser, params);
            const user = await getUser(this.props.authUser);

            this.props.setUser(user.user);
            this.setState({loading: false});
            this.closeModal();

            toast.promise(
                Promise.resolve('Demographics and preferences updated!'),
                {
                    pending: 'Updating preferences...',
                    success: 'Demographics and preferences updated!',
                    error: 'Failed to update preferences!',
                },
                TOAST_OPTIONS
            );
        } catch (error) {
            toast.error('Failed to update preferences!', TOAST_OPTIONS);
        }
    }

    setInfo = () => {
        const user = this.props.user;
        const diversity = user.diversity || {};

        const companies = user.companies || [];
        this.setState({
            gender: diversity.gender,
            race: diversity.race || [],
            authorized: diversity.authorized,
            requireSponsorship: diversity.require_sponsorship,
            veteran: diversity.veteran,
            disabled: diversity.disabled,
            firstGeneration: diversity.first_generation,
            lgbtq: diversity.lgbtq,
            locations: user.locations || [],
            seekingRole: user.job_status,
            secondaryEmail: user.secondary_email,
            industry: user.industries,
            step: this.props.skipSteps ? 3: 1,
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            wait(10).then(() => {
                this.setState({step: 3});
                this.setInfo();
            })
        }
    }


    handleSelectChange = (inputName, value) => {
        this.setState({[inputName]: value});
    };

    handleEmailChange = (e) => {
        this.setState({secondaryEmail: e.target.value})
    }

    switchTitle = () => {
        switch (this.state.step) {
            case 3:
                return ""
            case 4:
                return "Equal Employment Data"
            default:
                return "Preferences"
        }
    }

    render() {
        const numSteps = 4
        const progressWidth = (this.state.step / numSteps) * 100;
        return (
            <ModalComponent isOpen={this.props.isOpen}
                            header={this.switchTitle()}
                            description={this.state.step !== 3 ? 'Add more about you and what you\'re looking for to make the job hunt easier.' : null}
                            backgroundColor={'white'}
                            headerToggle
                            size={'lg'}
                            toggle={this.closeModal}
                            FooterComponent={
                                <div className='flex flex-row items-center justify-end gap-3'>
                                    {this.state.step === 1 ?
                                        <Button variant={'secondary'} onClick={this.closeModal}>
                                            Close
                                        </Button>
                                        :
                                        <Button variant={'secondary'} onClick={this.previousStep}>
                                            Back
                                        </Button>
                                    }
                                    {this.state.step === numSteps ?
                                        <>
                                            <Button onClick={this.submit} disabled={this.switchNextButtonDisabled()} loading={this.state.loading}>
                                                Submit
                                            </Button>
                                        </>
                                        :
                                        <Button onClick={this.nextStep}
                                                disabled={this.switchNextButtonDisabled()}>
                                            {this.state.step === 3 ? "Complete my Profile" : "Next"}
                                        </Button>
                                    }
                                </div>
                            }
                            footerAlignment={'right'}
                            id={'personalization-modal'}
            >
                <div id={'personalization-modal'}>
                    <div className='px-4 py-4 flex flex-col gap-5 min-h-[20vh] justify-center'>
                        {this.state.step === 1 &&
                            <IndustryQuestions
                                handleSelectChange={this.handleSelectChange}
                                handleMultiSelectChange={this.handleMultiSelectChange}
                                locations={this.state.locations}
                                seekingRole={this.state.seekingRole}
                            />
                        }
                        {this.state.step === 2 &&
                            <PreferenceQuestions
                                handleSelectChange={this.handleSelectChange}
                                handleMultiSelectChange={this.handleMultiSelectChange}
                                industry={this.state.industry}
                                companies={this.state.companies}
                            />
                        }

                        {this.state.step === 3 &&
                            <div className="flex flex-col items-center justify-center text-center py-8">
                                <h2 className="text-2xl font-bold mb-4">Almost there - just a few more questions to unlock all RecruitU features!</h2>
                                <p className="text-lg mb-8">
                                    This will help us better personalize your experience.
                                </p>
                            </div>
                        }

                        {this.state.step === 4 &&
                            <DiversityQuestions
                                gender={this.state.gender}
                                race={this.state.race}
                                authorized={this.state.authorized}
                                requireSponsorship={this.state.requireSponsorship}
                                veteran={this.state.veteran}
                                disabled={this.state.disabled}
                                firstGeneration={this.state.firstGeneration}
                                lgbtq={this.state.lgbtq}
                                handleMultiSelectChange={this.handleMultiSelectChange}
                                handleSelectChange={this.handleSelectChange}
                                handleEmailChange={this.handleEmailChange}
                                secondaryEmail={this.state.secondaryEmail}
                            />
                        }
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalizationModal)
