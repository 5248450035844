import React, {Component} from "react";
import {APPLICANTS_TABLE_HEADERS, EVENTS_TABLE_HEADERS} from "../../utils/dummy";
import RecruiterJobPreviewCard from "../RecruiterJobPreviewCard";
import SectionHeader from "../SectionHeader";
import RecruiterEventPreviewCard from "../RecruiterEventPreviewCard";
import RecruiterDashboardTable from "../dashboard/RecruiterDashboardTable";
import RecruiterDashboardTableItem from "../dashboard/RecruiterDashboardTableItem";
import {getRecruiterEvents, getRecruiterRecentAttendees} from "../../api/recruiter/events";
import {connect} from "react-redux";
import SvgPlus from "../../components/icons/SvgPlus";
import {getRecruiterJobs} from "../../api/recruiter/jobs";
import DashboardLoading from "../atoms/DashboardLoading";
import NewJobOrEventCard from "../atoms/NewJobOrEventCard";
import {Button} from "../../components/atoms/Button";
import SvgSearchFeather from "../../components/icons/SvgSearchFeather";
import SvgBriefcase from "../../components/icons/SvgBriefcase";
import SvgUserAdd from "../../components/icons/SvgUserAdd";
import SvgEdit2 from "../../components/icons/SvgEdit2";
import SvgUserCheck from "../../components/icons/SvgUserCheck";
import { getSavedSearches } from "../../api/recruiter/saved_searches";
import SvgCalendar from "../../components/icons/SvgCalendar";
import {getCompany, getCompanyStats} from "../../api/recruiter/companies";
import {RecruiterDashboardSummary} from "../dashboard/RecruiterDashboardSummary";
import SavedSearchCard from "../SavedSearchCard";

const maxApplicants = 5;

function mapDispatchToProps(dispatch) {
    return {
        toggleCreateJobModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_JOB_MODAL",
            })
        },
        toggleCreateEventModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_EVENT_MODAL",
            })
        },
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

class RecruiterDashboard extends Component {
    state = {
        applications: [],
        events: [],
        recentAttendees: [],
        eventsLoading: true,
        jobsLoading: true,
        jobs: [],
        recentApplicants: [],
        stats: {},
        savedSearches: [],
        eventsCount: 0
    }

    componentDidMount = () => {
        this.refreshDashboard();
    }

    refreshDashboard = async () => {    
        const limit = this.getMaxCardsPerScreen();

        const [
            companyRes,
            statsRes,
            searches,
            jobsResult,
            eventsRes,
            recentAttendees
        ] = await Promise.all([
            getCompany(this.props.authUser),
            getCompanyStats(this.props.authUser),
            getSavedSearches(this.props.authUser),
            getRecruiterJobs(this.props.authUser, limit),
            getRecruiterEvents(this.props.authUser, limit),
            getRecruiterRecentAttendees(this.props.authUser, maxApplicants)
        ]);

        this.setState({
            company: companyRes.company,
            stats: statsRes.stats,
            savedSearches: searches.searches,
            jobs: jobsResult.jobs,
            jobsLoading: false,
            recentApplicants: jobsResult.recent_applicants,
            events: eventsRes.events,
            eventsLoading: false,
            recentAttendees: recentAttendees.users,
            eventsCount: eventsRes.total_count
        });
    }

    getMaxCardsPerScreen = () => {
        return window.innerWidth >= 1280
            ? 4  // xl: 5 columns
            : window.innerWidth >= 1024
                ? 3  // lg: 4 columns
                : window.innerWidth >= 768
                    ? 3  // md: 3 columns
                    : 2; // sm: 2 columns
    }

    render() {
        const maxCardsPerScreen = this.getMaxCardsPerScreen();
        if (this.state.jobsLoading || this.state.eventsLoading) {
            return (
                <DashboardLoading/>
            )
        }
        const links = [
            {
                label: 'Search students and clubs',
                icon: SvgSearchFeather,
                route: '/search',
                onClick: null
            },
            {
                label: 'View your lists',
                icon: SvgUserCheck,
                route: '/saved-lists',
                onClick: null
            },
            {
                label: 'View all jobs',
                icon: SvgBriefcase,
                route: '/engage',
                onClick: null
            },
            {
                label: 'View all events',
                icon: SvgCalendar,
                route: '/engage',
                onClick: null
            },
            {
                label: 'Edit your company profile',
                icon: SvgEdit2,
                route: '/company-page',
                onClick: null
            },
            {
                label: 'Invite another team member',
                icon: SvgUserAdd,
                route: '/settings',
                onClick: null
            },
        ];
        return (
            <div className={`flex flex-col flex-1 p-5 px-[12px] gap-8`}>
                <RecruiterDashboardSummary
                    company={this.state.company}
                    jobs={this.state.jobs}
                    eventsCount={this.state.eventsCount}
                    recentApplicantsCount={this.state.stats.num_applications}
                    recentAttendeesCount={this.state.stats.num_rsvps}
                    stats={this.state.stats}
                    links={links}
                />
                <div className='flex flex-col gap-3'>
                    <SectionHeader label={"Saved Searches"} rightLabel={'View all'} route={'/saved-searches'}/>
                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2'>
                    {this.state.savedSearches.length === 0 ?
                        <div className='flex flex-col items-center justify-center p-5'>
                            <p className='text-slate-500'>
                                No saved searches yet
                            </p>
                        </div>
                        :
                        this.state.savedSearches.slice(0, maxCardsPerScreen).map((item, index) =>
                            <SavedSearchCard
                                item={item}
                                index={index}
                            />
                        )}
                    </div>
                </div>
                <div className='flex flex-col gap-3'>
                    <div className={'flex flex-row items-center justify-between'}>
                        <SectionHeader label={'Events'} rightLabel={'View all'} route={'/events'}/>
                        <div className={'whitespace-nowrap ml-2'}>
                            <Button
                                variant={'default'}
                                icon={SvgPlus}
                                size={'sm'}
                                onClick={() => {
                                    
                                    this.props.toggleCreateEventModal();
                                }}>
                                New Event
                            </Button>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2'>
                        {this.state.events.slice(0, maxCardsPerScreen).map(item =>
                            <RecruiterEventPreviewCard item={item}/>
                        )}
                        {this.state.events.length < maxCardsPerScreen &&
                            <NewJobOrEventCard
                                index={0}
                                label={'Post a new event'}
                                onClick={this.props.toggleCreateEventModal}
                            />
                        }
                    </div>
                </div>
                <div className='flex flex-col gap-3'>
                   <div className={'flex flex-row items-center justify-between'}>
                       <SectionHeader label={'Jobs'} rightLabel={'View all'} route={'/jobs'}/>
                       <div className={'whitespace-nowrap ml-2'}>
                           <Button variant={'default'}
                                   icon={SvgPlus}
                                   size={'sm'}
                                   onClick={() => {
                                       
                                       this.props.toggleCreateJobModal();
                                   }}>
                               New Job
                           </Button>
                       </div>
                   </div>
                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2'>
                        {this.state.jobs.slice(0, maxCardsPerScreen).map((item, index) =>
                            <RecruiterJobPreviewCard item={item}/>
                        )}
                        {this.state.jobs.length < maxCardsPerScreen &&
                            <NewJobOrEventCard
                                index={0}
                                label={'Post a new job'}
                                onClick={this.props.toggleCreateJobModal}
                            />
                        }
                    </div>
                </div>
                <div className='flex flex-col gap-3'>
                    <SectionHeader label={'Candidates Applied'}/>
                    <RecruiterDashboardTable
                        columns={APPLICANTS_TABLE_HEADERS}
                        TableBody={
                            this.state.recentApplicants.length === 0 ?
                                <div className='flex flex-col items-center py-10'>
                                    <p className='text-xs text-slate-400'>
                                        No applicant inbound
                                    </p>
                                </div>
                                :
                                <div className='flex flex-col gap-1 p-5'>
                                    {this.state.recentApplicants.slice(0, maxApplicants).map(item =>
                                        <RecruiterDashboardTableItem
                                            item={item.user}
                                            columns={APPLICANTS_TABLE_HEADERS}
                                        />
                                    )}
                                </div>
                        }
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <SectionHeader label={"Candidates RSVP'd"}/>
                    <RecruiterDashboardTable
                        columns={EVENTS_TABLE_HEADERS}
                        TableBody={this.state.recentAttendees.length === 0 ?
                            <div className='flex flex-col items-center py-10'>
                                <p className='text-xs text-slate-400'>
                                    No event RSVPs
                                </p>
                            </div>
                            :
                            <div className='flex flex-col gap-1 p-5'>
                                {this.state.recentAttendees.slice(0, maxApplicants).map(item =>
                                    <RecruiterDashboardTableItem
                                        item={item}
                                        columns={EVENTS_TABLE_HEADERS}
                                    />
                                )}
                            </div>
                        }
                    />
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterDashboard);
