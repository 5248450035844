import React, {Component} from "react";
import {connect} from "react-redux";
import FadeInOnScroll from "../../components/atoms/FadeInOnScroll";
import Spinner from "../../components/Spinner";
import { getSavedSearches } from "../../api/recruiter/saved_searches";
import SavedSearchCard from "../SavedSearchCard";



function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

class RecruiterSavedSearchesPage extends Component {
    state = {
        savedSearches: [],
        loading: true
    }

    componentDidMount = async () => {
        const searches = await getSavedSearches(this.props.authUser);
        this.setState({savedSearches: searches.searches, loading: false})
        
    }

    render() {
        return (
            <div className={`flex flex-col flex-1 p-5 gap-8`}>
                <div className='flex flex-row items-center justify-between border-b border-slate-200 pb-5'>
                    <div>
                        <p className='text-slate-800 text-2xl font-semibold'>
                            All Saved Searches
                        </p>
                    </div>
                    {/* <div className='flex flex-row items-center gap-2'>
                        <Button
                            icon={SvgPlus}
                            onClick={this.props.toggleCreateEventModal}
                             >
                            New Event
                        </Button>
                    </div> */}
                </div>
                {this.state.loading ?
                    <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                        <Spinner />
                    </div>
                    :
                    <div className='grid grid-cols-3 gap-5'>
                        {this.state.savedSearches.map((item, index) =>
                            <FadeInOnScroll key={item.id} triggerImmediately
                                            delay={(index * 100) + 10}>
                                <SavedSearchCard index={index} item={item}/>
                            </FadeInOnScroll>
                        )}
                    </div>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps)(RecruiterSavedSearchesPage);
