import SchoolLogo from "../../components-recruiter/atoms/SchoolLogo";
import SvgClose from "../icons/SvgClose";
import CollapseComponent from "./CollapseComponent";
import FadeInOnScroll from "./FadeInOnScroll";
import classNames from "classnames";
import { useRef, useEffect, useState } from 'react';

export default function MultiSelectWithSearch({
    outerLabel,
    searchValue,
    selected,
    error,
    placeholder,
    results,
    openCollapse,
    maxToShow = 1,
    onSearchChange,
    onResultClick,
    onClearAll,
    resultMapper,
    leftIcon,
    rightIcon,
    showAllOnFocus = false,
    onFocus,
    onBlur,
    scrollable = false,
    disabled = false,
    className = "",
    displayKey = null,
    selectedItemsData = []
}) {
    const containerRef = useRef(null);
    const selectedItemsRef = useRef(null);
    const [inputWidth, setInputWidth] = useState('0px');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        if (containerRef.current && selectedItemsRef.current && selected.length > 0) {
            const containerWidth = containerRef.current.offsetWidth;
            const selectedItemsWidth = selectedItemsRef.current.offsetWidth;
            const newWidth = containerWidth - selectedItemsWidth - 1;
            setInputWidth(`${newWidth}px`);
        }
    }, [selected]);

    const handleFocus = (e) => {
        setIsDropdownOpen(true);
        onFocus?.(e);
    };

    const handleBlur = (e) => {
        setTimeout(() => {
            setIsDropdownOpen(false);
            onBlur?.(e);
        }, 200);
    };

    const handleSearchChange = (e) => {
        onSearchChange(e);
        setIsDropdownOpen(true);
    };

    const handleResultClick = (value) => {
        onResultClick(value);
        setIsDropdownOpen(false);
    };

    const handleClearAll = () => {
        onClearAll();
        setIsDropdownOpen(false);
    };

    const shouldShowDropdown = isDropdownOpen && (
        showAllOnFocus || searchValue.length > 0
    );

    return (
        <div className={classNames("flex flex-col w-full", className)}>
            <p className="text-sm text-slate-500 mb-1">{outerLabel}</p>
            <div className="relative w-full">
                <div ref={containerRef} className={`bg-white rounded-lg border ${error ? 'border-red-400' : 'border-neutral-200'} w-full`}>
                    <div className="flex flex-row items-center w-full">
                        {(leftIcon && selected.length === 0) && (
                            <span className="absolute left-3 top-1/2 -translate-y-1/2">{leftIcon}</span>
                        )}
                        {selected.length === 0 ? (
                            <input
                                type="text"
                                placeholder={placeholder}
                                className={classNames("input-light !border-0 !outline-none py-2 px-3 text-sm w-full disabled:opacity-50", {
                                    '!pl-10': !!leftIcon,
                                    '!pr-10': !!rightIcon
                                })}
                                value={searchValue}
                                onChange={handleSearchChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                disabled={disabled}
                            />
                        ) : (
                            <>
                                <div ref={selectedItemsRef} className="flex flex-row items-center pl-3 flex-shrink-0 overflow-hidden">
                                    <div className="flex flex-row flex-wrap gap-2">
                                        {selected.length > maxToShow ? (
                                            <div className="flex flex-row items-center gap-1 bg-slate-100 rounded-full px-2 py-1">
                                                <span className="text-sm line-clamp-1 max-w-[70px]">
                                                    {selected.length} selected
                                                </span>
                                                <div
                                                    className="text-slate-500 hover:text-red-400 cursor-pointer"
                                                    onClick={handleClearAll}
                                                >
                                                    <SvgClose className="h-3 w-3" />
                                                </div>
                                            </div>
                                        ) : (
                                            selected.map((value, index) => {
                                                // If we have selectedItemsData and displayKey, use that to display
                                                const displayValue = displayKey && selectedItemsData.length > 0 
                                                    ? selectedItemsData.find(item => item.id === value)?.[displayKey] || value
                                                    : value;
                                                
                                                return (
                                                    <div
                                                        key={index}
                                                        className="flex flex-row items-center gap-1 bg-slate-100 rounded-full px-2 py-1"
                                                    >
                                                        <span className="text-sm line-clamp-1 max-w-[70px]">
                                                            {displayValue}
                                                        </span>
                                                        <div
                                                            className="text-slate-500 hover:text-red-400 cursor-pointer"
                                                            onClick={() => handleResultClick(value)}
                                                        >
                                                            <SvgClose className="h-3 w-3" />
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Search more..."
                                    className={classNames("input-light !border-0 !outline-none py-2 px-3 text-sm disabled:opacity-50", {
                                        '!pr-10': !!rightIcon
                                    })}
                                    style={{ width: inputWidth }}
                                    value={searchValue}
                                    onChange={handleSearchChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    disabled={disabled}
                                />
                            </>
                        )}
                        {rightIcon && (
                            <span className="absolute right-3 top-1/2 -translate-y-1/2">{rightIcon}</span>
                        )}
                    </div>
                </div>
                {error && <p className="text-xs text-red-400 mt-1">{error}</p>}
                <div className="absolute top-12 left-0 right-0 z-[99] w-full">
                    <CollapseComponent
                        isOpen={shouldShowDropdown}
                        className="bg-white shadow-lg border overflow-hidden border-neutral-200 rounded-lg w-full"
                    >
                        <div className={classNames("w-full", {
                            "max-h-[200px] overflow-y-auto": scrollable
                        })}>
                            {results.map((result) => {
                                const mappedResult = resultMapper(result);
                                return (
                                    <FadeInOnScroll
                                        triggerImmediately
                                        delay={10}
                                        key={mappedResult.id}
                                    >
                                        <div
                                            onClick={() => handleResultClick(mappedResult.value)}
                                            className="flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 px-2"
                                        >
                                            {mappedResult.imageUrl && (
                                                <div className="h-10 aspect-square border border-slate-200 bg-white rounded-lg object-contain">
                                                    <SchoolLogo image={mappedResult.imageUrl}/>
                                                </div>
                                            )}
                                            <div className="flex flex-col">
                                                <p className="text-sm text-slate-800 line-clamp-2">
                                                    {mappedResult.name}
                                                </p>
                                            </div>
                                        </div>
                                    </FadeInOnScroll>
                                );
                            })}
                        </div>
                    </CollapseComponent>
                </div>
            </div>
        </div>
    );
}