import React, {useState, useEffect} from "react";
import RecruiterDashboardTable from "../dashboard/RecruiterDashboardTable";
import {APPLICANTS_TABLE_HEADERS_FULLPAGE, MAJOR_OPTIONS} from "../../utils/dummy";
import RecruiterJobApplicantTableItem from "../RecruiterJobApplicantTableItem";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import GoBack from "../../components/atoms/GoBack";
import {getJob, deleteJob} from "../../api/recruiter/jobs";    
import {getRecruiterJobApplications} from "../../api/recruiter/applications";
import {connect} from "react-redux";
import RecruiterEditJobModal from "../modals/RecruiterEditJobModal";
import Spinner from "../../components/Spinner";
import { DateTime } from 'luxon';
import {Button} from "../../components/atoms/Button";
import SvgSchool from "../../components/icons/SvgSchool";
import CollapseComponent from "../../components/atoms/CollapseComponent";
import { filteredCollegeSearch } from "../../utils/typesense";
import {toast} from "react-toastify";
import {LinkIcon} from "@heroicons/react/24/outline";
import {TOAST_OPTIONS} from "../../utils/constants";
import RecruiterBulkCreateList from "../RecruiterBulkCreateList";
import MultiSearchRace from "../../components/search/MultiSearchRace";
import SchoolLogo from "../../components-recruiter/atoms/SchoolLogo";
import MajorAdvancedSelector from "../atoms/MajorAdvancedSelector";
import { QualifiedOnlyProvider } from "../../contexts/QualifiedOnlyContext";
import RecruiterSearchFilters from "../atoms/RecruiterSearchFilters";
import useFiltersState from "../../hooks/useFiltersState";
import { filterUsers } from "../../utils/searchUtils";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        studentPeekOpen: state.userReducer.studentPeekOpen,
        candidates: state.userReducer.candidates,
        constants: state.userReducer.constants
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleStudentPeek: () => {
            dispatch({
                type: "TOGGLE_STUDENT_PEEK",
            })
        },
        setCandidates: (candidates) => {
            dispatch({
                type: "SET_CANDIDATES",
                candidates,
            })
        },
    }
}

function RecruiterJobListingPage(props) {
    const [job, setJob] = useState({});
    const [applications, setApplications] = useState([]);
    const [applicationObjects, setApplicationObjects] = useState({});
    const [loading, setLoading] = useState(true);
    const [editJobModalOpen, setEditJobModalOpen] = useState(false);
    const [collegeOptions, setCollegeOptions] = useState([]);
    const [selectedApplications, setSelectedApplications] = useState(new Set());
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [sortStates, setSortStates] = useState({
        name: 0,
        applied_at: 1
    });

    const {
        filters,
        setFilter,
        handleFilterClassYearsSelection,
        handleSearchChange,
        updateDiversity,
        clearFilters,
        canClearFilters
    } = useFiltersState();

    const candidates = React.useMemo(() => Object.values(props.candidates) || [], [props.candidates]);

    const filteredApplications = React.useMemo(() => {
        if (!candidates || candidates.length === 0) return [];
        
        const applicantsList = candidates.filter(user => applications.includes(user.username));
        
        const filterParams = {
            search: filters.search,
            majors: filters.majors,
            classYears: filters.classYears,
            gpa: filters.gpa,
            gender: filters.gender,
            race: filters.race,
            veteran: filters.veteran,
            lgbtq: filters.lgbtq,
            disabled: filters.disabled,
            authorized: filters.authorized,
            college: filters.schools.length > 0 ? filters.schools[0] : '',
            graduationSeason: filters.graduationSeason,
            clubs: filters.clubs,
            first_generation: filters.first_generation,
            require_sponsorship: filters.require_sponsorship,
            currentCompanies: filters.currentCompanies,
            previousCompanies: filters.previousCompanies,
            verticals: filters.verticals,
            previousVerticals: filters.previousVerticals
        };
        
        let filtered = filterUsers(applicantsList, filterParams);
        
        const activeSortKey = Object.entries(sortStates).find(([_, value]) => value > 0)?.[0];
        if (activeSortKey) {
            filtered.sort((a, b) => {
                if (activeSortKey === 'applied_at') {
                    const aDate = applicationObjects[a.username]?.created_at || '0';
                    const bDate = applicationObjects[b.username]?.created_at || '0';
                    const comparison = new Date(bDate) - new Date(aDate);
                    return sortStates[activeSortKey] === 1 ? comparison : -comparison;
                } else if (activeSortKey === 'name') {
                    const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                    const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                    const comparison = nameA.localeCompare(nameB);
                    return sortStates[activeSortKey] === 1 ? comparison : -comparison;
                }
                // Add handling for other potential sort keys here
                return 0;
            });
        }
        
        return filtered;
    }, [candidates, applications, filters, sortStates, applicationObjects]);

    useEffect(() => {
        getJobInfo();
    }, []);

    useEffect(() => {
        if (!props.location?.params?.forceRefresh && props.location?.params?.forceRefresh) {
            getJobInfo();
        }
    }, [props.location?.params?.forceRefresh]);

    const getJobInfo = async () => {
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const jobId = lastArg.split("?")[0];
        const res = await getJob(props.authUser, jobId);

        const result = await getRecruiterJobApplications(props.authUser, jobId);

        const candidates = result.applications.map(application => application.user)
        props.setCandidates(candidates);

        const colleges = new Set(candidates.filter(student => !!student.college).map(student => student.college));
        const lis = Array.from(colleges).sort();
        const applicationObjectsMap = Object.fromEntries(
            result.applications.map(application => [application.user_id, application])
        );
        setApplicationObjects(applicationObjectsMap);


        setJob(res.job);
        setApplications(result.applications.map(application => application.user_id));
        setCollegeOptions(lis.map(college => ({title: college})));
        setLoading(false);
    }

    const deleteStudentJob = async () => {
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const jobId = lastArg.split("?")[0];
        await deleteJob(props.authUser, jobId);
        props.history.goBack()
    }

    const toggleEditJobModal = () => {
        setEditJobModalOpen(prev => !prev);
    }

    const downloadCsv = () => {
        const applicants = filteredApplications;
        const end = applicants.map(row => {
            const answers = job?.application_questions.length 
                ? Object.values(applicationObjects[row.username]["answers"])
                    .map(answer => `"${answer.replace(/"/g, '""').replace(/\n/g, ' ')}"`).join(",")
                : "";
        
            return `${row.first_name} ${row.last_name},${row.email},${row.grade},${row.college},${row.gpa},${row?.major.join("/")},${row?.minor?.join("|")},${row?.diversity?.authorized},${row.diversity?.disabled},${row.diversity?.first_generation},${row.diversity?.gender},${row.diversity?.race?.join("|")},${row.diversity?.require_sponsorship},${row.diversity?.veteran},${answers}`.replace(/\n/g, ' ')
        }).join('\n');
        const csv = `name,email,class year,college,gpa,majors,minors,authorized to work,disability,first generation,gender,race,requires sponsorship,veteran,${job?.application_questions.length ? job.application_questions.map(q => q.text).join(","): ""}\n` + end;

        const blob = new Blob([csv], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'applicants.csv');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const previewJob = () => {
        props.history.push(`/job/${job.id}`);
    }

    const handleSelectStudentChange = (student) => {
        const newSelectedApplications = new Set(selectedApplications);
        if (newSelectedApplications.has(student.id)) {
            newSelectedApplications.delete(student.id);
        } else {
            newSelectedApplications.add(student.id);
        }
        setSelectedApplications(newSelectedApplications);
    };

    const toggleSelectAll = () => {
        const newState = !selectAllChecked;
        setSelectAllChecked(newState);
        if (newState) {
            setSelectedApplications(new Set(filteredApplications.map(application => application.id)));
        } else {
            setSelectedApplications(new Set());
        }
    };

    const updateRace = (raceValues) => {
        setFilter('race', raceValues);
    };

    const handleSort = (key) => {
        const newSortStates = { ...sortStates };
        Object.keys(newSortStates).forEach(k => {
            if (k !== key) newSortStates[k] = 0;
        });
        newSortStates[key] = (newSortStates[key] + 1) % 3;
        setSortStates(newSortStates);
    };

    return (
        <div className={`flex flex-col flex-1 p-[12px] gap-2 overflow-hidden`}>
            <div className='flex flex-col gap-5'>
                <div className='flex flex-row justify-between items-start gap-12'>
                    <div className='flex flex-col gap-1 flex-1'>
                        <GoBack/>
                        <div className="flex flex-row">
                            <p className='text-2xl font-semibold'>
                                {props.location.params?.name || job.name}
                            </p>
                            {!loading &&
                                <div
                                    onClick={() => {
                                        navigator.clipboard.writeText(`https://app.recruitu.com/job/${job.id}`);
                                        toast.success("Link copied to clipboard!", TOAST_OPTIONS);
                                    }}
                                    className="px-2 py-2 rounded-3xl bg-primary/20 flex flex-row items-center justify-center hover:opacity-50 cursor-pointer mx-3"
                                >
                                    <LinkIcon className="w-5 h-5 text-primary"/>
                                </div>
                            }
                        </div>
                        {!loading &&
                            <div className='flex text-sm flex-row items-center gap-2'>
                                <p className=' text-slate-500'>
                                    {!!job.city?.length && !!job.state?.length ? `${job.city}, ${job.state} ~` : null} {!!job.type ? job.type + " ~" : null}
                                </p>
                                <div className='flex flex-row text-slate-500 gap-2'>
                                    <p>
                                        Posted: <span
                                        className='text-slate-600'>{DateTime.fromISO(job.created_at).toFormat('MMM dd, yyyy')}{!!job.deadline && ' ~'}</span>
                                    </p>
                                    {DateTime.fromISO(job.deadline).isValid ?
                                        <p>
                                            Closes: <span
                                            className='text-slate-600'>{DateTime.fromISO(job.deadline).toFormat('MMM dd, yyyy')}</span>
                                        </p>
                                        : null
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                        <div className='flex flex-col items-end gap-3'>
                            <div className={'flex flex-row gap-3'}>
                                <Button variant={'secondary'} onClick={downloadCsv}>
                                    Download CSV
                                </Button>
                                <RecruiterBulkCreateList studentIds={Array.from(selectedApplications)}
                                                         originalName={job.name}/>
                            </div>
                            <div className={'flex flex-row gap-3'}>
                                <Button variant={'secondary'} onClick={toggleEditJobModal}>
                                    Edit Job Post
                                </Button>
                                <Button variant={'secondary'} onClick={previewJob}>
                                    Preview Job
                                </Button>
                                <Button variant={'destructive'} onClick={deleteStudentJob} className='w-[106px]'>
                                    Delete Job
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'flex flex-col gap-2 flex-1 overflow-hidden'}>
                <div className='flex-1 flex flex-col overflow-hidden'>
                    <div className='flex-1 flex flex-row gap-3 overflow-hidden'>
                        <div className={'w-[250px] flex flex-col'}>
                            <RecruiterSearchFilters
                                search={{
                                    placeholder: 'Search by name...',
                                    value: filters.search,
                                    onChange: (e) => handleSearchChange(e.target.value)
                                }}
                                
                                schools={filters.schools}
                                updateCollege={(schools) => setFilter('schools', schools)}
                                
                                clubs={filters.clubs}
                                setClubs={(clubs) => setFilter('clubs', clubs)}
                                
                                majors={filters.majors}
                                updateMajor={(majors) => setFilter('majors', majors)}
                                
                                classYears={filters.classYears}
                                handleFilterClassYearsSelection={handleFilterClassYearsSelection}

                                graduationSeason={filters.graduationSeason}
                                updateGraduationSeason={(season) => setFilter('graduationSeason', season)}
                                
                                currentCompanies={filters.currentCompanies}
                                setCurrentCompany={(companies) => setFilter('currentCompanies', companies)}
                                
                                previousCompanies={filters.previousCompanies}
                                setPreviousCompany={(companies) => setFilter('previousCompanies', companies)}

                                verticals={filters.verticals}
                                setVerticals={(verticals) => setFilter('verticals', verticals)}
                                previousVerticals={filters.previousVerticals}
                                setPreviousVerticals={(verticals) => setFilter('previousVerticals', verticals)}
                                
                                gpa={filters.gpa}
                                setGpaFilter={(gpa) => setFilter('gpa', gpa)}
                                
                                gender={filters.gender}
                                updateDiversity={updateDiversity}
                                race={filters.race}
                                updateRace={updateRace}
                                veteran={filters.veteran}
                                lgbtq={filters.lgbtq}
                                disabled={filters.disabled}
                                authorized={filters.authorized}
                                first_generation={filters.first_generation}
                                require_sponsorship={filters.require_sponsorship}
                                
                                clearFilters={canClearFilters ? clearFilters : undefined}
                            />
                        </div>
                        <div className='flex flex-col overflow-hidden flex-1'>
                            <div className={' py-2 text-lg font-semibold'}>
                                {filteredApplications.length} applications
                            </div>
                            <RecruiterDashboardTable
                                columns={APPLICANTS_TABLE_HEADERS_FULLPAGE}
                                hasCheckbox={true}
                                checked={selectAllChecked}
                                toggleCheckbox={toggleSelectAll}
                                onSort={handleSort}
                                sortStates={sortStates}
                                TableBody={
                                    loading ?
                                        <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                                            <Spinner/>
                                        </div>
                                        :
                                        <div className='flex flex-col gap-2 p-5 pt-2 flex-1 overflow-y-scroll'>
                                            {applications.length === 0 ?
                                                <div
                                                    className='flex flex-col items-center justify-center text-slate-500 text-sm flex-1'>
                                                    No applicants to show
                                                </div>
                                                :
                                                filteredApplications.map(item =>
                                                    <RecruiterJobApplicantTableItem
                                                        key={item.id}
                                                        item={item}
                                                        columns={APPLICANTS_TABLE_HEADERS_FULLPAGE}
                                                        isSelected={selectedApplications.has(item.id)}
                                                        onSelectStudentChange={handleSelectStudentChange}
                                                    />
                                                )}
                                        </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <QualifiedOnlyProvider>
                <RecruiterEditJobModal
                    job={job}
                    isOpen={editJobModalOpen}
                    closeModal={toggleEditJobModal}
                    authUser={props.authUser}
                    history={props.history}
                    constants={props.constants}
                />
            </QualifiedOnlyProvider>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterJobListingPage);
