import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import SvgRemove from "../icons/SvgRemove";
import Tooltip from "../atoms/modals/Tooltip";
import { addFeaturedJob } from "../../api/student/clubs";
import CompanyLogo from "../atoms/CompanyLogo";
import { connect } from "react-redux";
import TagComponent from "../atoms/TagComponent";
import { DateTime } from "luxon";

function mapStateToProps(state) {
  return {
    authUser: state.userReducer.authUser,
  };
}

const TAGS = {
  1: "Recommended for your club",
  2: "Exclusive to your club",
};

class ClubPageJobItem extends PureComponent {
  removeFeaturedJob = async (jobId) => {
    // Add featured job both adds and removes depending on if already featured
    await addFeaturedJob(this.props.authUser, this.props.club.id, jobId);
    this.props.getClubInfo();
  };

  render() {
    const club = this.props.club || {};
    const admin = club.admin || [];
    const user = this.props.user || {};
    const listing = this.props.listing || {};
    const createdAt = DateTime.fromISO(listing.created_at);
    const deadline = DateTime.fromISO(listing.deadline);
    let location = null;
    if (!!listing.city.length) {
      if (listing.city.length === 1) {
        location = listing.city[0] + ", " + listing.state[0];
      } else {
        location = "Multiple Locations";
      }
    }
    return (
      <div
        onClick={() => {
          
          !listing.deprecated &&
            this.props.history.push({
              pathname: `/job/` + listing.id,
              state: { previousPage: { location: "/club", data: {} } },
            });
        }}
        className={`${listing.deprecated ? "opacity-50" : ""} 
                ${this.props.inPost ? "rounded-lg p-3 shadow-none border-slate-200 border hover:border-slate-300 w-full" : "p-3 shadow-lg shadow-slate-200 border-transparent border hover:border-slate-300"}
                cursor-pointer bg-white rounded-xl bg-white`}
      >
        <div className="flex flex-row justify-between flex-1">
          <div className="flex flex-row items-center flex-1">
            <CompanyLogo id={listing.company_id} className={"w-20 h-20"} />
            <div className="ml-3">
              <div className="flex flex-col gap-0.5">
                {!!listing.deprecated && (
                  <Tooltip
                    toggleComponent={
                      <span className="bg-slate-200 px-2 rounded-md inline mr-a my-auto inline">
                        <p className="text-xs text-slate-500">Expired</p>
                      </span>
                    }
                    label={
                      "This job has been removed from RecruitU. You can no longer navigate to the full job page."
                    }
                  />
                )}
                <p className="body2-bold m-0 blacks-1">{listing.name}</p>
              </div>
              {(listing.company_name || location) && (
                <div className="flex flex-row items-center text-neutral-400">
                  <p className="body2 m-0">
                    {listing.company_name}&nbsp;
                    {!!location && !this.props.inPost ? "•" : ""}&nbsp;
                    {location}
                  </p>
                </div>
              )}
              {createdAt.isValid ? (
                <div className="mr-3 body3 text-neutral-400">
                  <p className="m-0">Posted {createdAt.toRelative()}</p>
                </div>
              ) : null}
              <div className="mt-2 flex flex-row items-center gap-2 flex-wrap">
                <TagComponent
                  label={listing.type === "Job" ? "Full Time" : listing.type}
                  type={"job-type"}
                />
                {listing.industry && (
                  <TagComponent label={listing.industry} type={"industry"} />
                )}
                {listing.tags &&
                  listing.tags.map((tag) => (
                    <TagComponent label={tag} type={"random"} />
                  ))}
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-end gap-5 flex-wrap ml-8">
            {!this.props.inPost && this.props.tag ? (
              <div className={this.props.grid && "absolute top-0 right-0"}>
                <div className="flex flex-col items-center rounded-lg border-neutral-200 border">
                  <TagComponent label={TAGS[this.props.tag]} type={"reason"} />
                </div>
              </div>
            ) : null}
            {deadline.isValid ? (
              <div className={this.props.grid && "absolute top-0 right-0"}>
                <div className="flex flex-col items-center rounded-lg border-neutral-200 border">
                  <p className="body4-bold uppercase p-0.5 px-3 bg-neutral-100">
                    {deadline.toFormat("MMM")}
                  </p>
                  <p className="body2-bold p-1">{deadline.toFormat("dd")}</p>
                </div>
              </div>
            ) : null}
            {admin.includes(user.username) && !this.props.hideRemove ? (
              <Tooltip
                toggleComponent={
                  <button
                    onClick={(e) => {
                      this.removeFeaturedJob(listing.id);
                      e.stopPropagation();
                    }}
                  >
                    <SvgRemove
                      className={
                        "w-5 h-5 text-neutral-400 hover:text-red-500 cursor-pointer"
                      }
                    />
                  </button>
                }
                label={"Remove this job from the club's featured list"}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(ClubPageJobItem));
