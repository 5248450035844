import React, { useState } from "react";
import { useSelector } from "react-redux";
import MultiSelectWithSearch from "../atoms/MultiSelectWithSearch";

const MultiSearchVerticals = ({
  selectedValues,
  setSelectedValues,
  className,
  label = "",
  placeholder = "Search verticals...",
  limit = 2,
}) => {
  const verticals = useSelector(
    (state) => state.userReducer.constants.secondary_tags || []
  );
  const [searchValue, setSearchValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  // Filter verticals based on search only if there's a search value
  const filteredResults = searchValue
    ? verticals.filter((vertical) =>
        vertical.toLowerCase().includes(searchValue.toLowerCase())
      )
    : verticals;

  const handleResultClick = (value) => {
    const newValues = [...selectedValues];
    const index = newValues.findIndex((v) => v === value);

    if (index >= 0) {
      newValues.splice(index, 1);
    } else {
      newValues.push(value);
    }
    setSearchValue("");
    setSelectedValues(newValues);
  };

  return (
    <MultiSelectWithSearch
      scrollable={true}
      className={className}
      outerLabel={label}
      searchValue={searchValue}
      selected={selectedValues}
      placeholder={placeholder}
      results={filteredResults}
      openCollapse={isFocused}
      showAllOnFocus={true}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onSearchChange={(e) => setSearchValue(e.target.value)}
      onResultClick={handleResultClick}
      onClearAll={() => setSelectedValues([])}
      resultMapper={(vertical) => ({
        id: vertical,
        value: vertical,
        name: vertical
      })}
      maxToShow={limit}
    />
  );
};

export default MultiSearchVerticals;
