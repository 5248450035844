import React, { useState, useMemo, useEffect } from "react";
import { Button } from "../../components/atoms/Button";
import { deleteList, editList } from "../../api/recruiter/lists";
import EditListModal from "../modals/EditListModal";
import { DateTime } from 'luxon';
import GoBack from "../../components/atoms/GoBack";
import { downloadAllResumes } from "../../api/student/clubs";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { TOAST_OPTIONS } from "../../utils/constants";
import RecruiterDashboardTable from "../dashboard/RecruiterDashboardTable";
import { SAVED_LISTS_TABLE_HEADERS } from "../../utils/dummy";
import RecruiterFavoritesTableItem from "../RecruiterFavoritesTableItem";
import RecruiterSearchFilters from "../atoms/RecruiterSearchFilters";
import { filterUsers } from "../../utils/searchUtils";
import { searchClubs } from "../../utils/typesense";
import useFiltersState from "../../hooks/useFiltersState";
import ListHeaderActions from "../atoms/ListHeaderActions";
import { downloadStudentsCSV } from '../../utils/csv';

function RecruiterUserFullListPage({
    list,
    type,
    refreshList,
    authUser,
    history,
    recruiterCompany
}) {
    const [isEditing, setIsEditing] = useState(false);
    const [isEditListModalOpen, setIsEditListModalOpen] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [removeStudentConfirmationModal, setRemoveStudentConfirmationModal] = useState(false);
    const [currentStudentId, setCurrentStudentId] = useState(null);
    const [clubs, setClubs] = useState([]);
    const [error, setError] = useState(false);
    
    const {
        filters,
        setFilter,
        handleFilterClassYearsSelection,
        handleSearchChange,
        updateDiversity,
        clearFilters,
        toggleSchoolSort,
        canClearFilters,
        toggleSearchSort
    } = useFiltersState();

    useEffect(() => {
        const fetchClubs = async () => {
            if (filters.schools.length > 0) {
                try {
                    const clubResults = await searchClubs("", 1, 100, filters.schools[0]);
                    setClubs(clubResults);
                } catch (error) {
                    setClubs([]);
                }
            } else {
                setClubs([]);
            }
        };
        fetchClubs();
    }, [filters.schools]);

    const delList = async () => {
        await deleteList(authUser, list.id, type);
        history.push('/saved-lists');
    };

    const remove = async () => {
        await editList(authUser, list.id, type, {
            [`${type}_ids`]: [currentStudentId]
        });
        setRemoveStudentConfirmationModal(false);
        setCurrentStudentId(null);
        refreshList();
    };

    const downloadCsv = async () => {
        const usersToDownload = filteredUsers;
        downloadStudentsCSV(usersToDownload, list.name, { companyId: recruiterCompany.id });
    };

    const handleDownload = async () => {
        try {
            const usersToDownload = filteredUsers;
            const resumePromise = downloadAllResumes(authUser, usersToDownload);
            toast.promise(
                resumePromise,
                {
                    pending: "Downloading resumes...",
                    success: "Resumes downloaded successfully!",
                    error: "No resumes available for these filters",
                },
                TOAST_OPTIONS
            );
            
            const resumeResult = await resumePromise;
            if (!resumeResult) {
                setError(true);
            } else {
                setError(false);
            }
        } catch (error) {
            console.log("Error no resumes this year");
        }
    };

    const filteredUsers = useMemo(() => {
        console.log(list.users);
        if (!list.users) return [];
        
        const filterParams = {
            search: filters.search,
            majors: filters.majors,
            classYears: filters.classYears,
            gpa: filters.gpa,
            gender: filters.gender,
            race: filters.race,
            veteran: filters.veteran,
            lgbtq: filters.lgbtq,
            disabled: filters.disabled,
            authorized: filters.authorized,
            college: filters.schools.length > 0 ? filters.schools[0] : '',
            graduationSeason: filters.graduationSeason,
            clubs: filters.clubs,
            first_generation: filters.first_generation,
            require_sponsorship: filters.require_sponsorship,
            currentCompanies: filters.currentCompanies,
            previousCompanies: filters.previousCompanies,
            verticals: filters.verticals,
            previousVerticals: filters.previousVerticals,
            searchSortState: filters.searchSortState,
            schoolSortState: filters.schoolSortState
        };

        
        return filterUsers(list.users, filterParams);
 
    }, [list.users, filters, clubs]);

    const removeStudents = () => {
        setIsEditing(true);
    };

    const clickRemoveStudent = (id) => {
        setCurrentStudentId(id);
        setRemoveStudentConfirmationModal(true);
    };

    const updateRace = (raceValues) => {
        setFilter('race', raceValues);
    };

    const listCreation = DateTime.fromISO(list.created_at);
    const colleges = list?.colleges || [];

    return (
        <div className={`flex flex-col flex-1 overflow-hidden`}>
            <div className='flex flex-row items-end justify-between p-[12px] pb-0'>
                <div>
                    <GoBack/>
                    <div className='flex flex-row items-center mt-1 gap-2 mb-2'>
                        <p className='text-slate-800 text-2xl font-semibold'>
                            {list.name}
                            <span className='text-slate-500'>
                                {` (${filteredUsers.length} students)`}
                            </span>
                        </p>
                    </div>
                </div>
                <ListHeaderActions 
                    users={list.users}
                    onDownloadCsv={downloadCsv}
                    onDownloadResumes={handleDownload}
                    onEditList={() => setIsEditListModalOpen(true)}
                    onDeleteList={() => setConfirmationModalOpen(true)}
                    onRemoveItems={removeStudents}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            </div>
            <div className={'flex flex-row flex-wrap px-[12px] gap-4 opacity-70'}>
                <div className='flex flex-row items-end'>
                    <p className='text-slate-800 text-sm'>
                        <strong>Created:</strong> {listCreation.toLocaleString(DateTime.DATETIME_MED)} {!!list.creator_name && ` by ${list.creator_name}`}
                    </p>
                </div>
                {colleges.length ?
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Colleges:</strong> {list.colleges.join(", ")}
                        </p>
                    </div>
                    : null
                }
            </div>
            <div className={'flex flex-1 flex-col overflow-hidden px-[12px] pb-[12px]'}>
                <div className='flex-1 flex flex-col overflow-hidden'>
                    <div className='flex-1 flex flex-row gap-3 overflow-hidden'>
                        <div className={'w-[250px] flex flex-col'}>
                            <RecruiterSearchFilters
                                search={{
                                    placeholder: 'Search by name...',
                                    value: filters.search,
                                    onChange: (e) => handleSearchChange(e.target.value),
                                    toggleSearchSort: toggleSearchSort
                                }}
                                
                                schools={filters.schools}
                                updateCollege={(schools) => setFilter('schools', schools)}
                                toggleSchoolSort={toggleSchoolSort}
                                searchSortState={filters.searchSortState}
                                schoolSortState={filters.schoolSortState}
                                
                                clubs={filters.clubs}
                                setClubs={(clubs) => setFilter('clubs', clubs)}
                                
                                majors={filters.majors}
                                updateMajor={(majors) => setFilter('majors', majors)}
                                
                                classYears={filters.classYears}
                                handleFilterClassYearsSelection={handleFilterClassYearsSelection}

                                graduationSeason={filters.graduationSeason}
                                updateGraduationSeason={(season) => setFilter('graduationSeason', season)}
                                
                                currentCompanies={filters.currentCompanies}
                                setCurrentCompany={(companies) => setFilter('currentCompanies', companies)}
                                
                                previousCompanies={filters.previousCompanies}
                                setPreviousCompany={(companies) => setFilter('previousCompanies', companies)}

                                verticals={filters.verticals}
                                setVerticals={(verticals) => setFilter('verticals', verticals)}
                                previousVerticals={filters.previousVerticals}
                                setPreviousVerticals={(verticals) => setFilter('previousVerticals', verticals)}
                                
                                gpa={filters.gpa}
                                setGpaFilter={(gpa) => setFilter('gpa', gpa)}
                                
                                gender={filters.gender}
                                updateDiversity={updateDiversity}
                                race={filters.race}
                                updateRace={updateRace}
                                veteran={filters.veteran}
                                lgbtq={filters.lgbtq}
                                disabled={filters.disabled}
                                authorized={filters.authorized}
                                first_generation={filters.first_generation}
                                require_sponsorship={filters.require_sponsorship}
                                
                                clearFilters={canClearFilters ? clearFilters : undefined}
                            />
                        </div>
                        <div className='flex flex-col overflow-hidden flex-1'>
                            <div className={' py-2 text-lg font-semibold'}>
                                {filteredUsers.length} students
                            </div>
                            <RecruiterDashboardTable
                                columns={SAVED_LISTS_TABLE_HEADERS}
                                TableBody={
                                    <div className='flex flex-col gap-4 p-5 flex-1 overflow-y-scroll'>
                                        {filteredUsers.length === 0 ?
                                            <div className='flex flex-col items-center py-12 gap-3'>
                                                <p className='text-slate-500'>
                                                    No students match your search criteria
                                                </p>
                                                <Button onClick={() => setIsEditListModalOpen(true)}>
                                                    Edit list filters
                                                </Button>
                                            </div>
                                            :
                                            filteredUsers.map(item =>
                                                <div key={item.id}>
                                                    <RecruiterFavoritesTableItem
                                                        item={item}
                                                        columns={SAVED_LISTS_TABLE_HEADERS}
                                                        remove={clickRemoveStudent}
                                                        isEditing={isEditing}
                                                        list={list}
                                                        type={type}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <EditListModal
                isOpen={isEditListModalOpen}
                currentList={list}
                type={type}
                closeModal={() => setIsEditListModalOpen(false)}
                getLists={refreshList}
            />
            <ConfirmationModal
                isOpen={confirmationModalOpen}
                closeModal={() => setConfirmationModalOpen(false)}
                confirmText={'Delete'}
                title={'Are you sure?'}
                onConfirm={delList}
                description={'Are you sure you want to delete this list? This action cannot be undone.'}
            />
            <ConfirmationModal
                isOpen={removeStudentConfirmationModal}
                closeModal={() => setRemoveStudentConfirmationModal(false)}
                confirmText={'Delete'}
                title={'Are you sure?'}
                onConfirm={remove}
                description={'Are you sure you want to delete this student? This action cannot be undone.'}
            />
        </div>
    );
}

export default RecruiterUserFullListPage; 
