import React, {PureComponent} from "react";
import ModalComponent from "./ModalComponent";
import SvgCheck from "../../icons/SvgCheck";
import SvgCopy from "../../icons/SvgCopy";
import { Button } from "../Button";
import SvgLink from "../../icons/SvgLink"
import {wait} from "@testing-library/user-event/dist/utils";
import SvgAddCircle from "../../icons/SvgAddCircle";


const ZERO_STATE = {
    copied: false,
}

class MemberInviteModal extends PureComponent {
    state = ZERO_STATE


    closeModal = () => {
        this.props.closeModal()
        this.setState(ZERO_STATE);
    }

    copyLink = () => {
        this.setState({copied: true});
        navigator.clipboard.writeText("https://app.recruitu.com/authentication?activeTab=signup&clubId=" + this.props.club.id);
        wait(1000).then(() =>
            this.setState({copied: false})
        )
    }


    render() {
        const club = this.props.club || {};
        return (
            <ModalComponent isOpen={this.props.isOpen}
                            header={"Invite Members"}
                            backgroundColor={'white'}
                            headerToggle
                            size={'md'}
                            toggle={this.closeModal}
                            footerAlignment={'right'}
                            id={'member-invite-modal'}
            >
                <div className='flex flex-col gap-5  justify-center'>
                    <div className="flex flex-col w-full gap-5">
                        <div className='flex flex-col gap-5'>
                            <Button
                                variant='secondary'
                                className="basis-1/2"
                                onClick={this.copyLink}
                            >
                                {this.state.copied ?
                                    <SvgCheck className={'w-5 h-5'}/>
                                    :
                                    <SvgCopy className={'w-5 h-5'}/>
                                }
                                {this.state.copied ? 'Link copied!' : 'Copy invite link'}
                            </Button>
                            <Button variant='secondary' className="" onClick={() => {this.closeModal(); this.props.toggleAddClubMemberModal()}}>
                                <SvgAddCircle className={'w-4 h-4 mr-2'}/>
                                Add Member
                            </Button>
                            {/* {this.props.adminIds.includes(club.id) ?
                                <Button variant='secondary' className="" onClick={() => {this.closeModal(); this.props.changeTab("Admin")}}>
                                    <SvgLink className={'w-4 h-4 mr-2'}/>
                                    Add Member List
                                </Button>
                            : null} */}
                        </div>
                    </div>

                </div>
            </ModalComponent>
        )
    }
}

export default MemberInviteModal;
