import React, {useState, useEffect} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {Button} from "../../components/atoms/Button";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import Spinner from "../../components/Spinner";
import { createSmartList } from "../../api/recruiter/lists";
import { editList } from "../../api/recruiter/lists";
import RecruiterSearchFilters from "../atoms/RecruiterSearchFilters";
import useFiltersState from "../../hooks/useFiltersState";

const SmartListModal = ({
    isOpen, 
    closeModal, 
    authUser, 
    constants, 
    tier,
    mode = 'create',
    initialData = null,
    onSave 
}) => {
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    
    // Initialize the filters state hook
    const {
        filters,
        setFilter,
        handleFilterClassYearsSelection,
        updateDiversity,
        clearFilters,
        initFilters,
    } = useFiltersState();

    useEffect(() => {
        if (isOpen) {
            if (mode === 'edit' && initialData) {
                setName(initialData.name || '');
                
                // Map initialData to the format expected by initFilters
                const filtersData = {
                    schools: initialData.colleges || [],
                    majors: initialData.majors || [],
                    class_years: initialData.grades || [],
                    gpa: initialData.gpa || null,
                    verticals: initialData.verticals || [],
                    gender: initialData.gender || null,
                    race: initialData.race || [],
                    veteran: initialData.veteran,
                    lgbtq: initialData.lgbtq,
                    disabled: initialData.disabled,
                    authorized: initialData.authorized,
                    current_companies: initialData.current_companies || [],
                    previous_companies: initialData.previous_companies || [],
                    is_ib: initialData.is_ib,
                    clubs: initialData.clubs || [],
                    previous_verticals: initialData.previous_verticals || [],
                    graduation_season: initialData.graduation_season,
                    first_generation: initialData.first_generation,
                    require_sponsorship: initialData.require_sponsorship,
                };
                
                initFilters(filtersData);
            } else {
                setName('');
                clearFilters();
            }
        }
    }, [isOpen, mode, initialData]);

    const handleSave = async () => {
        const params = {
            name,
            grades: filters.classYears,
            majors: filters.majors,
            colleges: filters.schools,
            gpa: filters.gpa,
            gender: filters.gender,
            race: filters.race,
            verticals: filters.verticals, 
            lgbtq: filters.lgbtq,
            veteran: filters.veteran,
            disabled: filters.disabled,
            authorized: filters.authorized,
            current_companies: filters.currentCompanies,
            previous_companies: filters.previousCompanies,
            is_ib: filters.isIb,
            clubs: filters.clubs,
            previous_verticals: filters.previousVerticals,
            graduation_season: filters.graduationSeason,
            first_generation: filters.first_generation,
            require_sponsorship: filters.require_sponsorship,
        };
        
        if (mode === 'edit') {
            setShowConfirmation(true);
        } else {
            setLoading(true);
            try {
                await createSmartList(authUser, params);
                await onSave?.();
                closeModal();
            } catch (error) {
                console.error("Error creating smart list:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleConfirmedEdit = async () => {
        const params = {
            name,
            grades: filters.classYears,
            majors: filters.majors,
            colleges: filters.schools,
            gpa: filters.gpa,
            gender: filters.gender,
            race: filters.race,
            verticals: filters.verticals, 
            lgbtq: filters.lgbtq,
            veteran: filters.veteran,
            disabled: filters.disabled,
            authorized: filters.authorized,
            current_companies: filters.currentCompanies,
            previous_companies: filters.previousCompanies,
            is_ib: filters.isIb,
            clubs: filters.clubs,
            previous_verticals: filters.previousVerticals,
            graduation_season: filters.graduationSeason,
            first_generation: filters.first_generation,
            require_sponsorship: filters.require_sponsorship,
        };
        
        setLoading(true);
        try {
            await editList(authUser, initialData.id, 'smart', params);
            onSave?.();
            setShowConfirmation(false);
            closeModal();
        } catch (error) {
            console.error("Error editing smart list:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <ModalComponent
                isOpen={isOpen}
                backgroundColor={'white'}
                header={mode === 'create' ? 'Create a Smart List' : 'Edit Smart List'}
                headerToggle
                size={'lg'}
                toggle={closeModal}
                showScrollBar
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={loading}
                            onClick={handleSave}>
                            {mode === 'create' ? 'Create' : 'Save'}
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
                id={'smart-list-modal'}
            >
                <p>
                    Describe the kinds of candidates you're looking for, and we'll automatically generate a list of students
                    that match your criteria.
                </p>
                <div className='mt-5 flex flex-col gap-4'>
                    <div className='relative'>
                        <WrappedTextInput
                            type="text"
                            placeholder='List name'
                            className='input-light px-3 body2 w-full mb-4'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    
                    {/* RecruiterSearchFilters component */}
                    <RecruiterSearchFilters
                        // Schools
                        schools={filters.schools}
                        updateCollege={(schools) => setFilter('schools', schools)}
                        
                        // Clubs
                        clubs={filters.clubs}
                        setClubs={(clubs) => setFilter('clubs', clubs)}
                        
                        // Majors
                        majors={filters.majors}
                        updateMajor={(majors) => setFilter('majors', majors)}
                        
                        // Class Years
                        classYears={filters.classYears}
                        handleFilterClassYearsSelection={handleFilterClassYearsSelection}
                        
                        // Graduation Season
                        graduationSeason={filters.graduationSeason}
                        updateGraduationSeason={(season) => setFilter('graduationSeason', season)}
                        
                        // GPA
                        gpa={filters.gpa}
                        setGpaFilter={(gpa) => setFilter('gpa', gpa)}
                        
                        // Companies
                        currentCompanies={filters.currentCompanies}
                        setCurrentCompany={(companies) => setFilter('currentCompanies', companies)}
                        previousCompanies={filters.previousCompanies}
                        setPreviousCompany={(companies) => setFilter('previousCompanies', companies)}
                        
                        // Verticals
                        verticals={filters.verticals}
                        setVerticals={(verticals) => setFilter('verticals', verticals)}
                        previousVerticals={filters.previousVerticals}
                        setPreviousVerticals={(verticals) => setFilter('previousVerticals', verticals)}
                        
                        // Demographics
                        gender={filters.gender}
                        updateDiversity={updateDiversity}
                        race={filters.race}
                        updateRace={(race) => setFilter('race', race)}
                        veteran={filters.veteran}
                        lgbtq={filters.lgbtq}
                        disabled={filters.disabled}
                        authorized={filters.authorized}
                        first_generation={filters.first_generation}
                        require_sponsorship={filters.require_sponsorship}
                        
                        // Other
                        clearFilters={clearFilters}
                        
                        // Sort states
                        schoolSortState={filters.schoolSortState}
            
                        useFullLayout={true}
                        
                        hideSearch={true}

                        // Limits
                        tagLimits={{
                            schools: 4,
                            clubs: 2,
                            majors: 3,
                            currentCompanies: 4,
                            previousCompanies: 4,
                            verticals: 4,
                            previousVerticals: 4
                        }}
                    />
                    
                    {loading && (
                        <div className='mt-5 flex justify-center'>
                            <Spinner size={40}/>
                        </div>
                    )}
                </div>
            </ModalComponent>

            <ModalComponent
                isOpen={showConfirmation}
                backgroundColor={'white'}
                header={'Confirm Edit'}
                headerToggle
                size={'sm'}
                toggle={() => setShowConfirmation(false)}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={() => setShowConfirmation(false)} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={loading}
                            onClick={handleConfirmedEdit}>
                            Proceed
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <p className="text-slate-700">
                    By editing this smart list, any jobs and events associated with this smart list will be updated as well. Are you sure you want to proceed?
                </p>
            </ModalComponent>
        </>
    );
};

export default SmartListModal;
