import React, {PureComponent} from "react";
import TextBlockShimmer from "../loading/TextBlockShimmer";
import ShowMoreText from "react-show-more-text";
import CompanyFunFacts from "./CompanyFunFacts";
import {POPULAR_COMPANIES} from "../onboarding/onboarding-constants";
import CompanyLogo from "../atoms/CompanyLogo";
import SvgLink from "../icons/SvgLink";
import CompanyInfoBlock from "./CompanyInfoBlock";
import CompanyAboutBlock from "./CompanyAboutBlock";
import { sanitizeHtml } from "../../utils/strings";
import RenderHtml from "../atoms/RenderHtml";
import ShowMoreRichText from '../atoms/ShowMoreRichText';



class CompanyPageAboutTab extends PureComponent {

    render() {
        const company = this.props.company || {};
        return (
            <>
                <div className={`card flex flex-col gap-2 p-5`}>
                    <p className='text-lg font-bold'>
                        About {company.name}
                    </p>
                    <div>
                        {this.props.loading ?
                            <>
                                <TextBlockShimmer/>
                            </>
                            :
                            <ShowMoreRichText 
                                htmlString={company.description}
                                className='text-slate-500 text-sm flex-col'
                                maxLines={3}
                            />
                        }
                        <div className='mt-3'>
                            {this.props.loading ?
                                <div className='shimmer br-4 w-40' style={{height: 24}}/>
                                :
                                company?.website ?
                                    <div className='flex flex-row items-center gap-1 cursor-pointer hover:text-primary'>
                                        <SvgLink className={'w-4 h-4'}/>
                                        <p className='m-0 body2'>
                                            {company.website}
                                        </p>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className='flex flex-row gap-8 mt-3'>
                        <CompanyAboutBlock company={company}/>
                        <CompanyInfoBlock company={company}/>
                    </div>
                </div>
                {company?.fun_facts.length ?
                    <CompanyFunFacts
                        name={company.name}
                        items={company.fun_facts}/>
                    : null
                }
                <div className={`card flex flex-col gap-2 p-5`}>
                    <p className='text-lg font-bold'>
                        Similar companies
                    </p>
                    <div className='flex flex-row gap-3 flex-wrap'>
                        {POPULAR_COMPANIES.map(item =>
                                // <Link to={`/company/${item.id}`}>
                                <div key={item.id} onClick={() => this.props.history.push(`/company/${item.id}`)}
                                     className='flex flex-row items-center gap-3 border border-slate-200 hover:border-slate-300 hover:bg-slate-50 cursor-pointer p-3 rounded-xl'>
                                    <CompanyLogo id={item.id} className={'w-10 h-10'}/>
                                    <p className={'text-base font-semibold'}>
                                        {item.name}
                                    </p>
                                </div>
                            // </Link>
                        )}
                    </div>
                </div>
            </>
        )
    }
}

export default CompanyPageAboutTab;
