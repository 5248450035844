import { searchUsers } from "../api/recruiter/users";

/**
 * Gets the club IDs to fetch based on the tier and list
 * 
 * @param {Object} list - The smart list configuration
 * @param {string} tier - User tier (e.g., "blk")
 * @returns {Array} - Array of club IDs to fetch
 */
const getClubsToFetch = (list, tier) => {
  if (tier === "blk") {
    return ["baeZVpmndkm8fCNGFIuZ"];
  }
  return list.clubs || [];
};

/**
 * Searches for users based on a smart list configuration
 * 
 * @param {Object} params - The search parameters
 * @param {Object} params.user - The authenticated user object
 * @param {Object} params.list - The smart list configuration
 * @param {string} params.name - Optional search query for name filtering
 * @param {number} params.page - Page number for pagination
 * @param {string} params.tier - User tier (e.g., "blk")
 * @param {number} params.count - Number of results per page
 * @returns {Promise<Object>} - Search results with users and total count
 */
export const searchUsersFromSmartList = async ({
  user,
  list,
  name = "",
  page = 0,
  tier = null,
  count = null
}) => {
  if (!user || !list) {
    throw new Error("User and list are required parameters");
  }

  // Get club IDs based on tier and list
  const clubIds = getClubsToFetch(list, tier);

  // Build search parameters from the smart list
  /**
   * @type {import('../api/recruiter/users').SearchUsersParams}
   */
  const searchParams = {
    user,
    name,
    page,
    clubIds,
    classYears: list.grades || [],
    majors: list.majors || [],
    colleges: list.colleges || [],
    gpa: list.gpa || null,
    gender: list.gender || null,
    races: list.race || [],
    veteran: list.veteran || null,
    lgbtq: list.lgbtq || null,
    disabled: list.disabled || null,
    authorized: list.authorized || null,
    industries: list.industries || [],
    currentCompanies: list.current_companies || [],
    previousCompanies: list.previous_companies || [],
    isIb: list.is_ib || null,
    verticals: list.verticals || [],
    previousVerticals: list.previous_verticals || [],
    graduationSeason: list.graduation_season || null,
    firstGeneration: list.first_generation || null,
    requireSponsorship: list.require_sponsorship || null
  };

  // Add count parameter if provided
  if (count !== null) {
    searchParams.count = count;
  }

  // Execute the search
  return await searchUsers(searchParams);
}; 