import React, { useState } from "react";
import { Button } from "../../components/atoms/Button";
import { deleteList, editList } from "../../api/recruiter/lists";
import EditListModal from "../modals/EditListModal";
import { DateTime } from 'luxon';
import ClubLogo from "../atoms/ClubLogo";
import SvgSchool from "../../components/icons/SvgSchool";
import SvgClose from "../../components/icons/SvgClose";
import GoBack from "../../components/atoms/GoBack";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import ListHeaderActions from "../atoms/ListHeaderActions";

function RecruiterClubFullListPage({
    list,
    type,
    refreshList,
    authUser,
    history,
    toggleClubPeek,
    setClubPeekClub
}) {
    const [isEditing, setIsEditing] = useState(false);
    const [isEditClubListModalOpen, setIsEditClubListModalOpen] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const delList = async () => {
        await deleteList(authUser, list.id, type);
        history.push('/saved-lists');
    };

    const remove = async (clubId) => {
        await editList(authUser, list.id, type, {
            [`${type}_ids`]: [clubId]
        });
        refreshList();
    };

    const downloadCsv = async () => {
        let csv = `${list.name}\n`;
        csv += 'Club Name,School\n';
        const clubsToDownload = list.clubs || [];
        csv += clubsToDownload.map(club => `"${club.name.replace(/"/g, '""')}","${club.college.replace(/"/g, '""')}"`).join('\n');

        // Create a Blob object from the CSV data
        const blob = new Blob([csv], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);

        // Create a link element and click it programmatically to trigger download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${list.name}.csv`);
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const openClubPeek = (club) => {
        toggleClubPeek();
        setClubPeekClub(club);
    };

    const listCreation = DateTime.fromISO(list.created_at);

    return (
        <div className={`flex flex-col flex-1 overflow-hidden`}>
            <div className='flex flex-row items-end justify-between p-[12px] pb-0'>
                <div>
                    <GoBack/>
                    <div className='flex flex-row items-center mt-1 gap-2 mb-2'>
                        <p className='text-slate-800 text-2xl font-semibold'>
                            {list.name}
                            <span className='text-slate-500'>
                                {` (${list.clubs?.length ?? 0} clubs)`}
                            </span>
                        </p>
                    </div>
                </div>
                <ListHeaderActions 
                    onDownloadCsv={downloadCsv}
                    onEditList={() => setIsEditClubListModalOpen(true)}
                    onDeleteList={() => setConfirmationModalOpen(true)}
                    onRemoveItems={() => setIsEditing(true)}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    removeItemsLabel="Remove Clubs"
                />
            </div>
            <div className={'flex flex-row flex-wrap px-[12px] gap-4 opacity-70'}>
                <div className='flex flex-row items-end'>
                    <p className='text-slate-800 text-sm'>
                        <strong>Created:</strong> {listCreation.toLocaleString(DateTime.DATETIME_MED)} {!!list.creator_name && ` by ${list.creator_name}`}
                    </p>
                </div>
            </div>
            <div className={'flex flex-1 flex-col overflow-hidden px-[12px] pb-[12px]'}>
                <div className='flex flex-col flex-1 overflow-y-scroll'>
                    <div className='pt-3'>
                        {list.clubs?.length === 0 ?
                            <div className='flex flex-col items-center py-12 gap-3'>
                                <p className='text-slate-500'>
                                    No clubs added yet
                                </p>
                                <Button onClick={() => setIsEditClubListModalOpen(true)}>
                                    Add clubs
                                </Button>
                            </div>
                            : list.clubs?.map(item =>
                                <div
                                    key={item.id}
                                    onClick={() => openClubPeek(item)}
                                    className={'p-2 shadow-lg shadow-slate-200 border border-slate-200 rounded-xl bg-white mb-2 cursor-pointer flex flex-row hover:bg-slate-50 items-center gap-3'}>
                                    <div className='flex flex-row items-center gap-5'>
                                        <ClubLogo clubId={item.id} size={64} backupUrl={item.logo_url}
                                                  className={'border bg-white border-slate-200'}/>
                                        <div className='mr-2 flex flex-col gap-2'>
                                            <p className='body1-bold m-0 text-gray-800'>
                                                {item.name}
                                            </p>
                                            <div className='flex flex-row items-center text-neutral-400 gap-1'>
                                                <SvgSchool className={'w-4 h-4'}/>
                                                <p className='body2 m-0'>
                                                    {item.college}&nbsp; {!!item.founded && `• Est. ${item.founded}`}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {isEditing &&
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                remove(item.id);
                                            }}
                                            size={'icon'} variant={'destructive'} icon={SvgClose}
                                            className='ml-auto'/>
                                    }
                                </div>
                            )}
                    </div>
                </div>
            </div>
            <EditListModal
                isOpen={isEditClubListModalOpen}
                currentList={list}
                type={type}
                closeModal={() => setIsEditClubListModalOpen(false)}
                getLists={refreshList}
            />
            <ConfirmationModal
                isOpen={confirmationModalOpen}
                closeModal={() => setConfirmationModalOpen(false)}
                confirmText={'Delete'}
                title={'Are you sure?'}
                onConfirm={delList}
                description={'Are you sure you want to delete this list? This action cannot be undone.'}
            />
        </div>
    );
}

export default RecruiterClubFullListPage; 