import React, {PureComponent} from "react";
import ClubPageTitleWidget from "../clubpage/ClubPageTitleWidget";
import ClubPageTableWidget from "../clubpage/ClubPageTableWidget";
import {getClub} from "../../api/student/clubs";
import {connect} from "react-redux";
import PageContentWrapper from "../wrappers/PageContentWrapper";
import PageLTRWrapper from "../wrappers/PageLTRWrapper";
import {getClubStatistics} from "../../api/student/statistics";
import {getClubPosts} from "../../api/student/posts";
import PageHeaderWrapper from "../wrappers/PageHeaderWrapper";
import { withRouter } from 'react-router';
import Spinner from '../Spinner';
import { APP_ADMINS } from '../../utils/constants';

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        networks: state.userReducer.networks
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateNetwork: (network) => {
            dispatch({
                type: "UPDATE_NETWORK",
                network,
            })
        },
        deleteNetwork: (personId) => {
            dispatch({
                type: "DELETE_NETWORK",
                personId,
            })
        }
    }
}

const START = 1;
const END = 20;

class ClubPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            club: {},
            people: [],
            alumni: [],
            members: [],
            statistics: {},
            posts: [],
            loading: true,
            start: START,
            end: END,
            activeTab: 'Members',
            previousAlumId: null,
            nextAlumId: null,
            previousMemberId: null,
            nextMemberId: null,
            clubId: null,
            userInClub: false,
            numMembers: 0,
            numAlumni: 0,
        };
    }

    refreshBounds = (start, end) => {

    }

    changeTab = (activeTab) => {
        this.setState({activeTab, start: START, end: END});

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("active_tab", activeTab);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        this.props.history.replace(newUrl);
    }

    setNumMembers = (numMembers) => {
        this.setState({numMembers});
    }

    componentDidMount = () => {
        this.getClubInfo();
    }

    componentDidUpdate = (prevProps) => {
        const pathId = this.props?.match?.params?.clubId;
        const locationId = this.props?.location?.state?.club_id;
        const prevPathId = prevProps?.match?.params?.clubId;
        const prevLocationId = prevProps?.location?.state?.club_id;
        const pathDidUpdate = (!!pathId && pathId !== prevPathId);
        const locationDidUpdate = (!!locationId && locationId !== prevLocationId);
        const pathBecameLocation = (!!prevPathId && !!locationId && locationId !== prevPathId)
        const didUpdate = pathDidUpdate || locationDidUpdate || pathBecameLocation;
        if (didUpdate)
            this.getClubInfo();
    }

    getClubInfo = async () => {
        this.setState({loading: true});
        const clubId = this.props.match.params.clubId || this.props.location.state?.club_id;
        const result = await getClub(this.props.authUser, clubId);
        const posts = await getClubPosts(this.props.authUser, clubId);

        const userClubIds = this.props.user.clubs.map(club => club.id)
        const userInClub = APP_ADMINS.includes(this.props.authUser.uid) || userClubIds.includes(clubId);

        const statistics = await getClubStatistics(this.props.authUser, clubId);
        this.setState({
            club: result?.club,
            statistics: statistics,
            posts: posts?.posts,
            clubId: clubId,
            userInClub: userInClub,
            loading: false,
            previousMemberId: null,
            nextMemberId: null,
            numMembers: 0,
            numAlumni: 0,
        }, () => this.refreshBounds(START, END));
        

        const queryParams = new URLSearchParams(window.location.search);
        const activeTab = queryParams.get("active_tab");
        if (!!activeTab && activeTab !== "Members") this.changeTab(activeTab);
    }

    updateLoading = (loading) => {
        this.setState({loading});
    }

    render() {
        const club = this.state.club || {};
        const leadership = club.leadership || {};
        return (
            <PageContentWrapper>
                {this.state.loading ?
                    <div className='flex h-full justify-center items-center'>
                        <Spinner size={24}/>
                    </div>
                : !this.state.userInClub ?
                    <div className='flex h-full justify-center items-center'>
                        <p>This club does not exist</p>
                    </div>
                :
                <>
                <PageHeaderWrapper className={'bg-white'}>
                    <ClubPageTitleWidget club={club} loading={this.state.loading} numMembers={this.state.numMembers}
                         authUser={this.props.authUser}
                         user={this.props.user}
                         history={this.props.history}
                         changeTab={this.changeTab}
                    />
                </PageHeaderWrapper>
                <PageLTRWrapper style={{padding: '0'}}>
                    <div className='flex flex-col flex-1 overflow-hidden bg-slate-50'>
                        <ClubPageTableWidget
                            activeTab={this.state.activeTab}
                            loading={this.state.loading || this.state.tableLoading}
                            club={club}
                            posts={this.state.posts}
                            authUser={this.props.authUser}
                            leadership={leadership}
                            user={this.props.user}
                            getClubInfo={this.getClubInfo}
                            changeTab={this.changeTab}
                            history={this.props.history}
                            setNumMembers={this.setNumMembers}
                            statistics={this.state.statistics}
                            networks={this.props.networks}
                            updateNetwork={this.props.updateNetwork}
                            deleteNetwork={this.props.deleteNetwork}
                        />
                    </div>
                </PageLTRWrapper>
                </>
                }
            </PageContentWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClubPage));
