import React, { useState } from "react";
import { filteredCollegeSearch } from "../../utils/typesense";
import MultiSelectWithSearch from "../atoms/MultiSelectWithSearch";
import SvgSchool from "../../components/icons/SvgSchool";

export default function MultiSearchSchools({ outerLabel, selectedSchools, setSelectedSchools, limit, error, placeholder, rightIcon }) {
    const [schoolSearch, setSchoolSearch] = useState("");
    const [openCollapse, setOpenCollapse] = useState(false);
    const [schoolSearchResults, setSchoolSearchResults] = useState([]);

    const filteredSchoolResults = schoolSearchResults.filter(
        (result) => !selectedSchools.includes(result.name)
    );

    const handleSchoolClick = (school) => {
        if (selectedSchools.includes(school)) {
            setSelectedSchools([
                ...new Set(
                    [...selectedSchools].filter((item) => item !== school)
                ),
            ]);
        } else {
            setSelectedSchools([...new Set([...selectedSchools, school])]);
            setSchoolSearch("");
            setSchoolSearchResults([]);
            setOpenCollapse(false);
        }
    };

    const handleSearchChange = (event) => {
        const text = event.target.value;
        setSchoolSearch(text);

        if (text === "") {
            setOpenCollapse(false);
            setSchoolSearchResults([]);
            return;
        }

        setOpenCollapse(true);
        schoolSearchChanged(text);
    };

    const schoolSearchChanged = async (text) => {
        const results = await filteredCollegeSearch(text);
        setSchoolSearchResults(results);
    };


    return (
        <MultiSelectWithSearch
            outerLabel={outerLabel}
            searchValue={schoolSearch}
            selected={selectedSchools}
            error={error}
            placeholder={placeholder}
            results={filteredSchoolResults}
            openCollapse={openCollapse}
            onSearchChange={handleSearchChange}
            onResultClick={handleSchoolClick}
            onClearAll={() => setSelectedSchools([])}
            resultMapper={(result) => ({
                id: result.id,
                name: result.name,
                imageUrl: result.logo_url,
                value: result.name
            })}
            leftIcon={<SvgSchool className="h-5 w-5 text-slate-500" />}
            rightIcon={rightIcon}
            maxToShow={limit}
        />
    );
}
