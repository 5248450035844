import * as Dialog from '@radix-ui/react-dialog';
import * as Progress from '@radix-ui/react-progress';

/**
 * @typedef {Object} ProgressItem
 * @property {string} name - The name or description of the progress item
 * @property {number} progress - The progress value (0-100)
 */

/**
 * A modal component that displays a progress bar
 * @param {Object} props
 * @param {boolean} props.isOpen - Controls the visibility of the modal
 * @param {(open: boolean) => void} props.onOpenChange - Callback function when modal open state changes
 * @param {number} props.numberOfSteps - Total number of steps
 * @param {number} props.currentStep - Current step index (0-based)
 * @param {string} props.currentName - Name or description of the current step
 */
const ProgressModal = ({ isOpen, onOpenChange, numberOfSteps, currentStep = 0, currentName }) => {
  // Calculate overall progress based on the current step
  const overallProgress = Math.round((currentStep / numberOfSteps) * 100);

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[10000]" />
        <Dialog.Content className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-[400px] shadow-xl z-[10001]" >
          <Dialog.Title className="text-xl font-semibold mb-4">
            {currentName}
          </Dialog.Title>
          
          <div className="space-y-2">
            <Progress.Root className="h-2 bg-gray-200 rounded-full overflow-hidden">
              <Progress.Indicator
                className="h-full bg-blue-500 transition-transform duration-300 ease-out"
                style={{ transform: `translateX(${overallProgress - 100}%)` }}
              />
            </Progress.Root>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ProgressModal; 