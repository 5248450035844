import React, {PureComponent} from "react";
import {connect} from "react-redux";
import PageContentWrapper from "../wrappers/PageContentWrapper";
import {withRouter} from "react-router-dom";
import PageHeaderWrapper from "../wrappers/PageHeaderWrapper";
import AnimatedTabs from "../atoms/AnimatedTabs";
import SvgGroup from "../icons/SvgGroup";
import SvgBriefcase from "../icons/SvgBriefcase";
import SvgCalendar from "../icons/SvgCalendar";
import PersonalizationModal from "../atoms/modals/PersonalizationModal";
import CRMPage from "./CRMPage";
import CalendarPage from "./CalendarPage";
import NetworkingPage from "./NetworkingPage";
import TrackCompanies from "../track/TrackCompanies";
import DropdownComponent from "../atoms/DropdownComponent";
import {Button} from "../atoms/Button";
import SvgPostAdd from "../icons/SvgPostAdd";
import SvgAddCircle from "../icons/SvgAddCircle";
import SvgSearch from "../icons/SvgSearch";
import AddJobModal from "../atoms/modals/AddJobModal";
import SvgCompanyBuilding from "../icons/SvgCompanyBuilding";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
        user: state.userReducer.user,
    };
}

class TrackPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            filter: "All",
            activeTab: 'Jobs',
            modalOpen: false,
            addJobModalOpen: false,
        };
    }

    componentDidMount() {
        
        const queryParams = new URLSearchParams(window.location.search);
        const activeTab = queryParams.get("activeTab");
        if (!!activeTab) this.changeTab(activeTab);
    }

    changeTab = (tab) => {
        this.setState({activeTab: tab, filter: "All"});

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("activeTab", tab);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        this.props.history.replace(newUrl);
    };

    toggleAddJobModal = () => {
        this.setState({addJobModalOpen: !this.state.addJobModalOpen});
    }

    render() {
        const activeTab = this.state.activeTab;
        return (
            <PageContentWrapper>
                <PageHeaderWrapper className={'pt-3 pb-0'}>
                    <div className='relative -mb-2'>
                        <AnimatedTabs
                            activeTab={activeTab}
                            changeTab={this.changeTab}
                            xl
                            tabs={[
                                {title: 'Jobs', icon: SvgBriefcase},
                                {title: 'People', icon: SvgGroup},
                                {title: 'Events', icon: SvgCalendar},
                                {title: 'Companies', icon: SvgCompanyBuilding},
                                ]}
                        />
                        {this.state.activeTab === 'Applications' &&
                            <div className='absolute right-0 -top-2'>
                                <DropdownComponent
                                    ToggleComponent={

                                        <Button variant={'default'}>
                                            <SvgPostAdd className={'w-4 h-4'}/>
                                            Add Application
                                        </Button>
                                    }
                                    value={null}
                                    isControlled={false}
                                    setValue={() => null}
                                    options={
                                        [
                                            {
                                                title: "Enter details manually",
                                                icon: SvgAddCircle,
                                                onClick: this.toggleAddJobModal,
                                            },
                                            {
                                                title: 'Search job board',
                                                icon: SvgSearch,
                                                onClick: () => this.props.history.push({
                                                    pathname: `/discover`,
                                                    state: {
                                                        activeTab: 'Jobs',
                                                    }
                                                }),
                                            },
                                        ]
                                    }
                                    direction="bottom-end"
                                    light
                                />
                            </div>
                        }
                    </div>
                </PageHeaderWrapper>
                {this.state.activeTab === 'Jobs' &&
                    <CRMPage/>
                }
                {this.state.activeTab === 'Events' &&
                    <CalendarPage history={this.props.history}/>
                }
                {this.state.activeTab === 'Companies' &&
                    <TrackCompanies history={this.props.history}/>
                }
                {this.state.activeTab === 'People' &&
                    <NetworkingPage history={this.props.history}/>
                }
                <PersonalizationModal isOpen={this.state.modalOpen}
                                      closeModal={() => this.setState({modalOpen: false})}/>
                <AddJobModal
                    isOpen={this.state.addJobModalOpen}
                    closeModal={this.toggleAddJobModal}
                    personalJob
                />
            </PageContentWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps)(TrackPage));
