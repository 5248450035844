import React from 'react';
import { useHistory } from 'react-router-dom';
import SvgInfo from "../icons/SvgInfo";
import SvgChevronRight from "../icons/SvgChevronRight";

function NUXTopBar({hasPersonalization, showTopBanner}) {
    const history = useHistory();

    const message = "Complete onboarding to boost your profile visibility and personalize your experience" 

    const handleClick = () => {
        if (!hasPersonalization) {
            history.push('/edit-profile');
        }
    };

    if (!showTopBanner) {
        return null;
    }


    return (
        <div
            style={{transition: 'height 150ms ease-in-out'}}
            className={`w-full fixed top-0 left-0 right-0 z-50  ${showTopBanner ? 'h-[48px]' : 'h-[0px]'} overflow-hidden`}>
            <div
                className={`bg-slate-200 text-primary w-full body2-bold flex flex-row h-full px-5 items-center justify-between`}
                onClick={handleClick}>
                <div className='flex flex-row items-center'>
                    <SvgInfo className={'w-5 h-5 mr-2'}/> {message}
                </div>
                <div>
                    <SvgChevronRight className={'w-5 h-5'}/>
                </div>
            </div>
        </div>
    );
}

export default NUXTopBar;
