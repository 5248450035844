import { useState } from "react";
import useConstants from "./useConstants";

/**
 * @typedef {Object} Filters
 * @property {Array<string>} schools - List of schools
 * @property {Array<string>} majors - List of majors
 * @property {Array<string>} classYears - List of class years
 * @property {number|null} gpa - GPA filter
 * @property {Array<string>} clubs - List of clubs
 * @property {Array<string>} currentCompanies - List of current companies
 * @property {Array<string>} previousCompanies - List of previous companies
 * @property {Array<string>} verticals - List of verticals
 * @property {Array<string>} previousVerticals - List of current verticals
 * @property {string|null} gender - Gender filter
 * @property {Array<string>} race - List of races
 * @property {boolean|null} veteran - Veteran status
 * @property {boolean|null} lgbtq - LGBTQ+ status
 * @property {boolean|null} disabled - Disability status
 * @property {boolean|null} authorized - Authorization status
 * @property {boolean|null} isIb - IB status
 * @property {string} search - Search query
 * @property {string|null} sortBy - Sort criteria
 * @property {number} searchSortState - Search sort state
 * @property {number} schoolSortState - School sort state
 * @property {string|null} graduationSeason - Graduation season
 * @property {boolean|null} first_generation - First generation status
 * @property {boolean|null} require_sponsorship - Sponsorship requirement
 */

/** @type {Filters} */
export const INITIAL_FILTERS = {
  schools: [],
  majors: [],
  classYears: [],
  gpa: null,
  clubs: [],
  currentCompanies: [],
  previousCompanies: [],
  verticals: [],
  previousVerticals: [],
  gender: null,
  race: [],
  veteran: null,
  lgbtq: null,
  disabled: null,
  authorized: null,
  isIb: null,
  search: "",
  sortBy: null,
  searchSortState: 0,
  schoolSortState: 0,
  graduationSeason: null,
  first_generation: null,
  require_sponsorship: null,
};

export default function useFiltersState() {
  const { majorCategories } = useConstants();
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [selectedMajorCategories, setSelectedMajorCategories] = useState([]);
  // Flag to determine if we're in search mode (null when unchecked) or qualified only mode (false when unchecked)
  const [isSearchMode, setIsSearchMode] = useState(true);

  function setFilter(filterKey, value) {
    setFilters((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  }



  function handleFilterClassYearsSelection(year) {
    if (!year) {
      setFilter("classYears", []);
      return;
    }
    const isSelected = filters.classYears.includes(year);
    if (isSelected) {
      setFilter(
        "classYears",
        filters.classYears.filter((item) => item !== year)
      );
    } else {
      setFilter("classYears", [...filters.classYears, year]);
    }
  }

  function handleSearchChange(text) {
    setFilter("search", text);
  }

  function toggleSearchSort() {
    const newSortState = (filters.searchSortState + 1) % 3;
    setFilter("searchSortState", newSortState);
  }

  function toggleSchoolSort() {
    const newSortState = (filters.schoolSortState + 1) % 3;
    setFilter("schoolSortState", newSortState);
  }

  function updateDiversity(field, value) {
    // If value is explicitly provided, use it
    if (value !== undefined) {
      setFilter(field, value);
      return;
    }
    
    // Otherwise toggle based on current value
    const currentValue = filters[field];
    
    // In search mode, toggle between true and null
    if (isSearchMode) {
      setFilter(field, currentValue === true ? null : true);
    } else {
      // In qualified only mode, toggle between true and false
      setFilter(field, !currentValue);
    }
  }

  function clearFilters() {
    setFilters(INITIAL_FILTERS);
    setSelectedMajorCategories([]);
  }

  function initFilters(jobOrEventFilters) {
    if (jobOrEventFilters) {
      /**
       * @type {Filters}
       */
      const parsedFilters = {};
      
      // If we're initializing from a job or event, we're in qualified only mode
      setIsSearchMode(false);
      
      if (jobOrEventFilters.schools?.length) parsedFilters.schools = jobOrEventFilters.schools;
      if (jobOrEventFilters.majors?.length) parsedFilters.majors = jobOrEventFilters.majors;
      if (jobOrEventFilters.class_years?.length) parsedFilters.classYears = jobOrEventFilters.class_years;
      if (jobOrEventFilters.gpa) parsedFilters.gpa = jobOrEventFilters.gpa;
      if (jobOrEventFilters.clubs?.length) parsedFilters.clubs = jobOrEventFilters.clubs;
      if (jobOrEventFilters.current_companies?.length) parsedFilters.currentCompanies = jobOrEventFilters.current_companies;
      if (jobOrEventFilters.previous_companies?.length) parsedFilters.previousCompanies = jobOrEventFilters.previous_companies;
      if (jobOrEventFilters.verticals?.length) parsedFilters.verticals = jobOrEventFilters.verticals;
      if (jobOrEventFilters.previous_verticals?.length) parsedFilters.previousVerticals = jobOrEventFilters.previous_verticals;
      if (jobOrEventFilters.gender) parsedFilters.gender = jobOrEventFilters.gender;
      if (jobOrEventFilters.race?.length) parsedFilters.race = jobOrEventFilters.race;
      if (jobOrEventFilters.veteran !== undefined) parsedFilters.veteran = jobOrEventFilters.veteran;
      if (jobOrEventFilters.lgbtq !== undefined) parsedFilters.lgbtq = jobOrEventFilters.lgbtq;
      if (jobOrEventFilters.disabled !== undefined) parsedFilters.disabled = jobOrEventFilters.disabled;
      if (jobOrEventFilters.authorized !== undefined) parsedFilters.authorized = jobOrEventFilters.authorized;
      if (jobOrEventFilters.is_ib !== undefined) parsedFilters.isIb = jobOrEventFilters.is_ib;
      if (jobOrEventFilters.search) parsedFilters.search = jobOrEventFilters.search;
      if (jobOrEventFilters.sort_by !== undefined) parsedFilters.sortBy = jobOrEventFilters.sort_by;
      if (jobOrEventFilters.search_sort_state !== undefined) parsedFilters.searchSortState = jobOrEventFilters.search_sort_state;
      if (jobOrEventFilters.school_sort_state !== undefined) parsedFilters.schoolSortState = jobOrEventFilters.school_sort_state;
      if (jobOrEventFilters.graduation_season) parsedFilters.graduationSeason = jobOrEventFilters.graduation_season;
      if (jobOrEventFilters.first_generation !== undefined) parsedFilters.first_generation = jobOrEventFilters.first_generation;
      if (jobOrEventFilters.require_sponsorship !== undefined) parsedFilters.require_sponsorship = jobOrEventFilters.require_sponsorship;

      setFilters({ ...INITIAL_FILTERS, ...parsedFilters });

      // Populate selected major categories based on majors
      if (parsedFilters.majors?.length) {
        const categories = Object.entries(majorCategories)
          .filter(([category, majors]) => 
            parsedFilters.majors.some(major => majors.includes(major))
          )
          .map(([category]) => category);
        setSelectedMajorCategories(categories);
      } else {
        setSelectedMajorCategories([]);
      }
    } else {
      setFilters(INITIAL_FILTERS);
      setSelectedMajorCategories([]);
    }
  }

  // Set the mode to search (for RecruiterStudentSearch)
  function setSearchMode() {
    setIsSearchMode(true);
  }

  // Set the mode to qualified only (for useQualifiedOnlyState)
  function setQualifiedOnlyMode() {
    setIsSearchMode(false);
  }

  const canClearFilters =
    filters.classYears.length > 0 ||
    filters.majors.length > 0 ||
    filters.schools.length > 0 ||
    filters.gpa !== null ||
    filters.gender !== null ||
    filters.race.length > 0 ||
    filters.veteran !== null ||
    filters.lgbtq !== null ||
    filters.disabled !== null ||
    filters.authorized !== null ||
    filters.first_generation !== null ||
    filters.require_sponsorship !== null ||
    filters.clubs.length > 0 ||
    filters.currentCompanies.length > 0 ||
    filters.previousCompanies.length > 0 ||
    filters.verticals.length > 0 ||
    filters.previousVerticals.length > 0 ||
    filters.search !== "" ||
    filters.schoolSortState !== 0 ||
    filters.searchSortState !== 0 ||
    filters.graduationSeason !== null;


  return {
    filters,
    setFilter,
    handleFilterClassYearsSelection,
    handleSearchChange,
    toggleSearchSort,
    toggleSchoolSort,
    updateDiversity,
    clearFilters,
    initFilters,
    selectedMajorCategories,
    setSelectedMajorCategories,
    setSearchMode,
    setQualifiedOnlyMode,
    isSearchMode,
    canClearFilters,
  };
} 