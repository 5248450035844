import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {Button} from "../../components/atoms/Button";
import {Link, withRouter} from "react-router-dom";
import { searchClubs } from "../../utils/typesense";
import { createList, editList } from "../../api/recruiter/lists";
import {connect} from "react-redux";
import Spinner from "../../components/Spinner";
import ProfilePicture from "../atoms/ProfilePicture";
import { searchUsers } from "../../api/recruiter/users";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        tier: state.userReducer.tier,
    };
}

const ZERO_STATE = {
    name: '',
    search: '',
    searchResults: [],
    list: [],
    collapseOpen: false,
    listId: null,
    loading: false
}

class EditListModal extends Component {
    state = ZERO_STATE

    nameChanged = e => this.setState({name: e.target.value});

    searchChanged = async (e) => {
        const text = e.target.value;
        let searchResults = [];
        if (text.length > 0) {
            if (this.props.type === "user") {
                const clubId = this.props.tier === "blk" ? "baeZVpmndkm8fCNGFIuZ" : null;
                searchResults = await searchUsers({
                    user: this.props.authUser,
                    name: text,
                    page: 1,
                    clubIds: clubId ? [clubId] : []
                });
                searchResults = searchResults.students;
            } else {
                searchResults = await searchClubs(text);
            }
        }
        this.setState({search: text, searchResults, collapseOpen: true});
    };

    addToList = itemToAdd => {
        if (!this.state.list.some(item => item.id === itemToAdd.id)) {
            this.setState(prevState => ({
                search: '',
                searchResults: [],
                collapseOpen: false,
                list: [...prevState.list, itemToAdd]
            }));
        }
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen !== this.props.isOpen && !!this.props.currentList) {
            this.setState({
                name: this.props.currentList.name,
                listId: this.props.currentList.id
            });
        }
        if (prevProps.club !== this.props.club && this.props.club.id) {
            this.setState({list: [this.props.club]});
        }
    }

    removeFromList = async (itemToRemove) => {
        this.setState(prevState => ({
            list: prevState.list.filter(item => item.id !== itemToRemove.id)
        }));
    }

    update = async () => {
        this.setState({loading: true});
        if (this.props.isNewList) {
            await createList(this.props.authUser, {
                name: this.state.name,
                type: this.props.type,
                ids: []
            });
        } else {
            // Todo: new lists
            await editList(this.props.authUser, this.state.listId, this.props.type, {
                name: this.state.name,
                // ids: this.state.list.map(item => item.id)
            });
        }

        this.props.closeModal();
        this.setState(ZERO_STATE);
        this.props.history.push(`/search?active_tab=${this.props.type === "user" ? "Students" : "Clubs"}`)
    }
    
    render() {
        const {isNewList, type} = this.props;
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={isNewList ? `Create A New List` : 'Rename This List'}
                headerToggle
                size={'md'}
                toggle={this.props.closeModal}
                showScrollBar
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button variant={'secondary'} onClick={this.props.closeModal}>
                            Cancel
                        </Button>
                        {this.state.loading ?
                            <Spinner/>
                            :
                            <Button onClick={this.update} disabled={!this.state.name}>
                                {isNewList ? 'Create' : 'Save'} List + Add {type === "user" ? "Students" : "Clubs"}
                            </Button>
                        }
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className='flex flex-col gap-5 w-full'>
                    <div className="col-plain w-full">
                        <p className="body3-bold text-neutral-400 mb-1">
                            List Name
                        </p>
                        <input
                            value={this.state.name}
                            onChange={this.nameChanged}
                            placeholder={'Enter a name for this list'}
                            className='input-light w-full px-3 body2 base-black-50'
                        />
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default withRouter(connect(mapStateToProps)(EditListModal));