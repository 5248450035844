import * as Dialog from '@radix-ui/react-dialog';
import SvgClose from "../icons/SvgClose";
import React, {PureComponent} from "react";
import SvgAddCircle from "../icons/SvgAddCircle";
import {createAlumni} from '../../api/student/admin';
import {connect} from "react-redux";
import {searchCompanies} from "../../utils/typesense";
import { TOAST_OPTIONS } from "../../utils/constants";
import { toast } from 'react-toastify';

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}


class AddAlumniModal extends PureComponent {
    state = {
        firstName: '',
        lastName: '',
        title: '',
        linkedIn: "",
        companyName: "",
        loading: false,
        email: "",
        secondaryEmail: "",
        phone: "",
        gradYear: "",
        industry: "",
        companyId: "",
        searchResults: [],
    }

    titleChanged = e => this.setState({title: e.target.value})
    linkedInChanged = e => this.setState({linkedIn: e.target.value});
    firstNameChanged = e => this.setState({firstName: e.target.value});
    lastNameChanged = e => this.setState({lastName: e.target.value});
    emailChanged = e => this.setState({email: e.target.value});
    secondaryEmailChanged = e => this.setState({secondaryEmail: e.target.value});
    phoneChanged = e => this.setState({phone: e.target.value});
    gradYearChanged = e => this.setState({gradYear: e.target.value});
    industryChanged = e => this.setState({industry: e.target.value});

    companyChanged = async (e) => {
        const text = e.target.value;
        this.setState({companyName: text});
        const searchResults = await searchCompanies(text);
        this.setState({searchResults});
    }

    setCompany = (result) => {
        this.setState({
            searchResults: [],
            companyId: result.id,
            companyName: result.name,
        })
    }


    trySaveManualNetwork = async () => {
        this.setState({loading: true});
        createAlumni(this.props.authUser, {
            company_id: this.state.companyId,
            club_id: this.props.club.id,
            email: this.state.email,
            secondary_email: this.state.secondaryEmail,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            linkedin: this.state.linkedIn,
            alumni: true,
            grade: this.state.gradYear
        });
        this.setState({
            firstName: "",
            lastName: "",
            title: "",
            linkedIn: "",
            companyName: "",
            loading: false,
            email: "",
            secondaryEmail: "",
            phone: "",
            gradYear: "",
            industry: "",
            companyId: "",
            searchResults: [],
        })
        toast.promise(
            Promise.resolve('New alumni added! Processing their linkedin...'),
            {
                pending: 'Adding alumni...',
                success: 'New alumni added! Processing their linkedin...',
                error: 'Failed to add alumni.',
            },
            TOAST_OPTIONS
        );
        }


    render() {
        const disabled = !this.state.firstName || !this.state.lastName || !this.state.linkedIn.startsWith("https://www.linkedin.com/");
        return (
            <Dialog.Root>
                <Dialog.Trigger asChild>
                    {this.props.children}
                </Dialog.Trigger>
                <Dialog.Portal>
                    <Dialog.Overlay className="DialogOverlay"/>
                    <Dialog.Content className="DialogContent" style={{maxWidth: '50vw'}}>
                        <Dialog.Title className="title2 text-neutral-900">
                            Add an alumni to your club's network!
                        </Dialog.Title>
                        <Dialog.Description className="DialogDescription body2 text-neutral-400">
                            These alumni can only be viewed by your club members. Add a few quick details and add them
                            to the network! You can easily edit or add information later.
                        </Dialog.Description>
                        <div className='flex flex-col w-full gap-5'>
                            <div className='flex flex-row gap-5'>
                                <div className="col-plain w-full">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        First Name
                                    </p>
                                    <input
                                        value={this.state.firstName}
                                        onChange={this.firstNameChanged}
                                        placeholder={'John'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                                <div className="col-plain w-full">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        Last Name
                                    </p>
                                    <input
                                        value={this.state.lastName}
                                        onChange={this.lastNameChanged}
                                        placeholder={'Doe'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                                <div className="col-plain w-1/2">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        Grad Year
                                    </p>
                                    <input
                                        value={this.state.gradYear}
                                        onChange={this.gradYearChanged}
                                        placeholder={2023}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                        type={'number'}
                                    />
                                </div>
                            </div>
                            {/* <div className="col-plain w-full">
                                <p className="body3-bold text-neutral-400 mb-1">
                                    Company
                                </p>
                                <input
                                    value={this.state.companyName}
                                    onChange={this.companyChanged}
                                    placeholder={'ie. Morgan Stanley'}
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                            </div>
                            {this.state.searchResults.map(result => {
                                return (
                                    <button className='row-plain' onClick={() => this.setCompany(result)}>
                                        <img src={result.logo_url} alt={'club-icon'}
                                             className='w-8 h-8 rounded-sm border object-contain bg-white border-neutral-200 mr-2'/>
                                        <div className='col-plain'>
                                            <p className='body3'>{result.name}</p>
                                            <p className='body4'>{result.sector}</p>
                                        </div>
                                    </button>
                                )
                            })} */}
                            {/* <div className='flex flex-row gap-5'>
                                <div className="col-plain w-full">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        Title
                                    </p>
                                    <input
                                        value={this.state.title}
                                        onChange={this.titleChanged}
                                        placeholder={'ie. Risk Management Analyst'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                                <div className="col-plain w-full">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        Industry
                                    </p>
                                    <input
                                        value={this.state.industry}
                                        onChange={this.industryChanged}
                                        placeholder={'ie. Investment Banking'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                            </div> */}
                            <div className='flex flex-row gap-5'>
                                <div className="col-plain w-full">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        Email
                                    </p>
                                    <input
                                        value={this.state.email}
                                        onChange={this.emailChanged}
                                        placeholder={'ie. John.Doe@doe.com'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                                <div className="col-plain w-full">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        Secondary Email
                                    </p>
                                    <input
                                        value={this.state.secondaryEmail}
                                        onChange={this.secondaryEmailChanged}
                                        placeholder={'ie. John.Doe@doe.com'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                            </div>
                            <div className="col-plain w-full">
                                <p className="body3-bold text-neutral-400 mb-1">
                                    LinkedIn
                                </p>
                                <input
                                    value={this.state.linkedin}
                                    onChange={this.linkedInChanged}
                                    placeholder={'ie. https://www.linkedin.com/in/john-doe/'}
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                            </div>
                            <div className="col-plain w-full">
                                <p className="body3-bold text-neutral-400 mb-1">
                                    Phone
                                </p>
                                <input
                                    value={this.state.phone}
                                    onChange={this.phoneChanged}
                                    placeholder={'ie. 1234567890'}
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                            </div>
                        </div>
                        <div className='flex-row gap-3 mt-10'
                             style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Dialog.Close asChild onClick={this.props.close}>
                                <button className="secondary-button body1-bold">Cancel</button>
                            </Dialog.Close>
                            <Dialog.Close asChild onClick={this.props.close}>
                                <button
                                    onClick={() => this.trySaveManualNetwork()}
                                    disabled={disabled}
                                    className={`primary-button body1-bold ${disabled && 'opacity-50 cursor-not-allowed'}`}
                                    >Add alumni
                                </button>
                            </Dialog.Close>
                        </div>
                        <Dialog.Close asChild onClick={this.props.close}>
                            <button className="IconButton" aria-label="Close">
                                <SvgClose/>
                            </button>
                        </Dialog.Close>
                    </Dialog.Content>
                </Dialog.Portal>
            </Dialog.Root>
        )
    }
}

export default connect(mapStateToProps)(AddAlumniModal);
