import React from "react";
import { Button } from "../../components/atoms/Button";
import SvgMoreVertical from "../../components/icons/SvgMoreVertical";
import MessageStudentFlow from "../MessageStudentFlow";


function ListHeaderActions({
    users,
    loadingUsers,
    onDownloadCsv,
    onDownloadResumes,
    onEditList,
    onDeleteList,
    onRemoveItems,
    isEditing,
    setIsEditing,
    removeItemsLabel = "Remove Candidates",
    buttonVariant = "secondary",
    onEmailBlast
}) {
    return (
        <div className='flex flex-row gap-3'>
            {users && <MessageStudentFlow users={users} buttonVariant={buttonVariant} onEmailBlast={onEmailBlast} loadingUsers={loadingUsers} />}
            
            {isEditing && (
                <Button
                    variant={'green'}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsEditing(false);
                    }}>
                    Done Editing
                </Button>
            )}
            
            <div className={`group z-50 relative py-3 ml-1 pointer px-1 flex flex-row items-center h-10 hover:bg-white/10 active:bg-white/20 rounded-lg cursor-pointer`}>
                <Button
                    icon={SvgMoreVertical} 
                    size={'icon'}
                    variant={'secondary'}
                />
                <div className='hidden w-[200px] group-hover:block absolute p-1 top-[40px] right-0 bg-white shadow-xl border border-slate-300 rounded-xl'>
                    <div className='w-full hover:text-primary hover:bg-slate-100 rounded-lg'>
                        <div onClick={onDownloadCsv} className='px-3 py-2 hover:bg-slate-100 rounded-lg'>
                            Download CSV
                        </div>
                    </div>
                    
                    {onDownloadResumes && (
                        <div className='w-full hover:text-primary hover:bg-slate-100 rounded-lg'>
                            <div className='block px-3 py-2 w-full' onClick={onDownloadResumes}>
                                Download Resumes
                            </div>
                        </div>
                    )}
                    
                    {onRemoveItems && (
                        <div className='w-full hover:text-primary hover:bg-slate-100 rounded-lg'>
                            <div className='block px-3 py-2 w-full' onClick={() => {
                                if (typeof onRemoveItems === 'function') {
                                    onRemoveItems();
                                } else {
                                    setIsEditing(true);
                                }
                            }}>
                                {removeItemsLabel}
                            </div>
                        </div>
                    )}
                    
                    <div className='w-full hover:text-primary'>
                        <div onClick={(e) => {
                            e.stopPropagation();
                            onEditList();
                        }} className='px-3 py-2 hover:bg-slate-100 rounded-lg'>
                            Edit List
                        </div>
                    </div>
                    
                    <div className='w-full hover:text-primary'>
                        <div onClick={(e) => {
                            e.stopPropagation();
                            onDeleteList();
                        }} className='px-3 py-2 hover:bg-slate-100 rounded-lg'>
                            Delete List
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListHeaderActions; 