import React, {Component} from "react";
import {Link} from "react-router-dom";
import CompanyLogo from "./atoms/CompanyLogo";
import TagComponent from "./atoms/TagComponent";
import SvgCheckCircle from "./icons/SvgCheckCircle";
import Checkbox from "./atoms/Checkbox";
import {editTracker} from "../api/student/trackers";
import SvgMagic from "./icons/SvgMagic";
import Tooltip from "./atoms/modals/Tooltip";
import SvgSchool from "./icons/SvgSchool";
import SvgLinkedIn from "./icons/SvgLinkedIn";
import SvgCalendar from "./icons/SvgCalendar";
import {DateTime} from "luxon";


const CompanyProgressItem = ({label, value, goal}) => {
    const pct = (value / goal) * 100
    const completed = value >= goal
    return (
        <div>
            <div className='flex flex-row items-center gap-2'>
                <p className={`text-xl font-semibold`}>
                    {value}
                    {/*<span className='text-slate-400'>/{goal}</span>*/}
                </p>
                {!!value > 0 &&
                    <SvgCheckCircle className={'w-5 h-5 text-green-500'}/>
                }
            </div>
            <p className='text-slate-500 text-sm'>
                {label}
            </p>
            {/*<div className='flex flex-row items-center gap-2'>*/}
            {/*    <div className='bg-primary/10 w-[140px] overflow-hidden rounded-full'>*/}
            {/*        <div className={`h-1.5 ${completed ? 'bg-green-500' : 'bg-primary'}`}*/}
            {/*             style={{width: completed ? '100%' : (pct + '%')}}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

const ApplicationItem = ({application, openApplicationPeek}) => {
    const job = application.job
    let location = null;
    if (!!job.city) {
        if (job.city.length === 1) {
            location = job.city[0] + ", " + job.state[0]
        } else {
            location = "Multiple Locations"
        }
    }
    return (
        <div
            className={`row-ac-jb p-3 rounded-xl bg-white cursor-pointer border border-slate-200 hover:border-slate-300`}
            onClick={() => openApplicationPeek(job)}>
            <div className='row-ac' style={{flex: 2}}>
                <div className='ml-8 mr-8 flex flex-col gap-0'>
                    <div className='flex flex-col gap-0.5'>
                        {!!job.deprecated &&
                            <Tooltip
                                toggleComponent={
                                    <span className='bg-slate-200 px-2 rounded-md inline mr-a my-auto inline'>
                                                <p className='text-xs text-slate-500'>
                                                    Expired
                                                </p>
                                            </span>
                                }
                                label={'This job has been removed from RecruitU. You can no longer navigate to the full job page.'}
                            />
                        }
                        <p className='base-black-70 text-md font-semibold m-0'>
                            {job.name}
                        </p>
                    </div>
                    <p className='text-sm text-slate-500 m-0 capitalize'>
                        {job.type} {!!location ? "•" : ""} {location}
                    </p>
                    <div className='flex flex-row items-center gap-2 wrap mt-1'>
                        {!!job.type ? <TagComponent sm type={'random'} label={job.type}/> : null}
                        <TagComponent sm label={job.industry}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const PersonItem = ({person, openNetworkingPeek}) => {
    return (
        <div
            className={`row-ac-jb p-3 rounded-xl bg-white cursor-pointer border border-slate-200 hover:border-slate-300`}
            onClick={() => openNetworkingPeek(person.person_id)}>
            <div className='flex flex-row items-center wrap flex-1 gap-3'>
                <div className='flex flex-col'>
                    {!!person.grade &&
                        <div
                            className={`flex flex-row ${person?.grade ? 'text-primary' : 'text-slate-500'} items-center gap-1.5`}>
                            <SvgSchool className={'w-3.5 h-3.5'}/>
                            <p className='text-sm font-semibold'>
                                {person?.grade || 'N/A'}
                            </p>
                        </div>
                    }
                    <div className='flex flex-row items-center gap-1'>
                        <p className='text-md font-semibold'>
                            {person?.first_name} {person?.last_name}
                        </p>
                        {!!person?.linkedin ?
                            <a className='row-ac text-neutral-400 hover:text-neutral-600 cursor-pointer'
                               target={'_blank'}
                               rel={'noreferrer'}
                               href={person?.linkedin}>
                                <SvgLinkedIn className={'w-5 h-5'}/>
                            </a>
                            : null}
                    </div>
                    <div className='flex flex-row items-center gap-1'>
                        {!!person.title &&
                            <p className={`body3 base-black-70 ${!person.title && 'opacity-50'}`}>
                                {person.title}
                            </p>
                        }
                    </div>
                    {/* <div className='mr-auto flex flex-row items-center gap-2 mt-1'>
                        <TagComponent label={person.industry}/>
                        <p className='mr-3 text-sm text-slate-500'>
                            Source: {person.source === "club" ? "Club" : person.source === "recruitu.io" ? "RecruitU" : "Personal"}
                        </p>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

const EventItem = ({event}) => {
    const deadline = DateTime.fromISO(event.event_date);
    const createdAt = DateTime.fromISO(event.created_at);

    const classYears = event.class_years || [];
    classYears.sort();
    let yearText = '';

    if (classYears.length === 1) {
        yearText = `Class of '${classYears[0].toString().slice(-2)}`;
    } else if (classYears.length > 1) {
        const minYear = classYears[0].toString().slice(-2);
        const maxYear = classYears[classYears.length - 1].toString().slice(-2);
        yearText = `Class of '${minYear} - '${maxYear}`;
    }
    return (
        <div
            className={`row-ac-jb p-3 rounded-xl bg-white cursor-pointer border border-slate-200 hover:border-slate-300`}>
            <div className='flex flex-col'>
                <div className='flex flex-row items-center gap-3'>
                    {!!event.deprecated ?
                        <div className='flex flex-row text-primary items-center gap-1.5 mb-1'>
                            <Tooltip
                                toggleComponent={
                                    <span
                                        className='bg-slate-200 px-2 rounded-md inline mr-a my-auto inline'>
                                            <p className='text-xs text-slate-500'>
                                                Expired
                                            </p>
                                        </span>
                                }
                                label={'This event has been removed from RecruitU. You can no longer navigate to the full event page.'}
                            />
                        </div>
                        :
                        !!yearText ?
                            <div className='flex flex-row text-primary items-center gap-1.5 mb-1'>
                                <SvgCalendar className={'w-3.5 h-3.5'}/>
                                <p className='text-sm font-semibold'>
                                    {yearText}
                                </p>
                            </div>
                            : null}
                </div>
                <p className='text-md font-semibold'>
                    {event.name}
                </p>
                <div className='mr-a mt-1 flex flex-row items-center gap-2 wrap mb-3'>
                    <TagComponent label={event.type || 'Early Insights Program'}/>
                    <TagComponent label={event.industry}/>
                    <div className='flex flex-row items-center gap-2 flex-1 truncate mt-1'>
                        {createdAt.isValid &&
                            <div className='flex flex-row gap-1 flex-1 truncate'>
                                <p className='text-sm text-slate-500'>
                                    Posted: {createdAt.toRelative() || ''}
                                </p>
                            </div>
                        }
                        {/* <p className='mr-3 text-slate-500 text-sm'>
                            Source: {event.exclusive ? event.company_name : !!event.creator ? "Personal" : "RecruitU"}
                        </p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

class CompanyProgressOverview extends Component {
    state = {
        isBodyCollapsed: true,
    }

    toggleCollapse = () => {
        this.setState({isBodyCollapsed: !this.state.isBodyCollapsed})
    }

    toggleCheckbox = async () => {
        const newTracker = {...this.props.companyTracker};
        const newStatus = !newTracker.status
        newTracker.status = newStatus
        this.props.updateTracker(newTracker);
        await editTracker(this.props.authUser, newTracker.id, {status: newStatus});

    }

    render() {
        const company = this.props.companyTracker?.company;
        const companyId = company.id;
        const companyApplications = Object.values(this.props.applications).filter(application => application.company_id === companyId && application.personal_status !== 'To apply');
        const companyNetworks = Object.values(this.props.networks).filter(network => network.person.company_id === companyId && network.status === "Contacted");
        const companyEvents = Object.values(this.props.trackers).filter(tracker => tracker.type === "event" && tracker.event.company_id === companyId && tracker.status);

        const actionNeededCompanyApplications = Object.values(this.props.applications).filter(application => application.company_id === companyId && application.personal_status === 'To apply');
        const actionNeededCompanyNetworks = Object.values(this.props.networks).filter(network => network.person.company_id === companyId && network.status === "Not contacted");
        // Todo:fix the one below this comment
        const actionNeededCompanyEvents = Object.values(this.props.trackers).filter(tracker => tracker.type === "event" && tracker.event.company_id === companyId && !tracker.status);
        const applicationsGoal = 1
        const eventsGoal = 3
        const networkingGoal = 5
        const eventsPct = (companyEvents / eventsGoal) * 100
        const networkingPct = (companyNetworks / networkingGoal) * 100
        const nextMessage = companyApplications.length === 0 ? 'Submit an application' :
            networkingPct <= eventsPct ? 'Network with more contacts' : 'Register for an event'
        return (
            <div
                className={`flex flex-col border-b border-slate-200 last:border-none transition-all overflow-hidden duration-300 ${false && 'my-3 bg-white border rounded-xl shadow-xl'}`}>
                <div key={company.id}
                     onClick={() => !this.props.headerOnly && this.toggleCollapse()}
                     className={`${!this.props.headerOnly && 'py-2'} flex  flex-row items-center`}>
                    <div
                        className='flex flex-row gap-3 items-center flex-1 mr-auto'>
                        {!this.props.headerOnly &&
                            <CompanyLogo id={company.id} className={'w-16 h-16'}/>
                        }
                        <div className='flex flex-col items-start gap-1'>
                            <p className='text-lg font-semibold'>
                                {company.name}
                            </p>
                            {this.props.companyTracker?.status ?
                                <div
                                    className='flex flex-row bg-emerald-100 py-1 rounded-full mt-1 text-emerald-600 px-3 items-center'>
                                    <SvgCheckCircle className={'w-4 h-4 mr-1'}/>
                                    <p className='text-xs font-semibold'>
                                        Congrats on your offer!
                                    </p>
                                </div>
                                :
                                <div
                                    className='flex flex-row bg-primary/10 py-1 rounded-full mt-1 text-primary px-3 items-center'>
                                    <SvgMagic className={'w-4 h-4 mr-1'}/>
                                    <p className='text-xs font-semibold'>
                                        Suggested: {nextMessage}
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='flex flex-row items-center gap-8'>
                        <CompanyProgressItem value={companyApplications.length} goal={applicationsGoal}
                                             label={'Jobs applied'}/>
                        <CompanyProgressItem value={companyEvents.length} goal={eventsGoal}
                                             label={'Events registered'}/>
                        <CompanyProgressItem value={companyNetworks.length} goal={networkingGoal}
                                             label={'Networking calls'}/>
                      <div className='ml-20'>
                          <Checkbox
                              checked={this.props.companyTracker?.status}
                              onClick={this.toggleCheckbox}
                              label={'Offered'}
                          />
                      </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CompanyProgressOverview
