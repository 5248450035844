import React, {PureComponent} from "react";
import TrackedJobsWidget from "../crm/TrackedJobsWidget";
import {editApplication} from "../../api/student/applications";
import {connect} from "react-redux";
import ApplicationPeek from "../crm/ApplicationPeek";
import PageContentWrapper from "../wrappers/PageContentWrapper";
import {toast} from "react-toastify";
import {TOAST_OPTIONS} from "../../utils/constants";
import PageHeaderWrapper from "../wrappers/PageHeaderWrapper";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        path: state.userReducer.path,
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
        constants: state.userReducer.constants
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateApplication: (application) => {
            dispatch({
                type: "UPDATE_APPLICATION",
                application,
            })
        },
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
        setPath: (path) => {
            dispatch({
                type: "SET_PATH",
                path,
            })
        },
    }
}

class CRMPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            listingPreviewOpen: false,
            applications: [],
            applicationPreviewOpen: false,
            jobsOfTheWeekOpen: true,
            search: "",
            companyId: null,
            companyName: null,
            location: [],
            jobType: null,
            classYear: null,
            industry: [],
        };
    }

    componentDidMount = async () => {
        if (!!this.props.path && this.props.path !== null && this.props.path !== "null") {
            this.props.history.push({pathname: `/` + this.props.path});
        }
    }

    editApplication = async (applicationId, jobId, params) => {
        try {
            const editPromise = editApplication(this.props.authUser, applicationId, params);
            toast.promise(
                editPromise,
                {
                    pending: 'Updating...',
                    success: 'Application updated!',
                    error: 'Failed to update application!',
                },
                TOAST_OPTIONS
            );
            const editResult = await editPromise
            if (editResult) {
                const newApplication = {...this.props.applications[jobId]};
                for (const key of Object.keys(params)) {
                    newApplication[key] = params[key];
                }
                this.props.updateApplication(newApplication);
            }
        } catch (error) {
            toast.error('Failed to update application!', TOAST_OPTIONS);
        }
    }

    previewApplication = (application) => {
        this.setState({applicationPreview: application, applicationPreviewOpen: true, currentJobId: application.job_id})
    }

    closeListingPreview = () => {
        this.setState({applicationPreviewOpen: false})
    }

    openJobsOfTheWeek = () => {
        this.setState({jobsOfTheWeekOpen: true})
    }

    closeJobsOfTheWeek = () => {
        this.setState({jobsOfTheWeekOpen: false})
    }

    updateIndustry = (selectedIndustry) => {
        const industryArray = [...this.state.industry];

        let updatedIndustry;
        if (industryArray.includes(selectedIndustry)) {
            updatedIndustry = industryArray.filter((item) => item !== selectedIndustry);
        } else if (selectedIndustry === null) {
            updatedIndustry = [];
        } else {
            updatedIndustry = [...industryArray, selectedIndustry]
        }

        this.setState({industry: updatedIndustry});
    };

    updateLocation = (selectedLocation) => {
        const locationArray = [...this.state.location];

        let updatedLocation;
        if (locationArray.includes(selectedLocation)) {
            updatedLocation = locationArray.filter((item) => item !== selectedLocation);
        } else if (selectedLocation === null) {
            updatedLocation = [];
        } else {
            updatedLocation = [...locationArray, selectedLocation]
        }

        this.setState({location: updatedLocation});
    }

    render() {
        const bodyHeight = this.state.height + 69;
        const INDUSTRY_TAGS = this.props.constants.industry_tags.map(tag => ({title: tag, value: tag}));
        const LOCATION_TAGS = this.props.constants.locations.map(loc => ({title: loc.city}));
        const allApplications = Object.values(this.props.applications) || [];
        allApplications.sort(function (first, second) {
            return new Date(second.created_at) - new Date(first.created_at);
        });
        const applications = allApplications.filter(application => {
            const job = application.job;
            let nameMatch = true;
            const {companyId, search, location, jobType, classYear, industry} = this.state;
            if (!!search) {
                const lowerCaseName = job.name.toLowerCase();
                nameMatch = lowerCaseName.startsWith(search.toLowerCase());
            }

            let companyMatch = true;
            if (!!companyId) {
                companyMatch = job.company_id === companyId;
            }
            let jobTypeMatch = true;
            if (!!jobType) {
                jobTypeMatch = job.type === jobType
            }
            let locationMatch = true;
            if (location.length) {
                const set1 = new Set(job.city)
                const commonItems = location.filter(item => set1.has(item));
                locationMatch = commonItems.length > 0;
            }
            let classYearMatch = true;
            if (!!classYear) {
                classYearMatch = job.class_years.includes(classYear)
            }
            let industryMatch = true;
            if (industry.length) {
                industryMatch = job.industry === industry;
            }
            return nameMatch && companyMatch && locationMatch && jobTypeMatch && classYearMatch && industryMatch;
        });
        return (
            <PageContentWrapper>
                <div className='px-5 flex-1 overflow-hidden flex mt-2'>
                    <PageHeaderWrapper
                        className={'py-0 md:py-0 lg:py-0 sm:px-0 md:px-0 lg:px-0 mb-2'}
                        search={{
                            placeholder: 'Search jobs...',
                            value: this.state.search,
                            onChange: (e) => this.setState({search: e.target.value}),
                        }}
                        selects={[
                            {
                                label: 'Location',
                                value: this.state.location,
                                setValue: this.updateLocation,
                                options: LOCATION_TAGS,
                                multiSelect: true,
                            },
                            {
                                label: 'Job type',
                                value: this.state.jobType,
                                setValue: (jobType) => this.setState({jobType}),
                                options: [{title: "Internship",}, {title: "Full Time",}]
                            },
                            {
                                label: 'Class year',
                                value: this.state.classYear,
                                setValue: (classYear) => this.setState({classYear}),
                                options: [{title: "2025",}, {title: "2026"}, {title: '2027'}, {title: '2028'},]
                            },
                            {
                                label: 'Industry',
                                value: this.state.industry,
                                setValue: this.updateIndustry,
                                options: INDUSTRY_TAGS,
                                multiSelect: true,
                            },
                        ]}

                        setCompany={this.setCompany}
                        company={{id: this.state.companyId, name: this.state.companyName}}

                    />
                    <div className='flex-col flex-1 flex overflow-y-scroll hide-scrollbar mb-0 -mr-5'>
                        <TrackedJobsWidget applications={applications}
                                           previewApplication={this.previewApplication}
                                           editApplication={this.editApplication}
                                           changeTab={this.props.changeTab}
                        />
                    </div>
                </div>
                {applications.length > 0 &&
                    <ApplicationPeek
                        open={this.state.applicationPreviewOpen}
                        previousPage={"home"}
                        pageName={"home"}
                        listing={this.state.applicationPreview?.job}
                        application={this.props.applications[this.state.currentJobId]}
                        editApplication={this.editApplication}
                        close={this.closeListingPreview}
                    />
                }
            </PageContentWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMPage);

