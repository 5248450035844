import React, { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { connect } from "react-redux";
import { getSingleList } from "../../api/recruiter/lists";
import RecruiterClubFullListPage from "./RecruiterClubFullListPage";
import RecruiterSmartFullListPage from "./RecruiterSmartFullListPage";
import RecruiterUserFullListPage from "./RecruiterUserFullListPage";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        recruiterCompany: state.userReducer.recruiterCompany,
        tier: state.userReducer.tier,
        constants: state.userReducer.constants,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleCreateJobModal: (clubs) => {
            dispatch({
                type: "TOGGLE_CREATE_JOB_MODAL",
                clubs
            })
        },
        toggleCreateEventModal: (clubs) => {
            dispatch({
                type: "TOGGLE_CREATE_EVENT_MODAL",
                clubs
            })
        },
        toggleClubPeek: () => {
            dispatch({
                type: "TOGGLE_CLUB_PEEK",
            })
        },
        setClubPeekClub: (club) => {
            dispatch({
                type: "SET_CLUB_PEEK_CLUB",
                user: club,
            })
        },
    }
}

function RecruiterFullListPage(props) {
    const [type, setType] = useState("");
    const [list, setList] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getList();
    }, []);

    const getList = async () => {
        setLoading(true);
        const parts = window.location.pathname.split("/");
        const lastArg = parts[3];
        const type = parts[2];
        const listId = lastArg.split("?")[0];

        const res = await getSingleList(props.authUser, listId, type);
        setList(res.list);
        setType(type);
        setLoading(false);
    };

    if (loading) {
        return (
            <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                <Spinner size={40}/>
            </div>
        );
    }

    // Route to the appropriate component based on list type
    switch (type) {
        case "club":
            return <RecruiterClubFullListPage 
                list={list} 
                type={type} 
                refreshList={getList} 
                {...props} 
            />;
        case "smart":
            return <RecruiterSmartFullListPage 
                list={list} 
                type={type} 
                refreshList={getList} 
                {...props} 
            />;
        case "user":
            return <RecruiterUserFullListPage 
                list={list} 
                type={type} 
                refreshList={getList} 
                {...props} 
            />;
        default:
            return (
                <div className='flex flex-col items-center justify-center flex-1'>
                    <p>Unknown list type</p>
                </div>
            );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterFullListPage);
