import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import CreateApplicationQuestions from "./create-application/CreateApplicationQuestions";
import {
  createApplication,
  updateApplication,
} from "../../api/clubAdmin/applications";
import { Button } from "../../components/atoms/Button";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";

function mapStateToProps(state) {
  return {
    authUser: state.userReducer.authUser,
    user: state.userReducer.user,
  };
}

const ZERO_STATE = {
  applicationTitle: "",
  applicationDescription: "",
  applicationQuestions: [],
  loading: false,
  applicationToEdit: null,
};

const CreateApplicationModal = ({
  isOpen,
  closeModal,
  clubId,
  authUser,
  applicationQuestionsToEdit,
  applicationToEdit,
  updateApplicationToList,
  addApplicationToList,
}) => {
  const [state, setState] = useState(ZERO_STATE);

  useEffect(() => {
    if (applicationToEdit) {
      setState((prevState) => ({
        ...prevState,
        applicationTitle: applicationToEdit.title,
        applicationDescription: applicationToEdit.description,
        applicationQuestions: applicationQuestionsToEdit || [],
        applicationToEdit: applicationToEdit,
      }));
    }
  }, [applicationToEdit, applicationQuestionsToEdit]);

  const submit = async () => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const questions = state.applicationQuestions.filter(
      (question) => question !== ""
    );
    const params = {
      application_id: applicationToEdit ? applicationToEdit.id : null,
      club_id: clubId,
      applicationTitle: state.applicationTitle,
      applicationDescription: state.applicationDescription,
      application_questions: questions,
    };
    const { application } = state.applicationToEdit
      ? await updateApplication(authUser, params)
      : await createApplication(authUser, params);

    addApplicationToList(application);

    closeModal();
    
    setState({ ...ZERO_STATE });
  };

  const handleInputChange = (e, inputName) => {
    setState((prevState) => ({
      ...prevState,
      [inputName]: e.target.value,
    }));
  };

  const updateApplicationQuestions = (text, i) => {
    const applicationQuestions = [...state.applicationQuestions];
    if (i >= state.applicationQuestions.length) {
      applicationQuestions.push({
        ...applicationQuestions[i],
        questionText: text,
      });
    } else {
      applicationQuestions[i] = {
        ...applicationQuestions[i],
        questionText: text,
      };
    }
    setState((prevState) => ({
      ...prevState,
      applicationQuestions,
    }));
  };

  const removeApplicationQuestion = (i) => {
    const applicationQuestions = [...state.applicationQuestions];
    applicationQuestions.splice(i, 1);
    setState((prevState) => ({
      ...prevState,
      applicationQuestions,
    }));
  };

  const close = () => {
    setState(ZERO_STATE);
    closeModal();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      backgroundColor={"white"}
      header={
        state.applicationToEdit
          ? "Update Application"
          : "Create A New Application"
      }
      headerToggle
      size={"lg"}
      toggle={close}
      FooterComponent={
        <div className="flex flex-row items-center gap-3">
          <Button
            variant={"secondary"}
            onClick={close}
            disabled={state.loading}
          >
            Cancel
          </Button>
          <Button
            onClick={submit}
            disabled={
              state.loading ||
              !state.applicationTitle ||
              !state.applicationDescription
            }
          >
            {state.applicationToEdit ? "Update" : "Submit"}
          </Button>
        </div>
      }
      footerAlignment={"right"}
    >
      <div id={"add-applicationlisting-modal"}>
        <p className="font-bold">Application Details</p>
        <div className="my-4">
          <WrappedTextInput
            type="text"
            outerLabel={"Title"}
            placeholder={"ie. General Club Application"}
            className="input-light px-3 w-full"
            value={state.applicationTitle}
            onChange={(e) => handleInputChange(e, "applicationTitle")}
          />
        </div>
        <div className="my-4">
          <WrappedTextInput
            type="text"
            outerLabel={"Description"}
            placeholder={
              "ie. This is our application for prospective members, please fill out all questions to the best of your abilities. We look forward to reviewing your submission!"
            }
            textarea
            className="input-light px-3 w-full"
            value={state.applicationDescription}
            onChange={(e) => handleInputChange(e, "applicationDescription")}
          />
        </div>
        <p className="font-bold">Questions</p>
        <div className="my-4">
          <CreateApplicationQuestions
            hideTutorial={true}
            placeholder={"ie. Why are you interested in joining our club?"}
            applicationQuestions={state.applicationQuestions}
            updateApplicationQuestions={updateApplicationQuestions}
            removeApplicationQuestion={removeApplicationQuestion}
          />
        </div>
      </div>
    </ModalComponent>
  );
};

export default connect(mapStateToProps)(CreateApplicationModal);
