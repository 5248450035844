import React, {PureComponent} from "react";
import SvgSearch from "../icons/SvgSearch";
import {connect} from "react-redux";
import JobBoardLoading from "../loading/JobBoardLoading";
import ClubSearchListItem from "../clubsearch/ClubSearchListItem";
import {searchClubs} from "../../utils/typesense";
import {NUM_CLUBS_SHOWN} from "../../utils/constants";
import SearchHeaderWrapper from "../../components-recruiter/atoms/SearchHeaderWrapper";
import {Button} from "../atoms/Button";
import { pluralizeString } from "../../utils/strings";
import SelectClubListModal from "../../components-recruiter/modals/SelectClubListModal";
import { isFeatureEnabled } from "../../config/featureFlags";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
    };
}

const initialFilters = {
    search: "",
    college: "",
    verifiedOnly: false,
    sortByMembers: true,
}

class RecruiterClubSearch extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            clubPreview: {},
            clubs: [],
            originalClubs: [],
            loading: false,
            moreClubsLoading: false,
            clubPreviewOpen: false,
            page: 1,
            hideMoreClubs: false,
            selectedClubs: {},
            isSelectListModalOpen: false,
            ...initialFilters
        };
    }

    previewClub = (club) => {
        this.setState({clubPreview: club, clubPreviewOpen: true})
    }

    closeClubPreview = () => {
        this.setState({clubPreviewOpen: false})
    }

    onSelectClubChange = (club) => {
        if (this.state.selectedClubs[club.id]) {
          const { [club.id]: _, ...selectedClubs } = this.state.selectedClubs;
          this.setState({selectedClubs})
        } else {
          this.setState({selectedClubs: {...this.state.selectedClubs, [club.id]: club}})
        }
      }

    isClubSelected = (clubId) => {
        return !!this.state.selectedClubs[clubId]
    }

    componentDidMount = async () => {
        const results = await this.search();
        this.setState({originalClubs: results});
    }

    getNextClubInfo = async () => {
        if (!this.props.showPaywall) {
            this.setState({moreClubsLoading: true});
            const newClubs = await searchClubs(this.state.search, this.state.page + 1, NUM_CLUBS_SHOWN, this.state.college, !this.state.college ? "num_members:desc" : null);
            this.setState({
                clubs: [...this.state.clubs, ...newClubs],
                moreClubsLoading: false,
                page: this.state.page + 1,
                hideMoreClubs: newClubs.length < NUM_CLUBS_SHOWN,
            })
        }
    }

    handleSearchChange = (event) => {
        if (!this.props.showPaywall) {
            const text = event.target.value;
            this.setState({search: text});
            this.search({ text });
        }
    }

    setSchool = (result) => {
        if (!result) {
            this.setState({page: 1})
        }
        this.setState({
            college: result
        })
        this.search({ college: result });
    }

    search = async ({ text = this.state.search, sortByMembers = this.state.sortByMembers, college = this.state.college, verifiedOnly = this.state.verifiedOnly } = {}) => {
        const results = await searchClubs(text, 1, NUM_CLUBS_SHOWN, college, !this.state.college ? "num_members:desc" : null, verifiedOnly);
        this.setState({
            clubs: results,
            loading: false,
            hideMoreClubs: results.length < NUM_CLUBS_SHOWN,
            page: 1
        });
        return results
    }
    
    toggleMemberSort = () => {
        const newSortByMembers = !this.state.sortByMembers;
        this.setState({ sortByMembers: newSortByMembers });
        this.search({ sortByMembers: newSortByMembers });
    }

    toggleVerifiedOnly = () => {
        this.setState({ verifiedOnly: !this.state.verifiedOnly }, () => {
            this.search({ verifiedOnly: this.state.verifiedOnly });
        });
    }

    clearFilters = () => {
        this.setState({...initialFilters});
        this.setState({clubs: this.state.originalClubs});
    }

    toggleSelectAll = async () => {
        // Deselect all case
        if (this.state.selectAllChecked) {
            this.setState({
                selectAllChecked: false,
                selectedClubs: {}
            });
            return;
        }

        // First, immediately select all currently visible students
        const initialSelectedClubs = {};
        this.state.clubs?.forEach(club => {
            initialSelectedClubs[club.id] = club;
        });

        // Update state immediately with visible students
        this.setState(() => ({
            selectAllChecked: true,
            selectedClubs: initialSelectedClubs
        }));

        try {
            // Create array of promises for pages 1-10
            const promises = Array.from({length: 10}, (_, i) =>
                searchClubs(this.state.search, i+1, NUM_CLUBS_SHOWN, this.state.college)
            );

            // Execute all promises in parallel
            const results = await Promise.all(promises);

            // Build complete selectedStudents object
            const allSelectedClubs = {...initialSelectedClubs};
            results.forEach(searchResult => {
                // Assuming each searchResult contains an array of clubs
                searchResult.forEach(club => {
                    allSelectedClubs[club.id] = club;
                });
            });

            // Update state with all selected students
            this.setState(() => ({
                selectedClubs: allSelectedClubs
            }));
        } catch (error) {
            console.error('Error fetching additional clubs:', error);
        }
    }

    render() {
        const clearFiltersVisible =
            this.state.search ||
            this.state.college ||
            this.state.verifiedOnly;
            // this.state.sortByMembers

        const verifiedCheckbox = isFeatureEnabled('VERIFIED_CLUBS') ? {
            label: 'Verified only',
            active: this.state.verifiedOnly,
            onClick: this.toggleVerifiedOnly
        } : null;

        return (
            <div className='flex flex-col overflow-hidden flex-1 px-[12px]'>
                <SearchHeaderWrapper
                    search={{
                        placeholder: 'Search clubs...',
                        value: this.state.search,
                        onChange: this.handleSearchChange,
                    }}
                    school={{
                        setSchool: this.setSchool,
                    }}
                    checkboxes={[
                        {
                            label: 'Select all',
                            active: this.state.selectAllChecked,
                            onClick: this.toggleSelectAll
                        },
                        verifiedCheckbox
                        // {
                        //     label: 'Sort by number of members',
                        //     active: this.state.sortByMembers,
                        //     onClick: this.toggleMemberSort
                        // }
                    ].filter(Boolean)}
                    placeChildrenRight
                    clearFilters={clearFiltersVisible ? this.clearFilters : undefined}
                >
                    <div className='flex flex-row items-center justify-end gap-4'>
                        <p className='text-sm text-slate-500'>
                            {Object.values(this.state.selectedClubs).length} {pluralizeString('club', Object.values(this.state.selectedClubs).length)} selected
                        </p>
                        <Button
                            size="sm"
                            onClick={() => this.setState({isSelectListModalOpen: true})}
                            disabled={Object.values(this.state.selectedClubs).length === 0}
                        >
                            Add to list
                        </Button>
                    </div>
                </SearchHeaderWrapper>

                <div className={'flex flex-1 flex-col overflow-y-scroll'}>
                    <>
                        {this.state.loading ?
                            <JobBoardLoading/>
                            :
                            this.state.clubs.length === 0 ?
                                <div className='my-8 flex text-neutral-400 flex-col items-center justify-center flex-1'>
                                    <SvgSearch/>
                                    <p className='body2 mt-2 mb-6'>
                                        No clubs found...
                                    </p>
                                </div>
                                :
                                <>
                                    <div className='flex flex-col gap-3 mt-5'>
                                        {this.state.clubs.map((club, index) => {
                                            if (!club.description && !club.logo_url) {
                                                return
                                            }
                                            return (
                                                <ClubSearchListItem
                                                    item={club}
                                                    className={'border border-slate-200'}
                                                    isSelected={this.isClubSelected(club.id)}
                                                    onSelect={this.onSelectClubChange}
                                                />
                                            )
                                        }

                                        )}
                                    </div>
                                    <SelectClubListModal
                                        items={Object.values(this.state.selectedClubs)}
                                        type={"club"}
                                        isOpen={this.state.isSelectListModalOpen}
                                        authUser={this.props.authUser}
                                        closeModal={() => this.setState({isSelectListModalOpen: false})}
                                        onUpdate={() => this.setState({selectedClubs: {}})}
                                    />
                                </>
                        }
                        {(!this.state.hideMoreClubs && (!this.state.loading && this.state.clubs.length !== 0)) &&
                            <div className='flex flex-row items-center justify-center py-8 body2-bold'
                                 onClick={this.getNextClubInfo}>
                                <Button loading={this.state.moreClubsLoading} variant={'ghost'}>
                                    Load more clubs
                                </Button>
                            </div>
                        }
                    </>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(RecruiterClubSearch);
