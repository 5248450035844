import React, {Component} from "react";
import RecruiterCompanyEditAbout from "../modals/RecruiterCompanyEditAbout";
import SvgEyeOff from "../../components/icons/SvgEyeOff";
import SvgVisibility from "../../components/icons/SvgVisibility";
import SvgEditFeather from "../../components/icons/SvgEditFeather";
import SvgPlus from "../../components/icons/SvgPlus";
import RenderHtml from "../../components/atoms/RenderHtml";

class RecruiterCompanyPageAbout extends Component {
    state = {
        editAboutModalOpen: false
    }

    openEditAboutModal = () => {
        this.setState({editAboutModalOpen: true})
    }

    closeEditAboutModal = () => {
        this.setState({editAboutModalOpen: false})
    }

    render() {
        const company = this.props.company || {};
        const description = company.description || "";
        const hidden = this.props.hidden || [];
        return (
            <div
                className={`bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl ${hidden.includes("description") && 'opacity-50'}`}>
                <div className='flex flex-row items-center gap-2 mb-2'>
                    <p className='text-xl font-semibold text-slate-800'>
                        About
                    </p>
                    <button
                        onClick={this.openEditAboutModal}
                        className='text-slate-500 hover:text-primary cursor-pointer'>
                        {description.length === 0 ?
                            <SvgPlus className={'w-4 h-4'}/>
                            :
                            <SvgEditFeather className={'w-4 h-4'}/>
                        }
                    </button>
                    <button
                        onClick={() => this.props.hide("description")}
                        className='text-slate-500 hover:text-primary cursor-pointer'>
                        {hidden.includes("description") ?
                            <SvgVisibility className={'w-4 h-4'}/>
                            :
                            <SvgEyeOff className={'w-4 h-4'}/>
                        }
                    </button>
                </div>
                {description.length === 0 &&
                    <p className='text-slate-500'>
                        No description created yet
                    </p>
                }
                <div className='text-slate-500 text-md'>
                    <RenderHtml htmlString={description} />
                </div>
                <RecruiterCompanyEditAbout
                    isOpen={this.state.editAboutModalOpen}
                    company={company}
                    closeModal={this.closeEditAboutModal}
                />
            </div>
        )
    }
}

export default RecruiterCompanyPageAbout;
