import React, { useState, useEffect } from "react";
import { searchClubs, searchClubsById } from "../../utils/typesense";
import MultiSelectWithSearch from "../atoms/MultiSelectWithSearch";
import SvgPeople from "../icons/SvgPeopleFeather";

export default function MultiSearchClubs({ outerLabel, selectedClubs, setSelectedClubs, limit, error, placeholder, colleges=[], disabled=false, className="" }) {
    const [clubSearch, setClubSearch] = useState("");
    const [openCollapse, setOpenCollapse] = useState(false);
    const [clubSearchResults, setClubSearchResults] = useState([]);
    const [selectedClubsData, setSelectedClubsData] = useState([]);

    // Fetch club data for selected club IDs when component mounts or selectedClubs changes
    useEffect(() => {
        const fetchSelectedClubsData = async () => {
            if (selectedClubs && selectedClubs.length > 0) {
                const clubData = await searchClubsById(selectedClubs);
                setSelectedClubsData(clubData);
            } else {
                setSelectedClubsData([]);
            }
        };

        fetchSelectedClubsData();
    }, [selectedClubs]);

    const filteredClubResults = clubSearchResults.filter(
        (result) => !selectedClubs.includes(result.id)
    );

    const handleClubClick = (clubId) => {
        if (selectedClubs.includes(clubId)) {
            setSelectedClubs([
                ...new Set(
                    [...selectedClubs].filter((item) => item !== clubId)
                ),
            ]);
        } else {
            setSelectedClubs([...new Set([...selectedClubs, clubId])]);
            setClubSearch("");
            setClubSearchResults([]);
            setOpenCollapse(false);
        }
    };

    const handleSearchChange = (event) => {
        const text = event.target.value;
        setClubSearch(text);

        if (text === "") {
            setOpenCollapse(false);
            setClubSearchResults([]);
            return;
        }

        setOpenCollapse(true);
        clubSearchChanged(text);
    };

    const clubSearchChanged = async (text) => {
        const results = await searchClubs(text, 1, 5, colleges.length > 0 ? JSON.stringify(colleges) : null);
        setClubSearchResults(results);
    };

    return (
        <MultiSelectWithSearch
            className={className}
            outerLabel={outerLabel}
            searchValue={clubSearch}
            selected={selectedClubs}
            selectedItemsData={selectedClubsData}
            displayKey="name"
            error={error}
            placeholder={placeholder}
            results={filteredClubResults}
            openCollapse={openCollapse}
            onSearchChange={handleSearchChange}
            onResultClick={handleClubClick}
            onClearAll={() => setSelectedClubs([])}
            resultMapper={(result) => ({
                id: result.id,
                name: result.name,
                imageUrl: result.logo_url,
                value: result.id
            })}
            leftIcon={<SvgPeople className="h-5 w-5 text-slate-500" />}
            maxToShow={limit}
            disabled={disabled}
        />
    );
}
