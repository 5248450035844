import React, {Component} from "react";
import RenderHtml from "../../components/atoms/RenderHtml";

class UpgradedCompanyPageAbout extends Component {
    render() {
        const company = this.props.company || {};
        const description = company.description || "";
        return (
            <div
                className='bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'>
                <div className='flex flex-row items-center gap-2 mb-2'>
                    <p className='text-xl font-semibold text-slate-800'>
                        About
                    </p>
                </div>
                {description.length === 0 &&
                    <p className='text-slate-500'>
                        No description created yet
                    </p>
                }
                <p className='text-slate-500 text-md'>
                    <RenderHtml htmlString={description} />
                </p>
            </div>
        )
    }
}

export default UpgradedCompanyPageAbout;
