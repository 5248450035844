import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill="#5f6368"
      {...props}
    >
      <path fill="currentColor" d="M792-443L176-183q-20 8-38-3.5T120-220v-520q0-22 18-33.5t38-3.5l616 260q25 11 25 37t-25 37zM200-280l474-200-474-200v140l240 60-240 60v140zm0 0v-400 400z" />
    </svg>
  )
}

export default SvgComponent
