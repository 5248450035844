import ModalComponent from "./ModalComponent";
import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {validateEmail} from "../../../utils/strings";
import {createNewClubMember} from "../../../api/student/users";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

const ZERO_STATE = {
    firstName: "",
    lastName: "",
    email: "",
    error: false,
    errorMessage: true,
}

class AddClubMemberModal extends PureComponent {
    state = ZERO_STATE
    firstNameChanged = e => this.setState({firstName: e.target.value});
    lastNameChanged = e => this.setState({lastName: e.target.value});
    emailChanged = e => {
        const email = e.target.value.replace(/\s/g, '').toLowerCase();
        this.setState({ email });
    };

    createMember = async () => {
        await createNewClubMember(this.props.authUser, this.props.club.id, this.state.email, this.state.firstName, this.state.lastName);
        this.setState({email: "", firstName: "", lastName: ""});
        this.props.getClubInfo();

    }

    closeModal = () => {
        this.props.closeModal()
        this.setState(ZERO_STATE);
    }

    render() {
        const saveEnabled = !!this.state.firstName && !!this.state.lastName && validateEmail(this.state.email);
        return (
            <ModalComponent isOpen={this.props.isOpen}
                            header={"Add Club Member"}
                            backgroundColor={'white'}
                            headerToggle
                            size={'lg'}
                            toggle={this.closeModal}
                            FooterComponent={
                                <div className='flex flex-row items-center justify-end gap-3'>
                                    <button onClick={this.closeModal} className='secondary-button body1-bold'>
                                        Close
                                    </button>
                                    <button onClick={this.createMember} disabled={!saveEnabled}
                                            className={`primary-button body1-bold ${!saveEnabled && 'opacity-50 cursor-not-allowed'}`}>
                                        Submit
                                    </button>
                                </div>
                            }
                            footerAlignment={'right'}
                            id={'add-job-modal'}
            >
                        <div className='flex flex-col w-full gap-5 mt-5'>
                            <div className='flex flex-row justify-between items-center'>
                                <div className='flex flex-col gap-5 flex-1'>
                                    <div className="col-plain w-full">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            First Name
                                        </p>
                                        <input
                                            value={this.state.firstName}
                                            onChange={this.firstNameChanged}
                                            placeholder={'John'}
                                            className='input-light px-3 body2 base-black-50 w-full'
                                        />
                                    </div>
                                    <div className="col-plain w-full">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            Last Name
                                        </p>
                                        <input
                                            value={this.state.lastName}
                                            onChange={this.lastNameChanged}
                                            placeholder={'Doe'}
                                            className='input-light px-3 body2 base-black-50 w-full'
                                        />
                                    </div>
                                    <div className="col-plain w-full">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            Email
                                        </p>
                                        <input
                                            value={this.state.email}
                                            onChange={this.emailChanged}
                                            placeholder={'ie. john.doe@doe.com'}
                                            className='input-light px-3 body2 base-black-50 w-full'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(AddClubMemberModal)
