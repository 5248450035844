import React, {useState, useEffect, useCallback} from "react";
import SvgSearch from "../icons/SvgSearch";
import {connect} from "react-redux";
import {SEARCH_STUDENT_TABLE_HEADERS} from "../../utils/dummy";
import {NUM_STUDENTS_SHOWN} from "../../utils/constants";
import StudentSearchListItem from "../StudentSearchListItem";
import RecruiterDashboardTable from "../../components-recruiter/dashboard/RecruiterDashboardTable";
import {Button} from "../atoms/Button";
import SvgChevronDown from "../icons/ChevronDown";
import SvgTrash from "../icons/SvgTrash";
import {createSavedSearch, getSavedSearches} from "../../api/recruiter/saved_searches";
import SelectClubListModal from "../../components-recruiter/modals/SelectClubListModal";
import {pluralizeString} from "../../utils/strings";
import {searchUsers} from "../../api/recruiter/users";
import {BsSortAlphaDown, BsSortAlphaDownAlt} from "react-icons/bs";
import RecruiterSearchFilters from "../../components-recruiter/atoms/RecruiterSearchFilters";
import DashboardLoading from "../../components-recruiter/atoms/DashboardLoading";
import Spinner from "../Spinner";
import useFiltersState from "../../hooks/useFiltersState";
import { USER_SELECT_PAGE_LIMIT } from "../../utils/constants";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        constants: state.userReducer.constants,
        tier: state.userReducer.tier,
    };
}

function RecruiterStudentSearch(props) {
    const [students, setStudents] = useState([]);
    const [originalStudents, setOriginalStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [moreStudentsLoading, setMoreStudentsLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [hideMoreStudents, setHideMoreStudents] = useState(false);
    const [isViewingSavedSearches, setIsViewingSavedSearches] = useState(false);
    const [isSavingSearch, setIsSavingSearch] = useState(false);
    const [savedSearches, setSavedSearches] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState({});
    const [isSelectListModalOpen, setIsSelectListModalOpen] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [isSelectAllLoading, setIsSelectAllLoading] = useState(false);
    
    const {
        filters,
        setFilter,
        handleFilterClassYearsSelection,
        handleSearchChange,
        toggleSearchSort,
        toggleSchoolSort,
        updateDiversity,
        clearFilters,
        setSearchMode,
        canClearFilters
    } = useFiltersState();

    const onSelectStudentChange = (student) => {
        setSelectedStudents(prevSelectedStudents => {
            if (prevSelectedStudents[student.id]) {
                const {[student.id]: _, ...selectedStudents} = prevSelectedStudents;
                return selectedStudents;
            } else {
                return {...prevSelectedStudents, [student.id]: student};
            }
        });
    }

    const isStudentSelected = (studentId) => {
        return !!selectedStudents[studentId];
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            // Set search mode explicitly
            setSearchMode();

            const initialClubIds = props.tier === "blk" ? ["baeZVpmndkm8fCNGFIuZ"] : [];
            setFilter("clubs", initialClubIds);
            
            const results = await searchUsers({
                user: props.authUser,
                name: filters.search,
                page: 1,
                clubIds: initialClubIds
            });
            setLoading(false);
            setStudents(results.students);
            setOriginalStudents(results.students);

            const queryParameters = new URLSearchParams(window.location.search);
            const searchId = queryParameters.get('searchId', "");
            getSearches(searchId);
        };

        fetchData();
    }, []);

    const getSearches = async (searchId) => {
        const res = await getSavedSearches(props.authUser);
        setSavedSearches(res.searches);

        if (!!searchId) {
            const items = res.searches.filter(search => search.id === searchId);
            if (items.length) {
                applySearch(items[0]);
            }
        }
    }

    const updateMajor = (newMajors) => {
        setFilter("majors", newMajors);
    }

    const updateCollege = (newColleges) => {
        if (!props.showPaywall) {
            setFilter("schools", newColleges);
        }
    }

    const setClubs = (newClubs) => {
        setFilter("clubs", newClubs);
    }

    const setCurrentCompany = (newCompanies) => {
        setFilter("currentCompanies", newCompanies);
    }

    const setPreviousCompany = (newCompanies) => {
        setFilter("previousCompanies", newCompanies);
    }

    const updateRace = (newRace) => {
        setFilter("race", newRace);
    }

    const updateGraduationSeason = (newSeason) => {
        setFilter("graduationSeason", newSeason);
    }

    const searchStudents = useCallback(async () => {
        if (
            filters.search === "" &&
            !filters.classYears.length &&
            !filters.majors.length &&
            !filters.schools.length &&
            !filters.gpa &&
            !filters.gender &&
            !filters.race.length &&
            !filters.veteran &&
            !filters.lgbtq &&
            !filters.disabled &&
            !filters.authorized &&
            !filters.clubs.length &&
            !filters.sortBy &&
            !filters.currentCompanies.length &&
            !filters.previousCompanies.length &&
            !filters.isIb &&
            !filters.verticals.length &&
            filters.searchSortState === 0 &&
            filters.schoolSortState === 0 &&
            !filters.previousVerticals.length &&
            !filters.graduationSeason &&
            !filters.first_generation &&
            !filters.require_sponsorship
        ) {
            setStudents(originalStudents);
            setHideMoreStudents(false);
            setPage(1);
            return;
        }
        

        const results = await searchUsers({
            user: props.authUser,
            name: filters.search,
            page: 1,
            clubIds: filters.clubs,
            classYears: filters.classYears,
            majors: filters.majors,
            colleges: filters.schools,
            gpa: filters.gpa,
            gender: filters.gender,
            races: filters.race,
            veteran: filters.veteran,
            lgbtq: filters.lgbtq,
            disabled: filters.disabled,
            authorized: filters.authorized,
            industries: [],
            sortBy: filters.sortBy,
            currentCompanies: filters.currentCompanies,
            previousCompanies: filters.previousCompanies,
            isIb: filters.isIb,
            searchSortState: filters.searchSortState,
            schoolSortState: filters.schoolSortState,
            verticals: filters.verticals,
            previousVerticals: filters.previousVerticals,
            graduationSeason: filters.graduationSeason,
            firstGeneration: filters.first_generation,
            requireSponsorship: filters.require_sponsorship
        });
        setStudents(results.students);
        setLoading(false);
        setHideMoreStudents(results.students.length < NUM_STUDENTS_SHOWN);
        setPage(1);
    }, [props.authUser, originalStudents, filters]);

    useEffect(() => {
        if (!loading) {
            searchStudents();
        }
    }, [
        filters, 
        loading, 
        searchStudents, 
        props.authUser
    ]);

    const getNextStudentInfo = async () => {
        if (!props.showPaywall) {
            setMoreStudentsLoading(true);
            const newStudents = await searchUsers({
                user: props.authUser,
                name: filters.search,
                page: page + 1,
                clubIds: filters.clubs,
                classYears: filters.classYears,
                majors: filters.majors,
                colleges: filters.schools,
                gpa: filters.gpa,
                gender: filters.gender,
                races: filters.race,
                veteran: filters.veteran,
                lgbtq: filters.lgbtq,
                disabled: filters.disabled,
                authorized: filters.authorized,
                industries: [],
                sortBy: filters.sortBy,
                currentCompanies: filters.currentCompanies,
                previousCompanies: filters.previousCompanies,
                isIb: filters.isIb,
                searchSortState: filters.searchSortState,
                schoolSortState: filters.schoolSortState,
                verticals: filters.verticals,
                previousVerticals: filters.previousVerticals,
                graduationSeason: filters.graduationSeason,
                firstGeneration: filters.first_generation,
                requireSponsorship: filters.require_sponsorship
            });
            setStudents(prevStudents => [...prevStudents, ...newStudents.students]);
            setMoreStudentsLoading(false);
            setPage(page + 1);
            setHideMoreStudents(newStudents.students.length < NUM_STUDENTS_SHOWN);
        }
    }

    const saveSearch = async () => {
        setIsSavingSearch(true);
        const savedSearch = {
            grades: filters.classYears,
            majors: filters.majors,
            gpa: filters.gpa,
            college: filters.schools.length > 0 ? filters.schools[0] : null,
        };
        await createSavedSearch(props.authUser, savedSearch);
        setIsSavingSearch(false);
    };

    const toggleIsViewingSavedSearches = () => {
        setIsViewingSavedSearches(!isViewingSavedSearches);
    }

    const deleteSearch = async (searchId) => {
        await deleteSearch(props.authUser, searchId);
        toggleIsViewingSavedSearches();
        window.location.reload();
    }

    const applySearch = (params) => {
        setIsViewingSavedSearches(false);
        setFilter("schools", params.college ? [params.college] : []);
        setFilter("classYears", params.grades || []);
        setFilter("majors", params.majors || []);
        setFilter("gpa", params.gpa || null);
        setFilter("search", "");
    }

    const toggleSelectAll = async () => {
        if (selectAllChecked) {
            setSelectAllChecked(false);
            setSelectedStudents({});
            return;
        }

        const initialSelectedStudents = {};
        students?.forEach(student => {
            initialSelectedStudents[student.id] = student;
        });

        setSelectAllChecked(true);
        setSelectedStudents(initialSelectedStudents);
        setIsSelectAllLoading(true);

        try {
            
            const results = await searchUsers({
                user: props.authUser,
                name: filters.search,
                page: 1,
                clubIds: filters.clubs,
                classYears: filters.classYears,
                majors: filters.majors,
                colleges: filters.schools,
                gpa: filters.gpa,
                gender: filters.gender,
                races: filters.race,
                veteran: filters.veteran,
                lgbtq: filters.lgbtq,
                disabled: filters.disabled,
                authorized: filters.authorized,
                industries: [],
                sortBy: filters.sortBy,
                currentCompanies: filters.currentCompanies,
                previousCompanies: filters.previousCompanies,
                isIb: filters.isIb,
                searchSortState: filters.searchSortState,
                schoolSortState: filters.schoolSortState,
                verticals: filters.verticals,
                previousVerticals: filters.previousVerticals,
                graduationSeason: filters.graduationSeason,
                firstGeneration: filters.first_generation,
                requireSponsorship: filters.require_sponsorship,
                count: USER_SELECT_PAGE_LIMIT
            })
      

            const allSelectedStudents = results.students.reduce((acc, student) => {
                acc[student.id] = student;
                return acc;
            }, {});

            setSelectedStudents(allSelectedStudents);
            setIsSelectAllLoading(false);
        } catch (error) {
            console.error('Error fetching additional students:', error);
            setIsSelectAllLoading(false);
        }
    }

    return (
        <div className='flex flex-col flex-1 overflow-hidden'>
            <div className={'flex flex-row flex-1 overflow-hidden'}>
                <div className={'w-[250px] px-[12px] flex flex-col'}>
                    <RecruiterSearchFilters
                        search={{
                            placeholder: 'Search by name...',
                            value: filters.search,
                            onChange: (e) => handleSearchChange(e.target.value),
                            toggleSearchSort: toggleSearchSort
                        }}
                        schools={filters.schools}
                        updateCollege={updateCollege}
                        toggleSchoolSort={toggleSchoolSort}
                        searchSortState={filters.searchSortState}
                        schoolSortState={filters.schoolSortState}
                        clubs={filters.clubs}
                        setClubs={setClubs}
                        majors={filters.majors}
                        updateMajor={updateMajor}
                        classYears={filters.classYears}
                        handleFilterClassYearsSelection={handleFilterClassYearsSelection}
                        graduationSeason={filters.graduationSeason}
                        updateGraduationSeason={updateGraduationSeason}
                        gpa={filters.gpa}
                        setGpaFilter={(value) => setFilter("gpa", value)}
                        currentCompanies={filters.currentCompanies}
                        setCurrentCompany={setCurrentCompany}
                        previousCompanies={filters.previousCompanies}
                        setPreviousCompany={setPreviousCompany}
                        verticals={filters.verticals}
                        setVerticals={(verticals) => setFilter('verticals', verticals)}
                        previousVerticals={filters.previousVerticals}
                        setPreviousVerticals={(verticals) => setFilter('previousVerticals', verticals)}
                        gender={filters.gender}
                        updateDiversity={updateDiversity}
                        race={filters.race}
                        updateRace={updateRace}
                        veteran={filters.veteran}
                        lgbtq={filters.lgbtq}
                        disabled={filters.disabled}
                        authorized={filters.authorized}
                        first_generation={filters.first_generation}
                        require_sponsorship={filters.require_sponsorship}
                        clearFilters={canClearFilters ? clearFilters : undefined}
                    />
                </div>
                <div className={'flex flex-col flex-1 overflow-hidden'}>
                    <div className='flex mb-1 flex-row items-center gap-4  pr-[12px]'>
                        <p className={'text-lg font-semibold mr-auto'}>
                            Results
                        </p>
                        <p className='text-sm text-slate-500 flex items-center gap-2'>
                            {isSelectAllLoading ? (
                                <>
                                    <Spinner size={12}/>
                                    <span>Loading selected students...</span>
                                </>
                            ) : (
                                `${Object.keys(selectedStudents).length} ${pluralizeString('student', Object.keys(selectedStudents).length)} selected`
                            )}
                        </p>
                        <Button
                            size="sm"
                            onClick={() => setIsSelectListModalOpen(true)}
                            disabled={Object.values(selectedStudents).length === 0}
                        >
                            Add to list
                        </Button>
                    </div>
                    <RecruiterDashboardTable
                        columns={SEARCH_STUDENT_TABLE_HEADERS}
                        hasCheckbox={true}
                        checked={selectAllChecked}
                        toggleCheckbox={toggleSelectAll}
                        containerStyle={'rounded-none rounded-tl-xl border-r-0 border-b-0'}
                        TableBody={
                            <div className='flex-1 flex flex-col mt-0 pt-2 gap-1 p-5 overflow-y-scroll'>
                                {loading ?
                                    <DashboardLoading copy={"search results"}/>
                                    :
                                    students.length === 0 ?
                                        <div
                                            className='my-8 flex text-neutral-400 flex-col items-center justify-center flex-1'>
                                            <SvgSearch/>
                                            <p className='body2 mt-2 mb-6'>
                                                No students found...
                                            </p>
                                        </div>
                                        :
                                        <>
                                            {students.map((item, index) =>
                                                <StudentSearchListItem
                                                    key={item.id}
                                                    item={item}
                                                    columns={SEARCH_STUDENT_TABLE_HEADERS}
                                                    onSelectStudentChange={onSelectStudentChange}
                                                    isSelected={isStudentSelected(item.id)}
                                                />
                                            )}
                                        </>
                                }
                                {(!hideMoreStudents && (!loading && students.length !== 0)) &&
                                    <div
                                        className='flex flex-row items-center justify-center mr-3 py-8 ml-2 mb-40 body2-bold'
                                        onClick={getNextStudentInfo}>
                                        <Button loading={moreStudentsLoading} variant={'ghost'}>
                                            Load more students
                                        </Button>
                                    </div>
                                }
                            </div>
                        }
                    />
                </div>
            </div>
            <SelectClubListModal
                items={Object.values(selectedStudents)}
                type={"user"}
                isOpen={isSelectListModalOpen}
                authUser={props.authUser}
                closeModal={() => setIsSelectListModalOpen(false)}
                onUpdate={() => setSelectedStudents({})}
            />

            <div
                className={`fixed bottom-12 left-1/2 ${isViewingSavedSearches ? 'h-auto' : 'h-0 overflow-hidden'}`}>
                <div
                    className='-translate-x-1/4 overflow-hidden bg-white min-w-[30vw] flex flex-col border w-full border-slate-200 shadow-xl absolute bottom-10 rounded-xl'>
                    <div className={'p-[12px] py-2 bg-slate-100'}>
                        <p className='text-base font-semibold '>
                            Saved Searches
                        </p>
                    </div>
                    <div className='flex flex-col  max-h-[30vh] overflow-y-scroll'>
                        {savedSearches.length === 0 ?
                            <div className='flex flex-col items-center justify-center p-5'>
                                <p className='text-slate-500'>
                                    No saved searches yet
                                </p>
                            </div>
                            : savedSearches.map((item, index) =>
                                <div key={index}
                                     onClick={() => applySearch(item)}
                                     className='border-b last:border-none flex flex-row items-center justify-between border-slate-200 hover:border-slate-300 hover:bg-slate-50 cursor-pointer p-3 text-sm '>
                                    <div>
                                        {!!item.grades.length &&
                                            <p><strong>Class Years:</strong> {item.grades.join(", ")}</p>}
                                        {!!item.majors.length &&
                                            <p><strong>Majors:</strong> {item.majors.join(", ")}</p>}
                                        {!!item.gpa &&
                                            <p><strong>GPA:</strong> {item.gpa !== null ? item.gpa : "N/A"}</p>}
                                        {!!item.college && <p><strong>College:</strong> {item.college}</p>}
                                    </div>
                                    <div className='flex flex-row gap-2 ml-auto'>
                                        <Button variant='ghost' onClick={() => deleteSearch(item.id)}
                                                icon={SvgTrash}/>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
            <div className='fixed bottom-8 left-1/2 translate-x-1/4'>
                <div className='flex flex-row gap-2 items-center relative'>
                    <Button onClick={toggleIsViewingSavedSearches} variant='secondary' className='mr-3'>
                        Saved searches <SvgChevronDown
                        className={`${isViewingSavedSearches ? 'rotate-[180deg]' : 'rotate-0'} duration-150`}/>
                    </Button>
                    {(filters.classYears?.length > 0 || filters.gpa !== null || filters.majors?.length > 0 || filters.schools?.length > 0) &&
                        <Button onClick={saveSearch} loading={isSavingSearch} className='mr-3'>
                            Save this search
                        </Button>
                    }
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(RecruiterStudentSearch);
