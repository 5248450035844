import React, { useState, useEffect, createContext } from "react";
import { firebaseAuth } from "../api/firebase";
import useVerificationCallback from "../hooks/useVerificationCallback";

export const UserContext = createContext({user: null});

const UserProvider = ({ children, userData }) => {
    const [user, setUser] = useState(0);
    useVerificationCallback();

    useEffect(() => {
        firebaseAuth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                userAuth.getIdTokenResult().then(async idTokenResult => {
 
                    const claims = idTokenResult.claims;
                    const userType = !!claims.recruiter ? "recruiter" : "student";
                    userAuth.userType = userType;
                    setUser(userAuth);
                })
            } else {
                setUser(null);
            }
        })
    }, [userData]);

    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    );
}

export default UserProvider;
