export const downloadCSV = (data, filename) => {
    // Create a Blob with UTF-8 BOM to ensure Excel recognizes the encoding
    const BOM = '\uFEFF';
    const blob = new Blob([BOM + data], { type: 'text/csv;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    
    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};

export const formatStudentForCSV = (student, type = 'full') => {
    if (type === 'email') {
        return {
            Email: student.email
        };
    }

    return {
        first_name: student.first_name,
        last_name: student.last_name,
        email: student.email,
        secondary_email: student.secondary_email,
        username: student.username,
        college: student.college,
        degree_name: student.education?.[0]?.degree_name,
        field_of_study: student.education?.[0]?.field_of_study,
        grade: student.grade,
        gpa: student.gpa,
        sports: student.sports?.join(':'),
        linkedin: student.linkedin,
        resume: student.resume,
        clubs: (student.clubs || []).join(':'),
        bio: student.bio,
        city: student.city,
        state: student.state,
        have_job: student.have_job ? 'Yes' : 'No',
        job_space: student.job_space,
        job_type: student.job_type,
        seeking_role: student.seeking_role ? 'Yes' : 'No',
        viewed_suggested_jobs: student.viewed_suggested_jobs ? 'Yes' : 'No',
        profile_status: student.onboarded 
            ? (student.completed_personalization ? "Profile Complete" : "Onboarded")
            : "Account Created",
        created_at: new Date(student.created_at).toLocaleDateString(),
        profile_updated_at: student.profile_updated_at 
            ? new Date(student.profile_updated_at).toLocaleDateString()
            : '',
        onboarding_source: student.onboarding_source,
        authorized: student.diversity?.authorized,
        disabled: student.diversity?.disabled,
        first_generation: student.diversity?.first_generation,
        gender: student.diversity?.gender,
        lgbtq: student.diversity?.lgbtq,
        veteran: student.diversity?.veteran,
        race: student.diversity?.race?.join(':'),
    };
};

/**
 * Downloads a CSV file containing student data
 * @param {Array} students - Array of student objects to include in the CSV
 * @param {string} filename - Name of the CSV file to download (without extension)
 * @param {Object} options - Additional options
 * @param {string} options.companyId - ID of the recruiter company
 * @param {string} options.delimiter - Delimiter for arrays (default: "/")
 * @param {boolean} options.includeBOM - Whether to include BOM for Excel compatibility (default: true)
 */
export const downloadStudentsCSV = (students, filename, options = {}) => {
    const { 
        companyId, 
        delimiter = "/", 
        includeBOM = true 
    } = options;
    
    if (!students || !Array.isArray(students) || students.length === 0) {
        console.warn('No students data provided for CSV download');
        return;
    }
    
    // Create CSV header
    const header = `full name,first name,last name,email,class year,college,${companyId === "recruitu" ? 'user id,' : ''}gpa,majors,minors,authorized to work,disability,first generation,gender,race,requires sponsorship,veteran,resume url`;
    
    // Map students to CSV rows
    const rows = students.map(student => {
        if (!student) return '';
        
        // Handle potential undefined values and properly format arrays
        const fullName = `${student.first_name || ''} ${student.last_name || ''}`.trim();
        const email = student.email || '';
        const grade = student.grade || '';
        const college = student.college ? `"${student.college.replace(/"/g, '""')}"` : '""';
        const userId = companyId === "recruitu" ? `${student.id || ''},` : '';
        const gpa = student.gpa || '';
        const majors = Array.isArray(student.major) ? student.major.join(delimiter) : '';
        const minors = Array.isArray(student.minor) ? student.minor.join("|") : '';
        const authorized = student.diversity?.authorized || '';
        const disabled = student.diversity?.disabled || '';
        const firstGeneration = student.diversity?.first_generation || '';
        const gender = student.diversity?.gender || '';
        const race = Array.isArray(student.diversity?.race) ? student.diversity.race.join("|") : '';
        const requireSponsorship = student.diversity?.require_sponsorship || '';
        const veteran = student.diversity?.veteran || '';
        const resumeUrl = student.resume || '';
        
        return `${fullName},${student.first_name},${student.last_name},${email},${grade},${college},${userId}${gpa},${majors},${minors},${authorized},${disabled},${firstGeneration},${gender},${race},${requireSponsorship},${veteran},${resumeUrl}`;
    }).filter(Boolean).join('\n');
    
    // Combine header and rows
    const csvContent = `${header}\n${rows}`;
    
    // Use the existing downloadCSV function if includeBOM is true, otherwise create a custom download
    if (includeBOM) {
        downloadCSV(csvContent, `${filename}.csv`);
    } else {
        // Create a Blob without BOM
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `${filename}.csv`);
        document.body.appendChild(link);
        link.click();
        
        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }
}; 