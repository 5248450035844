import { useMemo, useState, useEffect } from "react";
import useFiltersState from "./useFiltersState";

export default function useQualifiedOnlyState() {
  const [selectionMode, setSelectionMode] = useState("lists");
  const [lists, setLists] = useState({
    smart: {
      items: [],
      ids: [],
    },
    club: {
      items: [],
      ids: [],
    },
    user: {
      items: [],
      ids: [],
    },
  });
  
  const {
    filters,
    setFilter,
    handleFilterClassYearsSelection,
    handleSearchChange,
    toggleSearchSort,
    toggleSchoolSort,
    updateDiversity,
    clearFilters,
    initFilters,
    selectedMajorCategories,
    setSelectedMajorCategories,
    setQualifiedOnlyMode
  } = useFiltersState();

  // Set qualified only mode on initialization
  useEffect(() => {
    setQualifiedOnlyMode();
  }, []);

  const listNames = useMemo(() => {
    const smartLists = lists.smart.items.map((item) => ({
      id: item.id,
      name: item.name,
      type: "smart",
    }));
    const clubLists = lists.club.items.map((item) => ({
      id: item.id,
      name: item.name,
      type: "club",
    }));
    const userLists = lists.user.items.map((item) => ({
      id: item.id,
      name: item.name,
      type: "user",
    }));

    return [...smartLists, ...clubLists, ...userLists];
  }, [lists]);

  function setSmartLists(lists) {
    setLists((prev) => ({
      ...prev,
      smart: {
        items: lists,
        ids: lists.map((list) => list.id),
      },
    }));
  }

  function setClubLists(lists) {
    setLists((prev) => ({
      ...prev,
      club: {
        items: lists,
        ids: lists.map((list) => list.id),
      },
    }));
  }

  function setUserLists(lists) {
    setLists((prev) => ({
      ...prev,
      user: {
        items: lists,
        ids: lists.map((list) => list.id),
      },
    }));
  }

  function handleSmartListSelection(list) {
    const isSelected = lists.smart.ids.includes(list.id);
    if (isSelected) {
      const filteredLists = lists.smart.items.filter(
        (item) => item.id !== list.id
      );
      setSmartLists(filteredLists);
    } else {
      const newLists = [...lists.smart.items, list];
      setSmartLists(newLists);
    }
  }

  function handleClubListSelection(list) {
    const isSelected = lists.club.ids.includes(list.id);
    if (isSelected) {
      const filteredLists = lists.club.items.filter(
        (item) => item.id !== list.id
      );
      setClubLists(filteredLists);
    } else {
      const newLists = [...lists.club.items, list];
      setClubLists(newLists);
    }
  }

  function handleUserListSelection(list) {
    const isSelected = lists.user.ids.includes(list.id);
    if (isSelected) {
      const filteredLists = lists.user.items.filter(
        (item) => item.id !== list.id
      );
      setUserLists(filteredLists);
    } else {
      const newLists = [...lists.user.items, list];
      setUserLists(newLists);
    }
  }

  function initQualifiedOnlyState(jobOrEvent) {
    if (jobOrEvent) {
      if (jobOrEvent.qualified_only_selection_mode) {
        setSelectionMode(jobOrEvent.qualified_only_selection_mode);
      }

      if (jobOrEvent.filters) {
        initFilters(jobOrEvent.filters);
      }

      setLists({
        smart: {
          items: jobOrEvent?.smart_lists?.map(({ list }) => list) ?? [],
          ids: jobOrEvent?.smart_list_ids ?? [],
        },
        club: {
          items: jobOrEvent?.club_lists?.map(({ list }) => list) ?? [],
          ids: jobOrEvent?.club_list_ids ?? [],
        },
        user: {
          items: jobOrEvent?.user_lists?.map(({ list }) => list) ?? [],
          ids: jobOrEvent?.user_list_ids ?? [],
        },
      });
    }
  }

  return {
    selectionMode,
    setSelectionMode,
    listNames,
    setSmartLists,
    setClubLists,
    setUserLists,
    handleSmartListSelection,
    handleClubListSelection,
    handleUserListSelection,
    handleFilterClassYearsSelection,
    handleSearchChange,
    toggleSearchSort,
    toggleSchoolSort,
    updateDiversity,
    filters,
    lists,
    setFilter,
    clearFilters,
    initQualifiedOnlyState,
    selectedMajorCategories,
    setSelectedMajorCategories,
  };
}
