import React, {Component} from "react";
import {connect} from "react-redux";
import RecruiterClubSearch from "../../components/pages/RecruiterClubSearch";
import SectionTabs from "../../components/atoms/SectionTabs";
import RecruiterStudentSearch from "../../components/pages/RecruiterStudentSearch";
import RecruiterCollegeSearch from "../../components/pages/RecruiterCollegeSearch";
import RecruiterPaywall from "../atoms/RecruiterPaywall";
import {Button} from "../../components/atoms/Button";
import SvgPlus from "../../components/icons/SvgPlus";
import EditListModal from "../modals/EditListModal";
import RecruiterIndustryProfessionalsSearch from "../../components/pages/RecruiterIndustryProfessionalsSearch";

const INDUSTRY_PROFESSIONALS_TAB = 'Industry Professionals';
const ALL_TABS = ['Students', 'Clubs'];
const BLK_TABS = ['Students'];

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        tier: state.userReducer.tier,
        recruiterCompany: state.userReducer.recruiterCompany,
    };
}

class RecruiterSearchPage extends Component {
    state = {
        activeTab: 'Students',
        isEditClubListModalOpen: false
    }

    changeTab = (tab) => {
        this.setState({activeTab: tab});

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("active_tab", tab);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        this.props.history.replace(newUrl);
    };

    componentDidMount = () => {
        

        const queryParams = new URLSearchParams(window.location.search);
        const activeTab = queryParams.get("active_tab");
        if (!!activeTab && activeTab !== "Students") this.changeTab(activeTab);
    }

    render() {
        
        const initialTabs = this.props.tier === 'blk' ? BLK_TABS : ALL_TABS;
        const canShowIndustryProfessionalsTab = this.props.recruiterCompany && this.props.recruiterCompany.access_to_lateral_recruiting === true && process.env.REACT_APP_VERCEL_ENV !== 'production';
        const TABS = canShowIndustryProfessionalsTab ? [...initialTabs, INDUSTRY_PROFESSIONALS_TAB] : initialTabs;
        const showPaywall = this.props.tiers === 'basic';
        const activeTab = this.state.activeTab;

        return (
            <div className={`flex flex-col flex-1 gap-1 relative overflow-hidden`}>
                <div className='flex flex-row p-[12px] pb-0  items-center justify-between'>
                    <div className={'flex-1 mr-3'}>
                        <SectionTabs
                            activeTab={this.state.activeTab}
                            setActiveTab={this.changeTab}
                            tabs={TABS}
                            lg
                        />
                    </div>
                    {["Students", "Clubs"].includes(this.state.activeTab) ? 
                        <div className="flex flex-row gap-3">
                            <Button
                                variant={'secondary'}
                                icon={SvgPlus}
                                onClick={() => {
                                    this.setState({isEditClubListModalOpen: true})
                                }}
                            >
                                New {activeTab} List
                            </Button>
                        </div>
                        : null
                    }
                    
                </div>
                <div className='flex flex-col gap-3 flex-1 overflow-hidden'>
                    {this.state.activeTab === 'Students' &&
                        <RecruiterStudentSearch showPaywall={showPaywall}/>
                    }
                    {this.state.activeTab === 'Colleges' &&
                        <RecruiterCollegeSearch showPaywall={showPaywall}/>
                    }
                    {this.state.activeTab === 'Clubs' &&
                        <RecruiterClubSearch showPaywall={showPaywall}/>
                    }
                    {this.state.activeTab === 'Industry Professionals' && canShowIndustryProfessionalsTab &&
                        <RecruiterIndustryProfessionalsSearch showPaywall={showPaywall}/>
                    }
                </div>
                {showPaywall &&
                    <RecruiterPaywall/>
                }
                <EditListModal
                    isOpen={this.state.isEditClubListModalOpen}
                    isNewList
                    type={this.state.activeTab === "Students" ? "user": "club"}
                    getLists={() => {}}
                    closeModal={() => this.setState({isEditClubListModalOpen: false})}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps)(RecruiterSearchPage);
