import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AddPostText from "../../components/createpostflow/AddPostText";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import AddJobEvent from "../../components/createpostflow/AddJobEvent";
import CompanyLogo from "../../components/atoms/CompanyLogo";
import { createPost } from "../../api/student/posts";
import { createJob } from "../../api/student/jobs";
import { createStudentEvent } from "../../api/student/events";
import {
  searchCompanies,
} from "../../utils/typesense";
import SelectPostType from "../../components/createpostflow/SelectPostType";
import TagComponent from "../../components/atoms/TagComponent";
import AddJobTitleDescription from "../../components/addjobflow/AddJobTitleDescription";
import AddJobLocation from "../../components/addjobflow/AddJobLocation";
import SelectManualFlow from "../../components/createpostflow/SelectManualFlow";
import CreateEventDetailsStep from "../../components-recruiter/modals/create-event/CreateEventDetailsStep";
import CreateEventDateAndLocationStep from "../../components-recruiter/modals/create-event/CreateEventDateAndLocationStep";
import { notifyClubPostEmail } from "../../api/student/clubs";
import { uploadDoc } from "../../api/firebase";
import SvgNote from "../../components/icons/SvgNote";
import { discoverJobs } from "../../api/student/jobs";
import {discoverEvents} from "../../api/student/events";
import { stateToHTML } from 'draft-js-export-html';
import {DefaultEditorState} from "../../utils/richEditor";
import { sanitizeHtml } from "../../utils/strings";
function mapStateToProps(state) {
  return {
    authUser: state.userReducer.authUser,
    constants: state.userReducer.constants,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateApplication: (application) => {
      dispatch({
        type: "UPDATE_APPLICATION",
        application,
      });
    },
  };
}

const ZERO_STATE = {
  search: "",
  type: "",
  step: 2,
  eventId: null,
  jobId: null,
  searchResults: [],
  companyId: "",
  companyName: "",
  plaintext: "",
  job: {},
  event: {},
  jobTitle: "",
  jobDescription: "",
  eventTitle: "",
  eventDescription: DefaultEditorState,
  url: "",
  address: "",
  location: "",
  state: "",
  industry: "",
  role: "",
  deadline: null,
  date: null,
  startTime: "10:00",
  endTime: "11:00",
  virtual: false,
  meetingLink: "",
  custom: false,
  uploadedFile: null,
  postAsBot: false,
  classYears: [],
  registerOnRecruit: false,
};

class CreateJobOrEventModal extends PureComponent {
  state = ZERO_STATE;

  componentDidMount = () => {
    const selectedJob = this.props.selectedJob;
    if (!!this.props.selectedJob) {
      this.setState({
        job: selectedJob,
        jobId: selectedJob.id,
        step: 6,
        type: "job",
      });
    }
    const type = this.props.type === "job" ? "job" : "event"
    this.setState({
      type: type,
    });
    this.searchChanged("", this.props.type)
  };

  componentDidUpdate = (prevProps) => {
    const selectedJob = this.props.selectedJob;
    if (selectedJob !== prevProps.selectedJob) {
      this.setState({
        job: selectedJob,
        jobId: selectedJob.id,
        step: 6,
        type: "job",
      });
    }
    this.setState({
      type: this.props.type === "job" ? "job" : "event",
    });
    if (this.props.type !== prevProps.type) {
      this.searchChanged("", this.props.type)
    }
  };

  setEditorState = (editorState) => {
    this.setState({eventDescription: editorState});
}

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSelectChange = (inputName, value) => {
    this.setState({ [inputName]: value });
  };

  updateIndustry = (industry) => {
    this.setState({ industry });
  };

  handleDateChange = (e, stateKey) => {
    this.setState({ [stateKey]: e });
  };

  handleTimeChange = (e, stateKey) => {
    this.setState({ [stateKey]: e });
  };

  handleVirtualChange = (value) => {
    this.setState({ virtual: value });
  };

  onDrop = async (acceptedFiles) => {
    const randomUUID = Math.random().toString(36).substring(7);
    this.setState({ buttonMessage: "File uploaded!" });
    const url = await uploadDoc(
      "post_file",
      this.props.club.id + "-" + this.props.authUser.uid + "-" + randomUUID,
      acceptedFiles[0],
    );
    const name = acceptedFiles[0].name;
    const uploadedFile = { url: url, name: name };
    this.setState({ uploadedFile: uploadedFile });
  };

  removeUpload = () => {
    this.setState({ uploadedFile: null });
  };

  changeType = (type) => {
    if (type === "job") {
      const recommended = this.props.club.recommended || [];
      this.setState({
        searchResults: recommended.map((job) => job.job).slice(0, 4),
      });
    } else {
      this.searchChanged("", type);
    }
    this.setState({ type: type === "job" ? "job" : "event" });
  };

  updateClassYears = (year) => {
    if (year === null) {
      this.setState({ classYears: [] });
      return;
    }

    let newClassYears = [...this.state.classYears];
    if (this.state.classYears.includes(year)) {
      const index = this.state.classYears.indexOf(year);
      newClassYears.splice(index, 1);
    } else {
      newClassYears.push(year);
    }
    this.setState({ classYears: newClassYears });
  };

  updatePlaintext = (plaintext) => {
    this.setState({ plaintext });
  };

  switchTitle = () => {
    switch (this.state.step) {
      case 1:
        return "Share a job, event, or resource with your club!";
      case 2:
        return `Find a${this.state.type === "event" ? "n" : ""} ${this.state.type} on RecruitU or enter the details manually?`;
      case 3:
        return `Select a RecruitU ${this.state.type}`;
      default:
        return  this.state.type === 'job' ?  `Enter the details for the job` :`Enter the details for the event`;
    }
  };

  nextStep = (step = null) => {
    if (!!step && typeof step === "number") this.setState({ step });
    else this.setState({ step: this.state.step + 1 });
  };

  setCustom = (custom) => {
    this.setState({ custom });
  };

  previousStep = () => {
    switch (this.state.step) {
      case 4:
        return this.setState({ step: 2 });
      case 6:
        if (this.state.custom) return this.setState({ step: 5 });
        else return this.setState({ step: 3 });
      default:
        return this.setState({ step: this.state.step - 1 });
    }
  };

  setCompany = (result) => {
    this.setState({
      searchResults: [],
      companyId: result.id,
      companyName: result.name,
    });
  };

  handleLocationChange = (selectedLocation) => {
    const locationArray = [...this.state.location];

    let updatedLocation;
    if (locationArray.includes(selectedLocation)) {
      updatedLocation = locationArray.filter(
        (item) => item !== selectedLocation,
      );
    } else if (selectedLocation === null) {
      updatedLocation = [];
    } else {
      updatedLocation = [...locationArray, selectedLocation];
    }

    this.setState({ location: updatedLocation });
  };

  switchNextButtonDisabled = () => {
    switch (this.state.step) {
      case 6:
        return this.state.plaintext.length === 0;
      case 4:
        if (this.state.type === "job") {
          return (
            this.state.jobTitle.length === 0 ||
            this.state.companyName.length === 0 ||
            this.state.url.length === 0
          );
        } else {
          return (
            this.state.eventTitle.length === 0 ||
            this.state.companyName.length === 0
          );
        }
      default:
        return false;
    }
  };

  closeModal = () => {
    this.props.closeModal();
    this.setState(ZERO_STATE);
  };

  toggleInput = (stateKey) => {
    this.setState((prevState) => ({
        [stateKey]: !prevState[stateKey],
    }));
  }

  submit = async () => {
    try {
      let jobId = this.state.type === "job" ? this.state.jobId : "";
      let eventId = this.state.type === "event" ? this.state.eventId : "";

      if (this.state.custom) {
        if (this.state.type === "job") {
          const res = await createJob(this.props.authUser, this.getObject());
          jobId = res.job.id;
        } else {
          const res = await createStudentEvent(
            this.props.authUser,
            this.getObject(),
          );
          eventId = res.event_id;
        }
      }

      const params = {
        plaintext: this.state.plaintext,
        job_id: jobId,
        event_id: eventId,
        club_id: this.props.club.id,
        uploaded_file: this.state.uploadedFile?.url,
        bot: this.state.postAsBot,
        class_years: this.state.classYears,
      };
      const res = await createPost(this.props.authUser, params);
      const postId = res.post_id;
      notifyClubPostEmail(this.props.authUser, this.props.club.id, postId);
      
    } catch (e) {
      console.log(e);
    }
    this.closeModal();
    this.props.getClubInfo();
  };

  setJobEvent = (result) => {
    this.setState({
      searchResults: [],
      [this.state.type + "Id"]: result.id,
      [this.state.type]: result,
    });
    this.nextStep(6);
  };

  searchChanged = async (text, type = null) => {
    let searchResults;
    if (type !== "event") {
      searchResults = await discoverJobs(this.props.authUser, {query: text, limit: 4}).then(res => res.jobs);
    } else {
      const {events} = await discoverEvents(this.props.authUser, {query: text, limit: 4})
      searchResults = events;
    }
    this.setState({ searchResults });
  };

  companyChanged = async (text) => {
    const searchResults = await searchCompanies(text);
    this.setState({ searchResults, companyId: "", companyName: "" });
  };

  getObject = () => {
    if (this.state.type === "job") {
      if (!this.state.custom) return this.state.job;
      return {
        creator: this.props.authUser.uid,
        name: this.state.jobTitle,
        description: this.state.jobDescription,
        company_id: this.state.companyId,
        company_name: this.state.companyName,
        deadline: this.state.deadline,
        city: !!this.state.location ? [this.state.location] : [],
        state: !!this.state.state ? [this.state.state] : [],
        scope: "club",
        club_id: this.props.club.id,
        clubs: [this.props.club.id],
        link: this.state.url,
        secondary_specialties: !!this.state.role ? [this.state.role] : [],
      };
    } else {
      if (!this.state.custom) return this.state.event;
      const contentState = this.state.eventDescription.getCurrentContent();
      const htmlContent = sanitizeHtml(stateToHTML(contentState));
      return {
        creator: this.props.authUser.uid,
        name: this.state.eventTitle,
        description: htmlContent,
        company_id: this.state.companyId,
        company_name: this.state.companyName,
        location: this.state.virtual ? "Remote" : "In-Person",
        event_date: this.state.date,
        start_time: this.state.startTime,
        end_time: this.state.endTime,
        address: this.state.address,
        city: !!this.state.city ? [this.state.city] : [],
        state: !!this.state.state ? [this.state.state] : [],
        scope: "club",
        club_id: this.props.club.id,
        clubs: [this.props.club.id],
        link: this.state.meetingLink,
        secondary_specialties: !!this.state.role ? [this.state.role] : [],
        register_direct: this.state.registerOnRecruit,
      };
    }
  };

  render() {
    const numSteps = 7;
    const progressWidth = (this.state.step / numSteps) * 100;
    const selectedItem = this.getObject();
    return (
      <ModalComponent
        isOpen={this.props.isOpen}
        header={this.switchTitle()}
        backgroundColor={"white"}
        headerToggle
        size={"lg"}
        toggle={this.closeModal}
        FooterComponent={
          <div className="flex flex-row items-center justify-end gap-3">
            {this.state.step === 2 ? (
              <button
                onClick={this.closeModal}
                className="secondary-button body1-bold"
              >
                Close
              </button>
            ) : (
              <button
                onClick={this.previousStep}
                className="secondary-button body1-bold"
              >
                Previous
              </button>
            )}
            {this.state.step !== 1 && this.state.step !== 2 && (
              <>
                {this.state.step === numSteps ? (
                  <button
                    onClick={this.submit}
                    className={`primary-button body1-bold`}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    onClick={this.nextStep}
                    disabled={this.switchNextButtonDisabled()}
                    className={`primary-button body1-bold ${this.switchNextButtonDisabled() && "opacity-50"}`}
                  >
                    Next
                  </button>
                )}
              </>
            )}
          </div>
        }
        footerAlignment={"right"}
        id={"create-post-modal"}
      >
        <div id={"add-joblisting-modal"}>
          <div id={"add-job-modal"}>
            <div className="bg-slate-200 h-3 mt-4 rounded-full overflow-hidden ">
              <div
                className="bg-indigo-600 h-3"
                style={{
                  width: progressWidth + "%",
                  transition: "width 600ms ease-in-out",
                }}
              />
              {progressWidth}
            </div>
            <div className="px-4 py-4 flex flex-col gap-5 min-h-[20vh] justify-center">
              {this.state.step === 1 ? (
                <SelectPostType
                  nextStep={this.nextStep}
                  changeType={this.changeType}
                />
              ) : null}
              {this.state.step === 2 ? (
                <SelectManualFlow
                  nextStep={this.nextStep}
                  type={this.state.type}
                  setCustom={this.setCustom}
                />
              ) : null}
              {this.state.step === 3 ? (
                <AddJobEvent
                  setJobEvent={this.setJobEvent}
                  searchChanged={this.searchChanged}
                  type={this.state.type}
                  searchResults={this.state.searchResults}
                />
              ) : null}
              {this.state.step === 4 ? (
                <>
                  {this.state.type === "job" ? (
                    <AddJobTitleDescription
                      jobTitle={this.state.jobTitle}
                      jobDescription={this.state.jobDescription}
                      url={this.state.url}
                      handleInputChange={this.handleInputChange}
                      setCompany={this.setCompany}
                      companyChanged={this.companyChanged}
                      searchResults={this.state.searchResults}
                    />
                  ) : (
                    <CreateEventDetailsStep
                      eventTitle={this.state.eventTitle}
                      editorState={this.state.eventDescription}
                      setEditorState={this.setEditorState}
                      role={this.state.role}
                      handleInputChange={this.handleInputChange}
                      handleSelectChange={this.handleSelectChange}
                      setCompany={this.setCompany}
                      companyChanged={this.companyChanged}
                      classYears={this.state.classYears}
                      updateClassYears={this.updateClassYears}
                      searchResults={this.state.searchResults}
                      constants={this.props.constants}
                      inPostFlow
                    />
                  )}
                </>
              ) : null}

              {this.state.step === 5 ? (
                <>
                  {this.state.type === "job" ? (
                    <AddJobLocation
                      location={this.state.location}
                      state={this.state.state}
                      deadline={this.state.deadline}
                      handleLocationChange={this.handleLocationChange}
                      handleInputChange={this.handleInputChange}
                      handleSelectChange={this.handleSelectChange}
                      handleDateChange={this.handleDateChange}
                      industry={this.state.industry}
                      updateIndustry={this.updateIndustry}
                      constants={this.props.constants}
                    />
                  ) : (
                    <CreateEventDateAndLocationStep
                      date={this.state.date}
                      handleDateChange={this.handleDateChange}
                      startTime={this.state.startTime}
                      endTime={this.state.endTime}
                      handleTimeChange={this.handleTimeChange}
                      virtual={this.state.virtual}
                      meetingLink={this.state.meetingLink}
                      state={this.state.state}
                      city={this.state.city}
                      address={this.state.address}
                      handleVirtualChange={this.handleVirtualChange}
                      handleInputChange={this.handleInputChange}
                      handleSelectChange={this.handleSelectChange}
                      toggleInput={this.toggleInput}
                      registerOnRecruit={this.state.registerOnRecruit}
                    />
                  )}
                </>
              ) : null}

              {this.state.step === 6 && (
                <AddPostText
                  plaintext={this.state.plaintext}
                  selectedItem={selectedItem}
                  handleInputChange={this.handleInputChange}
                  onDrop={this.onDrop}
                  allowFileUpload={false}
                  uploadedFile={this.state.uploadedFile}
                  removeFile={this.removeUpload}
                  classYears={this.state.classYears}
                  updateClassYears={this.updateClassYears}
                />
              )}
              {this.state.step === 7 && (
                <div>
                  <div className={"flex flex-row items-center gap-3 mt-5"}>
                    <CompanyLogo
                      id={selectedItem.company_id}
                      className={"w-20 h-20"}
                    />
                    <div>
                      <p className="body1-bold m-0 blacks-1 text-neutral-900">
                        {selectedItem.name}
                      </p>
                      <div className="mt-2 flex flex-row items-center gap-2 flex-wrap">
                        {this.state.classYears.map((classYear) => (
                          <TagComponent
                            label={`Class of ${classYear}`}
                            type={"random"}
                          />
                        ))}
                      </div>
                      {/*
                                            <TagComponent
                                                label={this.state.jobType === "Job" ? "Full Time" : this.state.jobType}
                                                type={'job-type'}
                                            />

                                            <TagComponent
                                                label={this.state.onSiteRemote}
                                                type={'industry'}
                                            />
                                            <TagComponent
                                                label={this.state.industry}
                                                type={'industry'}
                                            />
                                        </div> */}
                    </div>
                  </div>
                  <div className="border border-slate-200 p-5 rounded-xl max-h-[200px] overflow-y-scroll my-5">
                    <p className={"body2 text-slate-800"}>
                      {this.state.plaintext}
                    </p>
                  </div>
                  {!!this.state.uploadedFile && (
                    <div className="w-full flex flex-row items-center gap-3 py-2 rounded-xl px-2">
                      <div className="flex flex-row">
                        <SvgNote
                          width={24}
                          height={24}
                          fill={"#4848F2"}
                          stroke={"#4848F2"}
                        />
                        <p className="body1-bold text-slate-800 ml-8">
                          {this.state.uploadedFile.name}
                        </p>
                      </div>
                    </div>
                  )}
                  {/* {APP_ADMINS.includes(this.props.authUser.uid) ? (
                    <div>
                      <label className="flex items-center cursor-pointer truncate">
                        <input
                          type="checkbox"
                          className="sr-only"
                          checked={this.state.postAsBot}
                          onChange={() => {
                            this.setState({ postAsBot: !this.state.postAsBot });
                          }}
                        />
                        <div
                          className={`w-4 h-4 min-w-4 inline-flex justify-center items-center mr-2 rounded border-2 ${this.state.postAsBot ? "bg-primary border-primary hover:border-primary/20 hover:bg-primary/80" : "bg-transparent border-slate-400 hover:border-slate-500 focus:border-slate-500"}`}
                        >
                          {this.state.postAsBot && (
                            <SvgCheck className="text-white" />
                          )}
                        </div>

                      </label>
                    </div>
                  ) : null} */}
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalComponent>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateJobOrEventModal);
