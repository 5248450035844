import React, {PureComponent} from 'react';
import {signInWithEmailAndPassword} from 'firebase/auth'
import {firebaseAuth} from "../../api/firebase";
import {signUp, signUpRecruiter, checkInvitedEmail} from "../../api/authentication";
import {wait} from "@testing-library/user-event/dist/utils";
import SvgLogo from "../icons/SvgLogo";
import {validateEmail} from "../../utils/strings";
import {connect} from "react-redux";
import '../../App.css'
import AuthForm from '../auth/AuthForm';
import AuthFormTitle from '../auth/AuthFormTitle';

import { SwitchTransition, CSSTransition } from 'react-transition-group';

function mapStateToProps(state) {
    return {
        domain: state.userReducer.domain,
    };
}


class AuthenticationPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            firstName: "",
            lastName: "",
            password: "",
            passwordConfirmation: "",
            currentTab: "login",
            error: "",
            loading: false,
            referrer: null,
            clubId: null,
            path: "",
            invite: null,
        }
    }

    emailChanged = e => this.setState({email: e.target.value.toLowerCase()})

    firstNameChanged = e => this.setState({firstName: e.target.value})

    lastNameChanged = e => this.setState({lastName: e.target.value})

    passwordChanged = e => this.setState({password: e.target.value})

    passwordConfirmationChanged = e => this.setState({passwordConfirmation: e.target.value})

    buttonPressed = () => {
        if (this.state.currentTab === "login") {
            this.logInPressed();
        } else {
            this.signUpPressed();
        }
    }

    logInPressed = async () => {
        let error;
        if (!this.state.email) {
            error = "Please enter an email"
        } else if (!this.state.password) {
            error = "Please enter a password"
        }
        if (error) {
            this.setState({error})
            return
        }
        this.setState({loading: true})

        try {
            await signInWithEmailAndPassword(firebaseAuth, this.state.email, this.state.password);
            wait(1000).then(() => {
                this.props.history.replace(!!this.state.path ? "/" + this.state.path : "/")
            })
        } catch (err) {
            this.setState({error: "Incorrect username and password.", loading: false})
        }


    }

    signUpPressed = async () => {
        let error;
        if (!this.state.email) {
            error = "Please enter an email"
        } else if (!this.state.password) {
            error = "Please enter a password"
        } else if (!this.state.firstName || !this.state.lastName) {
            error = "Please enter your first and last name"
        } else if (this.state.password !== this.state.passwordConfirmation) {
            error = "Password do not match"
        } else if (!validateEmail(this.state.email, this.props.recruiter, this.props.domain.startsWith("blkcapitalmanagement"))) {
            error = "Invalid email address. Please use a valid .EDU email."
        }
        if (error) {
            this.setState({error})
            return;
        }
        this.setState({loading: true})

        if (this.props.recruiter) {
            const res = await checkInvitedEmail(this.state.email)

            if (res.invited) {
                await signUpRecruiter(this.state.email, this.state.password, this.state.firstName, this.state.lastName, res.invited);
            } else {
                this.setState({
                    error: "Email has not been invited to sign up. Please check with your company administrator for an invite.",
                    loading: false
                });
                return;
            }
        } else {
            await signUp(
                this.state.email, 
                this.state.password, 
                this.state.firstName, 
                this.state.lastName, 
                this.state.referrer, 
                this.state.clubId,
            ).catch(err => {
                this.setState({error: err.response.data.error, loading: false});
                return;
            })
        }
        await signInWithEmailAndPassword(firebaseAuth, this.state.email, this.state.password)
        wait(1000).then(() => {
            if (!this.props.recruiter) {
                this.props.history.replace(!!this.state.path ? "/onboarding/?path=" + this.state.path : "/onboarding");
            } else {
                this.props.history.replace("/")
            }
        })
    }


    setTab = (tab) => {
        this.setState({
            currentTab: tab, 
            email: "", 
            firstName: "", 
            lastName: "", 
            password: "", 
            passwordConfirmation: "", 
            error: null, 
        });
    }

    componentDidMount = () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const referrer = queryParameters.get("referred", null);
        const activeTab = queryParameters.get("activeTab");
        const clubId = queryParameters.get("clubId", null);
        const path = queryParameters.get("path", "");
        const invite = queryParameters.get("invite", null);
        if (activeTab === "signup") {
            this.setState({currentTab: "signup"})

            if (!this.props.recruiter && !this.props.mobile) {
                this.props.history.replace(!!referrer ? `/signup?referred=${referrer}` :`/signup?path=${path}`)
            }
        }

        if (!!clubId) {
            this.setState({clubId: clubId});
        } else if (this.props.domain.startsWith("blkcapitalmanagement")) {
            this.setState({clubId: "baeZVpmndkm8fCNGFIuZ"})
        }
        this.setState({referrer, path, invite});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.recruiter !== prevProps.recruiter) {
            this.setState({currentTab: 'login'})
        }
    }

    renderName = () => {
        if (this.props.domain.startsWith("blkcapitalmanagement")) {
            return "BLK Capital Management"
        }
        return "RecruitU"
    }

    render() {
        const isBlk = this.props.domain.startsWith("blkcapitalmanagement");
        return (
            <div
                className={`flex flex-col flex-1 overflow-y-scroll hide-scrollbar justify-center min-h-[100dvh] py-20 relative ${this.props.recruiter ? 'recruiter-gradient' : 'student-gradient'}`}>
                <div className='flex flex-col gap-0 items-center mb-7 relative'>
                    {isBlk ?
                        <img alt={'logo'} style={{height: 52}} className='px-3 object-contain'
                             src={require('../../img/brand/blklogo.png')}/>
                        :
                        <SvgLogo className={'w-56 text-white'}/>
                    }
                    {this.props.recruiter &&
                        <p className='body1-bold text-white absolute bottom-[-24px]'>
                            For Recruiters
                        </p>
                    }
                </div>
                <form className="col-ac mt-8" onSubmit={e => e.preventDefault()}>
                    <SwitchTransition>
                        <CSSTransition
                            key={this.state.currentTab}
                            timeout={500}
                        classNames="fade-slide"
                    >
                        <div className="card bg-white p-7 mx-10 md:mx-0" style={{zIndex: 0}}>

                            <AuthFormTitle
                                currentTab={this.state.currentTab}
                                recruiter={this.props.recruiter}
                                invite={this.state.invite}
                                renderName={this.renderName}
                            />
                        
                            <AuthForm
                                currentTab={this.state.currentTab}
                                recruiter={this.props.recruiter}
                                email={this.state.email}
                                emailChanged={this.emailChanged}
                                firstName={this.state.firstName}
                                firstNameChanged={this.firstNameChanged}
                                lastName={this.state.lastName}
                                lastNameChanged={this.lastNameChanged}
                                password={this.state.password}
                                passwordChanged={this.passwordChanged}
                                passwordConfirmation={this.state.passwordConfirmation}
                                passwordConfirmationChanged={this.passwordConfirmationChanged}
                                error={this.state.error}
                                buttonPressed={this.buttonPressed}
                                loading={this.state.loading}
                                mobile={this.props.mobile}
                                history={this.props.history}
                                setTab={this.setTab}
                                path={this.state.path}
                            />
                        </div>
                    </CSSTransition>
                </SwitchTransition>
                </form>
                {/* {this.props.recruiter ?
                    <div className='row-ac-jc mt-20'>
                        <p className='body2 base-white-50 mr-4'>
                            Don't have an account?
                        </p>
                        <a
                            target={'_blank'}
                            rel={'noreferrer'}
                            href={'https://calendly.com/brooks-gammill/30min?month=2023-12'}
                            className='body2-bold base-white-50 hover:text-primary pointer'>
                            Book a call
                        </a>
                    </div>
                    :
                    <div className='row-ac-jc mt-5'>
                        <p className='body2 base-white-50 mr-4'>
                            Looking for recruiter login?
                        </p>
                        <Link to={'/recruiter/authentication'}
                              className='body2-bold base-white-70 h-base-white-100 pointer'>
                            Click here
                        </Link>
                    </div>
                } */}
            </div>
        );
    }
}

AuthenticationPage.propTypes = {};

export default connect(mapStateToProps)(AuthenticationPage);
