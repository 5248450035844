import React, { useState, useRef, useEffect } from 'react';
import RenderHtml from './RenderHtml';

const ShowMoreRichText = ({ htmlString, maxLines = 3, className = '' }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false);
    const contentRef = useRef(null);
    
    useEffect(() => {
        const checkHeight = () => {
            if (contentRef.current) {
                const lineHeight = parseInt(window.getComputedStyle(contentRef.current).lineHeight);
                const maxHeight = lineHeight * maxLines;
                const actualHeight = contentRef.current.scrollHeight;
                setIsTruncated(actualHeight > maxHeight);
            }
        };

        checkHeight();
        // Recheck on window resize
        window.addEventListener('resize', checkHeight);
        return () => window.removeEventListener('resize', checkHeight);
    }, [maxLines, htmlString]);

    return (
        <div className={className}>
            <div 
                ref={contentRef}
                style={{
                    maxHeight: isExpanded ? 'none' : `${maxLines}lh`,
                    overflow: 'hidden'
                }}
            >
                <RenderHtml htmlString={htmlString} />
            </div>
            {isTruncated && (
                <span 
                    className='text-primary cursor-pointer'
                    onClick={() => setIsExpanded(prev => !prev)}
                >
                    {isExpanded ? 'Show Less' : 'Show More'}
                </span>
            )}
        </div>
    );
};

export default ShowMoreRichText;