import axios from "../axios";
import { PDFDocument } from "pdf-lib"
import pMap from 'p-map';
import { RESUME_DOWNLOAD_CONCURRENCY } from "../../utils/constants";
export const getClub = async (user, club_id) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
      params: { club_id },
    };
    const res = await axios.get("/clubs", config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};


export const getSchoolClubs = async (user, school_name) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
      params: { school_name },
    };
    const res = await axios.get("/clubs/school", config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const editClub = async (user, club_id, params_to_edit) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const body = {
      updated_params: params_to_edit,
      club_id,
    };
    const res = await axios.put("/clubs", body, config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const createClubApplication = async (user, params) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post("/clubs", params, config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const addFeaturedJob = async (user, club_id, job_id) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post(
      "/clubs/featured_jobs",
      { club_id, job_id },
      config
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const addFeaturedEvent = async (user, club_id, event_id) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post(
      "/clubs/featured_events",
      { club_id, event_id },
      config
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const addRecentPlacement = async (user, club_id, placement) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post(
      "/clubs/recent_placements",
      { club_id, placement },
      config
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const joinClub = async (user, club_id) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const res = await axios.post("/clubs/join", { club_id }, config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const notifyClubPostEmail = async (user, club_id, post_id) => {
  if (!user) {
    return;
  }
  const token = await user.getIdToken();
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        mode: "no-cors",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post("/clubs/notify", { club_id, post_id }, config);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

const getResumeBuffer = async (resume) => {
  const res = await fetch(
    resume
  );
  // const blob = await res.blob();
  const buf = await res.arrayBuffer();
  return buf;
};

// clubId, year="*"
export const downloadAllResumes = async (authUser, members) => {
  if (!authUser) {
    return;
  }

  const everyone = members
    .filter(
      (member) =>
        member.onboarded && !member.deactivated && member.resume !== ""
    )
 //&& member?.grade >= 2025 && re.test(member?.grade));
  if (everyone.length === 0) {
    throw new Error("No resumes for this grade");
  }

  const resumeBlobs = await pMap(everyone, async (member) => {
    return getResumeBuffer(member.resume);
  // we need to limit the concurrency otherwise it will fail for large lists
  }, { concurrency: RESUME_DOWNLOAD_CONCURRENCY });

  // combined pdfs
  const combinedPdf = await PDFDocument.create();
  await Promise.all(
    resumeBlobs.map(async (blob, index) => {
      try {
        const pdf = await PDFDocument.load(blob);
        for (var i = 0; i < pdf.getPageCount(); i++) {
          const [page] = await combinedPdf.copyPages(pdf, [i]);
          combinedPdf.addPage(page);
        }
      } catch (err) {
        console.error(`Error processing resume for user `, err);

      }
    })
  );

  const pdfBytes = await combinedPdf.save();
  const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
  downloadPdf(pdfBlob);
  return true;
};

const downloadPdf = (blob) => {
  // download locally
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", "resumes.pdf");
  document.body.appendChild(link);
  link.click();
};
