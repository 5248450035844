import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { page_h_padding_class } from "../../utils/constants";
import { getUserAnswers } from "../../api/student/answers";
import AnimatedTabs from "../atoms/AnimatedTabs";
import SvgDashboard from "../icons/SvgDashboard";
import SvgQuiz from "../icons/SvgQuiz";
import SvgQuestionSet from "../icons/SvgQuestionSet";
import ResourcesInterviewSets from "../resources/ResourcesInterviewSets";
import ResourcesQuestionBankNew from "../resources/ResourcesQuestionBankNew";
import ResourcesLearnTab from "../resources/ResourcesLearnTab";
import useUser from "../../hooks/useUser";
import { isFeatureEnabled } from "../../config/featureFlags";

import OnboardingLearnModal from "../resources/OnboardingLearnModal";


function ResourcesPage(props) {
    const { authUser } = useUser();
    const [state, setState] = useState({
        activeTab: isFeatureEnabled('LEARN_OPTIMIZATION') ? 'Practice' : 'Interviews',
        answers: [],
        statistics: {},
        grid: true,
        selectedSet: null,
        selectedTopic: null,
        loading: true
    });

    const dispatch = useDispatch();

    const setAnswerIds = (answerIds) => {
        dispatch({
            type: "SET_ANSWER_IDS",
            answerIds,
        })
    }

    const changeTab = (tab) => {
        setState(prev => ({
            ...prev,
            selectedSet: tab !== 'Interviews' ? null : prev.selectedSet,
            selectedTopic: tab !== 'Question Bank' ? null : prev.selectedTopic,
            activeTab: tab
        }));

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("activeTab", tab);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        props.history.replace(newUrl);
    };

    useEffect(() => {
        async function init() {
            const queryParams = new URLSearchParams(window.location.search);
            const activeTab = queryParams.get("activeTab");
            if (!!activeTab) changeTab(activeTab);

            const res = await getUserAnswers(authUser);
            setAnswerIds(res.answers.map(answer => answer.question_id));
            setState(prev => ({
                ...prev,
                answers: res.answers,
                statistics: res.statistics,
                loading: false
            }));

            
        }

        init();
    }, []);

  

    // const selectInterviewToPreview = (set) => {
    //     setState(prev => ({...prev, selectedSet: set}));
    //     changeTab('Interviews');
    // };

    // const selectTopicToPreview = (topic) => {
    //     setState(prev => ({...prev, selectedTopic: topic}));
    //     changeTab('Question Bank');
    // };

    return (
        <>
            <div className={`flex flex-col flex-1 overflow-hidden relative ${page_h_padding_class}`}>

                <div className='pt-3 w-full bg-white'>
                    <AnimatedTabs
                        activeTab={state.activeTab}
                        changeTab={changeTab}
                        tabs={isFeatureEnabled('LEARN_OPTIMIZATION') ? [
                            {title: 'Practice', icon: SvgQuiz},
                            // {title: 'Interviews', icon: SvgQuestionSet},
                            // {title: 'Resources', icon: SvgDashboard}
                        ] : [
                            // {title: 'Interviews', icon: SvgQuestionSet},
                            {title: 'Practice', icon: SvgQuiz},
                            // {title: 'Resources', icon: SvgDashboard}
                        ]}
                    />
                </div>
                {/* {state.activeTab === 'Resources' &&
                    <>
                        <ResourcesLearnTab/>
                    </>
                } */}
                {/* {state.activeTab === 'Interviews' &&
                    <ResourcesInterviewSets 
                        selectedSet={state.selectedSet} 
                        answers={state.answers}
                        changeTab={changeTab} 
                    />
                } */}
                {state.activeTab === 'Practice' &&
                    <>
                        <ResourcesQuestionBankNew 
                            selectedTopic={state.selectedTopic} 
                            answers={state.answers}
                        />
                    </>
                }
            </div>
            <OnboardingLearnModal />
        </>
    );
}

export default ResourcesPage;
