import React, {useState, useEffect} from "react";
import RecruiterDashboardTable from "../dashboard/RecruiterDashboardTable";
import {ATTENDEES_TABLE_HEADERS_FULLPAGE} from "../../utils/dummy";
import RecruiterEventAttendeeTableItem from "../RecruiterEventAttendeeTableItem";
import GoBack from "../../components/atoms/GoBack";
import {connect} from "react-redux";
import SvgVideo from "../../components/icons/SvgVideo";
import SvgPin from "../../components/icons/SvgPin";
import RecruiterEditEventModal from "../modals/RecruiterEditEventModal";
import {getEvent, getEventAttendees} from "../../api/recruiter/events";
import Spinner from "../../components/Spinner";
import {Button} from "../../components/atoms/Button";
import MessageStudentFlow from "../MessageStudentFlow";
import {toast} from "react-toastify";
import {LinkIcon} from "@heroicons/react/24/outline";
import {TOAST_OPTIONS} from "../../utils/constants";
import RecruiterBulkCreateList from "../RecruiterBulkCreateList";
import { QualifiedOnlyProvider } from "../../contexts/QualifiedOnlyContext";
import RecruiterSearchFilters from "../atoms/RecruiterSearchFilters";
import useFiltersState from "../../hooks/useFiltersState";
import { filterUsers } from "../../utils/searchUtils";



function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        candidates: state.userReducer.candidates,
        constants: state.userReducer.constants,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setCandidates: (candidates) => {
            dispatch({
                type: "SET_CANDIDATES",
                candidates,
            })
        },
    }
}

function RecruiterEventListingPage(props) {
    const [attendees, setAttendees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editEventModalOpen, setEditEventModalOpen] = useState(false);
    const [event, setEvent] = useState({});
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [selectedAttendees, setSelectedAttendees] = useState(new Set());
    const [sortStates, setSortStates] = useState({
        name: 0,
        rsvp_at: 1
    });

    const {
        filters,
        setFilter,
        handleFilterClassYearsSelection,
        handleSearchChange,
        updateDiversity,
        clearFilters,
        toggleSchoolSort,
        canClearFilters,
        toggleSearchSort
    } = useFiltersState();

    const candidates = React.useMemo(() => Object.values(props.candidates) || [], [props.candidates]);

    const filteredAttendees = React.useMemo(() => {
        if (!candidates || candidates.length === 0) return [];
        
        const attendeesList = candidates.filter(user => attendees.includes(user.username));
        
        const filterParams = {
            search: filters.search,
            majors: filters.majors,
            classYears: filters.classYears,
            gpa: filters.gpa,
            gender: filters.gender,
            race: filters.race,
            veteran: filters.veteran,
            lgbtq: filters.lgbtq,
            disabled: filters.disabled,
            authorized: filters.authorized,
            college: filters.schools.length > 0 ? filters.schools[0] : '',
            graduationSeason: filters.graduationSeason,
            clubs: filters.clubs,
            first_generation: filters.first_generation,
            require_sponsorship: filters.require_sponsorship,
            currentCompanies: filters.currentCompanies,
            previousCompanies: filters.previousCompanies,
            verticals: filters.verticals,
            previousVerticals: filters.previousVerticals,
            searchSortState: filters.searchSortState,
            schoolSortState: filters.schoolSortState
        };
        
        let filtered = filterUsers(attendeesList, filterParams);
        
        const activeSortKey = Object.entries(sortStates).find(([_, value]) => value > 0)?.[0];
        if (activeSortKey && activeSortKey !== 'name') {
            filtered.sort((a, b) => {
                if (activeSortKey === 'rsvp_at') {
                    const comparison = new Date(b.rsvp_at || 0) - new Date(a.rsvp_at || 0);
                    return sortStates[activeSortKey] === 1 ? comparison : -comparison;
                }
                return 0;
            });
        }
        
        return filtered;
    }, [candidates, attendees, filters, sortStates]);

    useEffect(() => {
        setInfo();
    }, [props.location.params?.eventId]);

    const setInfo = async () => {
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const eventId = lastArg.split("?")[0];

        const res = await getEvent(props.authUser, eventId);
        const result = await getEventAttendees(props.authUser, eventId);
        props.setCandidates(result.students);

        setEvent(res.event);
        setAttendees(result.students.map(student => student.id));
        setLoading(false);
    };

    const deleteEvent = async () => {
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const eventId = lastArg.split("?")[0];
        await deleteEvent(props.authUser, eventId);
        props.history.goBack()
    };

    const toggleEditEventModal = () => {
        setEditEventModalOpen(!editEventModalOpen);
    };

    const downloadCsv = () => {
        const attendeesList = filteredAttendees;
        const end = attendeesList.map(row => `${row.first_name} ${row.last_name},${row.email},${row.grade},${row.college},${row.gpa},${row?.major.join("/")},${row?.minor?.join("|")},${row?.diversity?.authorized},${row.diversity?.disabled},${row.diversity?.first_generation},${row.diversity?.gender},${row.diversity?.race?.join("|")},${row.diversity?.require_sponsorship},${row.diversity?.veteran}`).join('\n');

        const csv = `name,email,class year,college,gpa,majors,minors,authorized to work,disability,first generation,gender,race,requires sponsorship,veteran\n` + end;
        const blob = new Blob([csv], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'attendees.csv');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const previewEvent = () => {
        props.history.push(`/event/${event.id}`);
    };

    const handleSelectStudentChange = (student) => {
        const newSelectedAttendees = new Set(selectedAttendees);
        if (newSelectedAttendees.has(student.id)) {
            newSelectedAttendees.delete(student.id);
        } else {
            newSelectedAttendees.add(student.id);
        }
        setSelectedAttendees(newSelectedAttendees);
    };

    const toggleSelectAll = () => {
        const newState = !selectAllChecked;
        setSelectAllChecked(newState);
        if (newState) {
            setSelectedAttendees(new Set(filteredAttendees.map(attendee => attendee.id)));
        } else {
            setSelectedAttendees(new Set());
        }
    };

    const handleSort = (key) => {
        const newSortStates = { ...sortStates };
        Object.keys(newSortStates).forEach(k => {
            if (k !== key) newSortStates[k] = 0;
        });
        newSortStates[key] = (newSortStates[key] + 1) % 3;
        setSortStates(newSortStates);
    };

    const updateRace = (raceValues) => {
        setFilter('race', raceValues);
    };

    return (
        <div className={`flex flex-col flex-1 p-[12px] overflow-hidden`}>
            <div className='flex flex-col gap-5'>
                <div className='flex flex-row justify-between items-start gap-12'>
                    <div className='flex flex-col gap-1 flex-1'>
                        <GoBack/>
                        <div className="flex flex-row items-center">
                            <p className="text-2xl font-semibold flex items-center">
                                {props.location.params?.name || event.name}
                                {!loading &&
                                    <span
                                        onClick={() => {
                                            navigator.clipboard.writeText(`https://app.recruitu.com/event/${event.id}`);
                                            toast.success("Link copied to clipboard!", TOAST_OPTIONS);
                                        }}
                                        className="ml-2 px-2 py-2 rounded-3xl bg-primary/20 flex items-center justify-center hover:opacity-50 cursor-pointer"
                                    >
                                        <LinkIcon className="w-5 h-5 text-primary"/>
                                    </span>
                                }
                            </p>
                        </div>
                        {!!event.location ?
                            <div className='flex flex-row items-center gap-2 -mt-1 text-slate-500 wrap'>
                                <div className='flex flex-row items-center gap-2'>
                                    {event.location === "Remote" ?
                                        <SvgVideo className={'w-3.5 w-3.5'}/> :
                                        <SvgPin className={'w-5 w-5'}/>}
                                    <p className='text-sm '>
                                        {event.location}
                                    </p>
                                </div>
                                {event.location !== "Remote" && !!event.city && !!event.state ?
                                    <>
                                        <p>
                                            •
                                        </p>
                                        <p className='text-md '>
                                            {event.city[0]}, {event.state[0]}
                                        </p>
                                    </>
                                    : null}
                            </div>
                            :
                            <>
                                {!loading ?
                                    <div className='flex flex-row items-center gap-2 text-slate-500 wrap'>
                                        <div className='flex flex-row items-center gap-1'>
                                            <SvgVideo className={'w-5 w-5'}/>
                                            <p className='text-lg '>
                                                Remote
                                            </p>
                                        </div>
                                    </div>
                                    : null}
                            </>
                        }
                    </div>
                    <div>
                        <div className='flex flex-col items-end gap-3'>
                            <div className={'flex flex-row gap-3'}>
                                <MessageStudentFlow users={candidates} buttonVariant={'default'}/>
                                <Button variant={'secondary'} onClick={downloadCsv}>
                                    Download CSV
                                </Button>
                                <RecruiterBulkCreateList studentIds={Array.from(selectedAttendees)}
                                                         originalName={event.name}/>
                            </div>
                            <div className={'flex flex-row gap-3'}>
                                <Button variant={'secondary'} onClick={toggleEditEventModal} disabled={loading}>
                                    Edit Event
                                </Button>
                                <Button variant={'secondary'} onClick={previewEvent} disabled={loading}>
                                    Preview Event
                                </Button>
                                <Button variant={'destructive'} onClick={deleteEvent} disabled={loading}>
                                    Delete Event
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'flex flex-col flex-1 overflow-hidden'}>
                <div className='flex-1 flex flex-row gap-3 overflow-hidden'>
                    <div className={'w-[250px] flex flex-col'}>
                        <RecruiterSearchFilters
                            search={{
                                placeholder: 'Search by name...',
                                value: filters.search,
                                onChange: (e) => handleSearchChange(e.target.value),
                                toggleSearchSort: toggleSearchSort
                            }}
                            
                            schools={filters.schools}
                            updateCollege={(schools) => setFilter('schools', schools)}
                            toggleSchoolSort={toggleSchoolSort}
                            searchSortState={filters.searchSortState}
                            schoolSortState={filters.schoolSortState}
                            
                            clubs={filters.clubs}
                            setClubs={(clubs) => setFilter('clubs', clubs)}
                            
                            majors={filters.majors}
                            updateMajor={(majors) => setFilter('majors', majors)}
                            
                            classYears={filters.classYears}
                            handleFilterClassYearsSelection={handleFilterClassYearsSelection}

                            graduationSeason={filters.graduationSeason}
                            updateGraduationSeason={(season) => setFilter('graduationSeason', season)}
                            
                            currentCompanies={filters.currentCompanies}
                            setCurrentCompany={(companies) => setFilter('currentCompanies', companies)}
                            
                            previousCompanies={filters.previousCompanies}
                            setPreviousCompany={(companies) => setFilter('previousCompanies', companies)}

                            verticals={filters.verticals}
                            setVerticals={(verticals) => setFilter('verticals', verticals)}
                            previousVerticals={filters.previousVerticals}
                            setPreviousVerticals={(verticals) => setFilter('previousVerticals', verticals)}
                            
                            gpa={filters.gpa}
                            setGpaFilter={(gpa) => setFilter('gpa', gpa)}
                            
                            gender={filters.gender}
                            updateDiversity={updateDiversity}
                            race={filters.race}
                            updateRace={updateRace}
                            veteran={filters.veteran}
                            lgbtq={filters.lgbtq}
                            disabled={filters.disabled}
                            authorized={filters.authorized}
                            first_generation={filters.first_generation}
                            require_sponsorship={filters.require_sponsorship}
                            
                            clearFilters={canClearFilters ? clearFilters : undefined}
                        />
                    </div>
                    <div className='flex flex-col overflow-hidden flex-1'>
                        <div className={' py-2 text-lg font-semibold'}>
                            {filteredAttendees.length} attendees
                        </div>
                        <RecruiterDashboardTable
                            hasCheckbox={true}
                            checked={selectAllChecked}
                            toggleCheckbox={toggleSelectAll}
                            columns={ATTENDEES_TABLE_HEADERS_FULLPAGE}
                            onSort={handleSort}
                            sortStates={sortStates}
                            TableBody={
                                loading ?
                                    <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                                        <Spinner/>
                                    </div>
                                    :
                                    <div className='flex flex-col gap-2 p-5 pt-2 flex-1 overflow-y-scroll'>
                                        {filteredAttendees.length === 0 ?
                                            <div
                                                className='flex flex-col items-center justify-center text-slate-500 text-sm flex-1'>
                                                No attendees to show
                                            </div>
                                            :
                                            filteredAttendees.map(item =>
                                                <RecruiterEventAttendeeTableItem
                                                    key={item.id}
                                                    item={item}
                                                    columns={ATTENDEES_TABLE_HEADERS_FULLPAGE}
                                                    onSelectStudentChange={handleSelectStudentChange}
                                                    isSelected={selectedAttendees.has(item.id)}
                                                />
                                            )}
                                    </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <QualifiedOnlyProvider>
                <RecruiterEditEventModal
                    event={event}
                    isOpen={editEventModalOpen}
                    closeModal={toggleEditEventModal}
                    authUser={props.authUser}
                    constants={props.constants}
                />
            </QualifiedOnlyProvider>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterEventListingPage);
