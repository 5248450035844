import React, {Component} from "react";
import {Button} from "../../components/atoms/Button";
import SvgMail from "../../components/icons/SvgMail";
import SvgClock from "../../components/icons/SvgClock";
import {getCompanyEmails} from "../../api/recruiter/companies";
import {connect} from "react-redux";
import RenderHtml from "../../components/atoms/RenderHtml";
import Spinner from "../../components/Spinner";
import SvgLogoPrimary from "../../components/icons/SvgLogoPrimary";
import SvgSend from "../../components/icons/SvgSend";
import SvgUsers from "../../components/icons/SvgUsers";
import SvgDownload from "../../components/icons/SvgDownload";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        recruiter: state.userReducer.user,
    };
}

class RecruiterEngagePage extends Component {
    constructor(props) {
        super(props);
        this.popoverRef = React.createRef();
        this.state = {
            emails: [],
            emailsLoading: true,
            selectedEmailId: null,
            searchQuery: "",
            showRecipients: false
        };
    }

    componentDidMount = async () => {
        this.getEmailHistory();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.popoverRef.current && !this.popoverRef.current.contains(event.target)) {
            this.setState({showRecipients: false});
        }
    }

    getEmailHistory = async () => {
        this.setState({emailsLoading: true})
        const res = await getCompanyEmails(this.props.authUser);
        this.setState({
            emails: res.emails,
            emailsLoading: false,
            selectedEmailId: res.emails.length > 0 ? res.emails[0].id : null
        })
    }

    navigateToEmail = () => {
        this.props.history.push({
            pathname: `/bulk-email`,
        })
    }

    selectEmail = (emailId) => {
        this.setState({selectedEmailId: emailId});
    }

    handleSearch = (e) => {
        this.setState({searchQuery: e.target.value});
    }

    getFilteredEmails = () => {
        const {emails, searchQuery} = this.state;
        if (!searchQuery) return emails;

        return emails.filter(email =>
            email.subject.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }

    renderEmailListItem = (email) => {
        const isSelected = this.state.selectedEmailId === email.id;
        const date = new Date(email.created_at);
        const formattedDate = date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
        });
        const formattedTime = date.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
        }).toLowerCase();

        return (
            <div
                key={email.id}
                onClick={() => this.selectEmail(email.id)}
                className={`cursor-pointer p-3 border-b border-slate-200 relative ${
                    isSelected ? 'opacity-100 bg-slate-50' : 'opacity-60 hover:opacity-80'
                }`}
            >
                <h3 className="text-slate-900 font-medium mb-1">{email.subject}</h3>
                <div className="flex items-center text-sm text-slate-600">
                    <span>{formattedDate}, {formattedTime}</span>
                    <span className="mx-2">·</span>
                    <span>{(email.emails || []).length} Recipients</span>
                </div>
                {isSelected && (
                    <div className="absolute right-0 top-0 bottom-0 w-1 bg-primary"/>
                )}
            </div>
        );
    }

    renderSelectedEmail = () => {
        const selectedEmail = this.state.emails.find(email => email.id === this.state.selectedEmailId);
        if (!selectedEmail) return null;

        const date = new Date(selectedEmail.created_at);
        const formattedDate = date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
        const formattedTime = date.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
        }).toLowerCase();
        console.log(selectedEmail)
        return (
            <div className="flex flex-1 overflow-hidden">
                <div className={'p-3 border-b border-slate-200 text-slate-600 min-h-[109px]'}>
                    <div className={'flex flex-row items-center gap-2 mt-1 mb-2'}>
                        <SvgMail className={'w-5 h-5 text-slate-900'}/>
                        <h2 className="text-xl font-semibold text-slate-900">{selectedEmail.subject}</h2>
                    </div>
                    <div className={'flex flex-row items-center gap-1 mb-1'}>
                        <SvgClock className={'w-4 h-4'}/>
                        <p className="text-xs">Sent {formattedDate} at {formattedTime}</p>
                    </div>
                    <div className={'flex flex-row items-center gap-1 relative'}>
                        <SvgUsers className={'w-4 h-4'}/>
                        <p className="text-xs">
                            Recipients ({selectedEmail.emails?.length || 0}): {
                            selectedEmail.emails?.length > 3 ? (
                                <>
                                    {selectedEmail.emails.slice(0, 3).join(", ")}
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState(prev => ({showRecipients: !prev.showRecipients}));
                                        }}
                                        className="text-blue-600 hover:text-blue-700 ml-1"
                                    >
                                        + {selectedEmail.emails.length - 3} more
                                    </button>
                                    <div
                                        className={`absolute left-0 top-6 transition-all duration-200 ease-in-out ${
                                            this.state.showRecipients
                                                ? 'opacity-100 translate-y-0 pointer-events-auto'
                                                : 'opacity-0 -translate-y-2 pointer-events-none'
                                        }`}
                                    >
                                        <div
                                            ref={this.popoverRef}
                                            className="bg-white shadow-lg rounded-lg border border-slate-200 z-50 max-h-[300px] w-[400px] overflow-y-auto"
                                            onClick={e => e.stopPropagation()}
                                        >
                                            <div className="p-3">
                                                <div className="flex justify-between items-center mb-2">
                                                    <h3 className="font-medium">All Recipients</h3>
                                                    <button
                                                        onClick={() => this.setState({showRecipients: false})}
                                                        className="text-slate-500 hover:text-slate-700 p-1 rounded hover:bg-slate-100 transition-colors"
                                                    >
                                                        ✕
                                                    </button>
                                                </div>
                                                <div className="divide-y divide-slate-100">
                                                    {selectedEmail.emails.map((email, index) => (
                                                        <div key={index} className="py-2 text-sm">
                                                            {email}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : selectedEmail.emails?.join(", ")
                        }
                        </p>
                    </div>
                </div>
                <div className="text-slate-700 flex p-3 flex-1 flex-col items-center overflow-y-scroll">
                    <div className={'w-full max-w-[680px] pb-[120px]'}>
                        <div>
                            <div
                                className={'flex flex-row items-center justify-between pb-5 pt-2 border-b border-slate-200 mb-5'}>
                                <div className={'flex flex-row items-center'}>
                                    <div
                                        className={'w-10 h-10 bg-slate-200 rounded-full flex flex-col items-center justify-center'}>
                                        {this.props.recruiter?.first_name?.[0]}{this.props.recruiter?.last_name?.[0]}
                                    </div>
                                    <p className={'text-sm ml-3'}>
                                        Sent
                                        from {this.props.recruiter?.first_name} {this.props.recruiter?.last_name} (you)
                                        on RecruitU
                                    </p>
                                </div>
                                <SvgLogoPrimary style={{height: 24, width: 100}}/>
                            </div>
                        </div>
                        <RenderHtml htmlString={selectedEmail.text}/>
                    </div>
                </div>
            </div>
        );
    }

    handleDownloadCsv = () => {
        // Create CSV header row
        const headers = ['Sender', 'Subject', 'Message content', 'Recipient list'];

        // Convert data to CSV rows
        const csvData = this.state.emails.map(email => ({
            sender: email.entity,
            subject: email.subject,
            message: email.text.replace(/<[^>]+>/g, ''),
            recipients: (email.emails || []).join(', ')
        }));

        const csvRows = [
            headers.join(','), // Header row
            ...csvData.map(row => [
                `"${row.sender || ''}"`,
                `"${row.subject || ''}"`,
                `"${row.message || ''}"`,
                `"${row.recipients || ''}"`,
            ].join(','))
        ].join('\n');

        // Create blob and download
        const blob = new Blob([csvRows], {type: 'text/csv;charset=utf-8;'});
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'email_history.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        const {emails, emailsLoading, searchQuery} = this.state;
        const filteredEmails = this.getFilteredEmails();
        console.log(this.props.authUser, this.props.recruiter)
        return (
            <div className={`flex flex-row flex-1 overflow-hidden`}>
                <div className={'max-w-[30vw] border-r border-slate-200 flex flex-col flex-1'}>
                    <div className="gap-2 flex flex-col p-3 border-b border-slate-200">
                        <div className={'flex flex-row items-center justify-between'}>
                            <p className='text-slate-800 text-xl font-semibold'>
                                Email History
                            </p>
                            <Button
                                variant={'default'}
                                size={'sm'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.navigateToEmail();
                                }}>
                                Compose <SvgSend className={'w-4 -rotate-45 h-4 -mt-1'}/>
                            </Button>
                        </div>
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Search emails"
                                value={searchQuery}
                                onChange={this.handleSearch}
                                className="w-full pl-9 pr-4 py-2.5 !bg-gray-100 rounded-lg !text-sm !text-gray-900 placeholder:!text-gray-500 focus:outline-none"
                            />
                            <svg
                                className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="flex-1 overflow-y-scroll">
                        {emailsLoading ? (
                            <div
                                className="p-4 text-center flex flex-col flex-1 justify-center items-center text-slate-600">
                                <Spinner size={16}/>
                                <p className={'mt-3'}>
                                    Loading emails...
                                </p>
                            </div>
                        ) : filteredEmails.length === 0 ? (
                            <div
                                className="p-4 text-center flex flex-col flex-1 justify-center items-center text-slate-600">
                                <p className={'mt-3'}>
                                    {emails.length === 0 ? 'No emails sent yet' : 'No matching emails found'}
                                </p>
                            </div>
                        ) : (
                            filteredEmails.map(this.renderEmailListItem)
                        )}
                    </div>
                    <div className={'p-3'}>
                        <Button
                            className={'w-full'}
                            variant={'secondary'}
                            onClick={this.handleDownloadCsv}
                            disabled={this.state.emailsLoading || this.state.emails.length === 0}
                        >
                            <SvgDownload className={'w-4 h-4 mr-1'}/>
                            Save history as CSV
                        </Button>
                    </div>
                </div>
                {!this.state.selectedEmailId ?
                    <div className={'flex flex-col items-center justify-center flex-1'}>
                        {filteredEmails.length > 0 ?
                            <p>
                                Select an email to view.
                            </p>
                            :
                            <p>
                                No emails yet
                            </p>
                        }
                    </div>
                    :
                    <div className="flex-1 overflow-hidden">
                        {this.renderSelectedEmail()}
                    </div>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps)(RecruiterEngagePage);