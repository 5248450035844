import React, { useState, useCallback, useEffect } from "react";
import { Button } from "../../components/atoms/Button";
import { deleteList } from "../../api/recruiter/lists";
import { DateTime } from 'luxon';
import SvgMoreVertical from "../../components/icons/SvgMoreVertical";
import GoBack from "../../components/atoms/GoBack";
import SmartListModal from "../modals/SmartListModal";
import SvgStars from "../../components/icons/SvgStars";
import { NUM_STUDENTS_SHOWN } from "../../utils/constants";
import { downloadAllResumes } from "../../api/student/clubs";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { TOAST_OPTIONS } from "../../utils/constants";
import RecruiterDashboardTable from "../dashboard/RecruiterDashboardTable";
import { SAVED_LISTS_TABLE_HEADERS } from "../../utils/dummy";
import RecruiterFavoritesTableItem from "../RecruiterFavoritesTableItem";
import { searchUsersFromSmartList } from "../../utils/smartListUtils";
import FiltersSidebar from "../atoms/FiltersSidebar";
import JobBoardLoading from "../../components/loading/JobBoardLoading";
import ListHeaderActions from "../atoms/ListHeaderActions";
import { CSV_DOWNLOAD_PAGE_LIMIT, RESUME_DOWNLOAD_PAGE_LIMIT } from "../../utils/constants";
import { downloadStudentsCSV } from '../../utils/csv';
import { SMART_LIST_EMAIL_BLAST_LIMIT } from "../../utils/constants";
import { getClub } from "../../api/recruiter/clubs";

function RecruiterSmartFullListPage({
    list,
    type,
    refreshList,
    authUser,
    history,
    tier,
    recruiterCompany,
    constants
}) {
    const [isEditListModalOpen, setIsEditListModalOpen] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [hideMoreStudents, setHideMoreStudents] = useState(false);
    const [moreStudentsLoading, setMoreStudentsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [listData, setListData] = useState(list);
    const [isLoading, setIsLoading] = useState(!list.users);
    const [isUsersToEmailBlastLoading, setIsUsersToEmailBlastLoading] = useState(false);
    const [clubNames, setClubNames] = useState([]);

    const MAJOR_CATEGORIES = constants.major_categories;

    const delList = async () => {
        await deleteList(authUser, listData.id, type);
        history.push('/saved-lists');
    };


    const fetchUserSearch = useCallback(async (query, currentList = listData) => {
        setMoreStudentsLoading(true);
        const page = 1;
        setPage(page);
    
        try {
            const result = await searchUsersFromSmartList({
                user: authUser,
                list: currentList,
                name: query,
                page: page,
                tier
            });
            
            setListData(prevData => ({
                ...prevData,
                users: result.students,
                total: result.total
            }));
            
            setHideMoreStudents(result.students.length < NUM_STUDENTS_SHOWN);
        } catch (error) {
            console.error("Error fetching users:", error);
            setError(true);
        } finally {
            setMoreStudentsLoading(false);
            setIsLoading(false);
        }
    }, [authUser, tier]);

    useEffect(() => {
        setListData(list);
    }, [list]);

    useEffect(() => {
        if (listData && !listData.users) {
            fetchUserSearch("", listData);
        }
    }, [listData, fetchUserSearch]);

    const getNextPageInfo = useCallback(async () => {
        setMoreStudentsLoading(true);
        const newUsers = await searchUsersFromSmartList({
            user: authUser,
            list: listData,
            name: search,
            page: page + 1,
            tier
        });
        
        setListData(prevData => ({
            ...prevData,
            users: [...(prevData.users || []), ...newUsers.students]
        }));
        
        setPage(prevPage => prevPage + 1);
        setMoreStudentsLoading(false);
        setHideMoreStudents(newUsers.students.length < NUM_STUDENTS_SHOWN);
    }, [search, page, listData, authUser, tier]);

    const downloadCsv = async () => {
        const usersFetched = await searchUsersFromSmartList({
            user: authUser,
            list: listData,
            name: search,
            page: page,
            tier,
            count: CSV_DOWNLOAD_PAGE_LIMIT
        });

        const usersToDownload = usersFetched.students;
        
        downloadStudentsCSV(usersToDownload, listData.name, { companyId: recruiterCompany.id });
    };

    const handleDownloadResumes = async () => {
        try {
            const newUsers = await searchUsersFromSmartList({
                user: authUser,
                list: listData,
                name: search,
                page: page,
                tier,
                count: RESUME_DOWNLOAD_PAGE_LIMIT
            });
    
            const resumePromise = downloadAllResumes(authUser, newUsers.students);
            toast.promise(
                resumePromise,
                {
                    pending: "Downloading resumes...",
                    success: "Resumes downloaded successfully!",
                    error: "No resumes available for these filters",
                },
                TOAST_OPTIONS
            );
            
            const resumeResult = await resumePromise;
            if (!resumeResult) {
                setError(true);
            } else {
                setError(false);
            }
        } catch (error) {
            console.log("Error no resumes this year");
        }
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        fetchUserSearch(value);
    };

    const getMajorCategories = () => {
        const categoryCounts = {};
        if (listData.majors) {
            listData.majors.forEach(major => {
                // Find which category this major belongs to
                for (const [category, majors] of Object.entries(MAJOR_CATEGORIES)) {
                    if (majors.includes(major)) {
                        categoryCounts[category] = (categoryCounts[category] || 0) + 1;
                        break;
                    }
                }
            });
        }
        return categoryCounts;
    };

    // Fetch club names when clubs are present in the list data
    useEffect(() => {
        const fetchClubNames = async () => {
            if (listData?.clubs && listData.clubs.length > 0) {
                try {
                    // Use Promise.all to fetch all club details in parallel
                    const clubPromises = listData.clubs.map(clubId => getClub(authUser, clubId));
                    const clubsData = await Promise.all(clubPromises);
                    
                    // Extract club names from the fetched data
                    const names = clubsData
                        .filter(club => club) // Filter out any null results
                        .map(({club}) => club.name);
                    
                    setClubNames(names);
                } catch (error) {
                    console.error("Error fetching club names:", error);
                }
            }
        };
        
        fetchClubNames();
    }, [listData?.clubs, authUser]);

    if (isLoading) {
        return <JobBoardLoading title="Loading smart list" page="smart-list" />;
    }

    const listCreation = DateTime.fromISO(listData.created_at || '');
    const colleges = listData?.colleges || [];

    const handleEmailBlast = async (setUsers) => {
        setIsUsersToEmailBlastLoading(true);
        const usersToEmailBlast = await searchUsersFromSmartList({
            user: authUser,
            list: listData,
            name: search,
            count: SMART_LIST_EMAIL_BLAST_LIMIT
        });
        setIsUsersToEmailBlastLoading(false);
        return usersToEmailBlast.students;
    }

    return (
        <div className={`flex flex-col flex-1 overflow-hidden`}>
            <div className='flex flex-row items-end justify-between p-[12px] pb-0'>
                <div>
                    <GoBack/>
                    <div className='flex flex-row items-center mt-1 gap-2 mb-2'>
                        <p className='text-slate-800 text-2xl font-semibold'>
                            {listData.name}
                            <span className='text-slate-500'>
                                {` (${listData.total || 0} students)`}
                            </span>
                        </p>
                        <SvgStars className={'text-primary'}/>
                    </div>
                </div>
                <ListHeaderActions 
                    users={listData.users}
                    loadingUsers={isUsersToEmailBlastLoading}
                    onDownloadCsv={downloadCsv}
                    onDownloadResumes={handleDownloadResumes}
                    onEditList={() => setIsEditListModalOpen(true)}
                    onDeleteList={() => setConfirmationModalOpen(true)}
                    onEmailBlast={handleEmailBlast}
                />
            </div>
            <div className={'flex flex-row flex-wrap px-[12px] gap-3 opacity-70 mt-2'}>
                <div className='flex flex-row items-end'>
                    <p className='text-slate-800 text-sm'>
                        <strong>Created:</strong> {listCreation.toLocaleString(DateTime.DATETIME_MED)} {!!listData.creator_name && ` by ${listData.creator_name}`}
                    </p>
                </div>
                {colleges.length ?
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Colleges:</strong> {listData.colleges.join(", ")}
                        </p>
                    </div>
                    : null
                }
                {listData.grades?.length > 0 && (
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Class Years:</strong> {listData.grades.join(", ")}
                        </p>
                    </div>
                )}
                {listData.majors?.length > 0 && (
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Majors:</strong> {
                                Object.entries(getMajorCategories())
                                    .map(([category, count]) => `${category} (${count})`)
                                    .join(", ")
                            }
                        </p>
                    </div>
                )}
                {listData.current_companies?.length > 0 && (
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Current Companies:</strong> {listData.current_companies.join(", ")}
                        </p>
                    </div>
                )}
                {listData.previous_companies?.length > 0 && (
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Previous Companies:</strong> {listData.previous_companies.join(", ")}
                        </p>
                    </div>
                )}
                {listData.verticals?.length > 0 && (
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Current Verticals:</strong> {listData.verticals.join(", ")}
                        </p>
                    </div>
                )}
                {listData.previous_verticals?.length > 0 && (
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Previous Verticals:</strong> {listData.previous_verticals.join(", ")}
                        </p>
                    </div>
                )}
                {listData.gpa && (
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Min GPA:</strong> {listData.gpa}
                        </p>
                    </div>
                )}
                {listData.industries?.length > 0 && (
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Industries:</strong> {listData.industries.join(", ")}
                        </p>
                    </div>
                )}
                {listData.clubs?.length > 0 && clubNames.length > 0 && (
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Clubs:</strong> {clubNames.join(", ")}
                        </p>
                    </div>
                )}
                {listData.graduation_season && (
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Graduation Season:</strong> {listData.graduation_season}
                        </p>
                    </div>
                )}
                {listData.race?.length > 0 && (
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Race:</strong> {listData.race.join(", ")}
                        </p>
                    </div>
                )}
                {listData.gender && (
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Gender:</strong> {listData.gender}
                        </p>
                    </div>
                )}
                {(listData.veteran || listData.lgbtq || listData.disabled || listData.authorized || listData.is_ib || 
                  listData.first_generation || listData.require_sponsorship) && (
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Filters:</strong> {[
                                listData.veteran && "Veteran",
                                listData.lgbtq && "LGBTQ+",
                                listData.disabled && "Disability",
                                listData.authorized && "Work Authorized",
                                listData.is_ib && "Investment Banking",
                                listData.first_generation && "First Generation",
                                listData.require_sponsorship && "Requires Sponsorship"
                            ].filter(Boolean).join(", ")}
                        </p>
                    </div>
                )}
            </div>
            <div className={'flex flex-1 flex-col overflow-hidden px-[12px] pb-[12px]'}>
                <div className='flex-1 flex flex-col overflow-hidden'>
                    <div className='flex-1 flex flex-row gap-3 overflow-hidden'>
                        <div className={'w-[250px] flex flex-col'}>
                            <FiltersSidebar
                                search={{
                                    placeholder: 'Search by name...',
                                    value: search,
                                    onChange: handleSearchChange,
                                }}
                            />
                        </div>
                        <div className='flex flex-col overflow-hidden flex-1'>
                            <div className={' py-2 text-lg font-semibold'}>
                                {listData.total || 0} students
                            </div>
                            <RecruiterDashboardTable
                                columns={SAVED_LISTS_TABLE_HEADERS}
                                TableBody={
                                    <div className='flex flex-col gap-4 p-5 flex-1 overflow-y-scroll'>
                                        {(listData.users?.length ?? 0) === 0 ?
                                            <div className='flex flex-col items-center py-12 gap-3'>
                                                <p className='text-slate-500'>
                                                    No students match your search criteria
                                                </p>
                                                <Button onClick={() => setIsEditListModalOpen(true)}>
                                                    Edit list filters
                                                </Button>
                                            </div>
                                            :
                                            listData.users.map(item =>
                                                <div key={item.id}>
                                                    <RecruiterFavoritesTableItem
                                                        item={item}
                                                        columns={SAVED_LISTS_TABLE_HEADERS}
                                                        list={listData}
                                                        type={type}
                                                    />
                                                </div>
                                            )
                                        }
                                        {(!hideMoreStudents && listData.users?.length !== 0) &&
                                            <div
                                                className='flex flex-row items-center justify-center mr-3 py-8 mb-20 body2-bold'
                                                onClick={getNextPageInfo}>
                                                <Button loading={moreStudentsLoading} variant={'ghost'}>
                                                    Next page
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <SmartListModal
                isOpen={isEditListModalOpen}
                initialData={listData}
                constants={constants}
                authUser={authUser}
                closeModal={() => setIsEditListModalOpen(false)}
                mode='edit'
                onSave={() => {
                    setIsEditListModalOpen(false);
                    refreshList();
                }}
            />
            <ConfirmationModal
                isOpen={confirmationModalOpen}
                closeModal={() => setConfirmationModalOpen(false)}
                confirmText={'Delete'}
                title={'Are you sure?'}
                onConfirm={delList}
                description={'Are you sure you want to delete this list? This action cannot be undone.'}
            />
        </div>
    );
}

export default RecruiterSmartFullListPage; 