import {connect} from "react-redux";
import Spinner from "../../components/Spinner";
import VerticalTabs from "../../components-recruiter/atoms/VerticalTabs";
import {Button} from "../../components/atoms/Button";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import WorkspaceMemberItem from "../../components/atoms/WorkspaceMemberItem";
import { getCollege } from "../../api/clubAdmin/user";
import { useEffect, useState } from "react";
import { getClubAdminInfo, createNewAdmin, editClub } from "../../api/clubAdmin/clubs";
import Checkbox from "../../components/atoms/Checkbox";
import {removeClubAdmin} from "../../api/student/admin";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    };
}

const ClubAdminSettingsPage = ({authUser, getClubInfo, club}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [admin, setAdmin] = useState([]);
    const [newEmail, setNewEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [college, setCollege] = useState(null);

    async function fetchData() {
        setIsLoading(true);
        const {college} = await getCollege(authUser, club.college);
        setCollege(!!college.length && college[0].name !== "Multiple Universities" ? college[0] : {domain: "", name: "Multiple Universities"});

        const res = await getClubAdminInfo(authUser, club.id);
        setAdmin(res.admins);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();

        
    }, []);

    const addAdmin = async () => {
        setIsLoading(true);
        // const email = college.domain ? newEmail + "@" + college.domain : newEmail;
        const res = await createNewAdmin(authUser, club.id, newEmail.toLowerCase());
        setNewEmail('');
        await fetchData();
    }

    const removeAdmin = async (admin) => {
        setIsLoading(true);
        await removeClubAdmin(authUser, club.id, admin.id)
        await fetchData()
    }

    const handleInputChange = (value) => {
        setNewEmail(value);
    }

    const toggleCheckbox = async () => {
        setIsLoading(true);
        await editClub(authUser, club.id, {private: !club.private});
        getClubInfo();
        setIsLoading(false);
    }

    const addRemovePoc = async (member) => {
        const pocEmails = club.points_of_contact.map(poc => poc.email);
        const pocs = [...club.points_of_contact];

        if (pocEmails.includes(member.email)) {
            const index = pocEmails.indexOf(member.email);
            pocs.splice(index, 1);
        } else {
            pocs.push({
                email: member.email,
                name: `${member.first_name} ${member.last_name}`,
                username: member.username,
                admin: club?.admin.includes(member.username)
            })
        }
        
        await editClub(authUser, club.id, {points_of_contact: pocs});
        fetchData();
    }

    if (isLoading || !college) {
        return (
          <div className="mt-48 h-screen w-screen flex flex-col items-center justify-center">
            <Spinner size={24} />
          </div>
        );
      }

    return (
        <div className={`flex flex-col flex-1 p-5 gap-8`}>
            <div className='pt-12 w-full max-w-[800px] mx-auto'>
                <div className={`flex flex-row min-h-[80vh]`}>
                    <div className='flex flex-col flex-1 gap-8 min-h-[80vh] overflow-y-scroll items-start pb-32'>
                        <div className='flex flex-col gap-3 w-full '>
                            <p className='text-xl text-gray-900 mb-2 font-semibold'>
                                Club Details
                            </p>
                            <div className='flex flex-col border-t border-neutral-200 pt-5 gap-5'>
                                <WrappedTextInput
                                    outerLabel={'College'}
                                    value={college.name}
                                    placeholder={'Your college name'}
                                    className='input-light px-3 w-full'
                                    onChange={(e) => this.handleInputChange('xxxx', e.target.value)}
                                    light
                                    disabled
                                />
                                <WrappedTextInput
                                    outerLabel={'Domain'}
                                    value={college.domain}
                                    placeholder={'No common domain'}
                                    className='input-light px-3 w-full'
                                    light
                                    disabled
                                />
                                {/* <Button variant={''}
                                        className='mt-4 mr-auto'
                                        disabled={!isWorkspaceDataChanged}
                                        onClick={this.handleWorkspaceSave}>
                                    Save
                                </Button> */}
                                <div className='flex flex-row items-center flex-[.5]'>
                                    <Checkbox
                                        checked={club.private}
                                        onClick={toggleCheckbox}
                                        label={'Private'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-1 w-full mt-8 '>
                            <p className='text-lg text-gray-900 font-semibold'>
                                Club Admin
                            </p>
                            <p className='text-xs text-gray-700 mb-4 mt-[-4]'>
                                Anyone who is added as a club admin will have access to the admin portal.  We recommend all club execs to be added as an admin.
                            </p>
                            <div
                                className='flex flex-col bg-white border border-neutral-100 rounded-xl p-3 gap-3'>
                                {admin.length === 0 &&
                                    <p className='text-sm text-slate-500 text-center my-3'>
                                        No other admins in this workspace yet
                                    </p>
                                }
                                {admin.map((member, index) =>
                                    <WorkspaceMemberItem key={`member${index}`} member={member} addRemovePoc={addRemovePoc} pocEmails={club.points_of_contact.map(poc => poc.email)}
                                                            authUser={authUser} removeAdmin={removeAdmin} hideDate/>
                                )}
                            </div>
                        </div>



                        <div className='flex flex-col gap-1 w-full mt-8 md:w-3/2'>
                            <p className='text-lg text-gray-900 mb-2 font-semibold'>
                                Add Admin
                            </p>
                            <div className='flex flex-row items-center gap-2'>
                                <WrappedTextInput
                                    outerLabel={'Admin email'}
                                    value={newEmail}
                                    placeholder={`e.g. john@${!!college.domain ? college.domain : 'michigan.edu'}`}
                                    className='input-light px-3 w-full'
                                    onChange={(e) => handleInputChange(e.target.value)}
                                    light
                                />
                                {/* {!!college.domain &&
                                    <p className='text-sm text-slate-500 mt-5'>
                                        {"@ " + college.domain}
                                    </p>
                                } */}
                            </div>
                            <p className='text-sm' style={{color: "red"}}>
                                {errorMessage}
                            </p>
                            <Button variant={''}
                                    className='mt-5 mr-auto'
                                    disabled={!newEmail}
                                    onClick={addAdmin}>
                                Update
                            </Button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}

export default connect(mapStateToProps)(ClubAdminSettingsPage);
