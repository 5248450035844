import React, { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { toast } from 'react-toastify';

const Dictaphone = ({isRecording, setTranscript}) => {
    const {
      transcript,
      interimTranscript,
      finalTranscript,
      resetTranscript,
      listening,
      browserSupportsSpeechRecognition,
    } = useSpeechRecognition();

    useEffect(() => {
        if (!browserSupportsSpeechRecognition) {
            toast.error('Your browser does not support this feature. Please try a different browser.');
        }
    }, [browserSupportsSpeechRecognition]);

    const listenContinuously = () => {
        SpeechRecognition.startListening({
          continuous: true,
          language: 'en-GB',
        
        });
    }

    useEffect(() => {
        if (isRecording) {
          listenContinuously();
        } else {
            SpeechRecognition.stopListening();
            setTranscript(transcript);
            resetTranscript();
        }
      }, [isRecording]);
    
    return (
      <div>
      </div>
    );
   };

export default Dictaphone;