import React, {Component} from "react";
import CompanyLogo from "../atoms/CompanyLogo";
import {truncateStringFullWord} from "../../utils/strings";
import SvgCalendar from "../icons/SvgCalendar";
import TagComponent from "../atoms/TagComponent";
import {DateTime} from 'luxon';
import Tooltip from "../atoms/modals/Tooltip";
import {editTracker} from "../../api/student/trackers";
import RuDirectLabel from "../atoms/RuDirectLabel";
import RsvpNowButton from "../RsvpNowButton";
import Checkbox from "../atoms/Checkbox";
import {Button} from "../atoms/Button";
import SvgCheckCircle from "../icons/SvgCheckCircle";
import {connect} from "react-redux";
import CompanyLabel from "../CompanyLabel";
import SvgDocument from "../icons/SvgDocument";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

const TAGS = {
    1: "Recommended for your club",
    2: "Exclusive to your club"
}

class TrackedEventItem extends Component {
    toggleCheckbox = async () => {
        const newTracker = {...this.props.tracker};
        const newStatus = !newTracker.status
        newTracker.status = newStatus
        this.props.updateTracker(newTracker);
        await editTracker(this.props.authUser, newTracker.id, {status: newStatus});
    }

    render() {
        const event = this.props.item || {};
        const deadline = DateTime.fromISO(event.event_date);
        const classYears = event.class_years || [];
        classYears.sort();
        let yearText = '';

        if (classYears.length === 1) {
            yearText = `'${classYears[0].toString().slice(-2)}`;
        } else if (classYears.length > 1) {
            const minYear = classYears[0].toString().slice(-2);
            const maxYear = classYears[classYears.length - 1].toString().slice(-2);
            yearText = `'${minYear} - '${maxYear}`;
        }
        return (
            <div onClick={() => {
                if (!event.deprecated) this.props.openEventPage(event)
            }}
                 className={`${event.deprecated ? "opacity-50" : ""} mb-0 w-full border-b py-3 border-slate-200 flex flex-row justify-between cursor-pointer`}
            >
                {/*{event.exclusive && !this.props.inPost &&*/}
                {/*    <RuDirectLabel className={'ml-auto mb-2'} label={"RecruitU Partner"}/>*/}
                {/*}*/}
                <div className='w-full flex flex-row flex-[2] items-center gap-3'>
                    <div className='w-20 h-20'>
                        <CompanyLogo id={event.company_id} className={'h-20 h-20'}/>
                    </div>
                    <div className='flex flex-col mr-5 flex-1'>
                        <div className='flex flex-row items-center gap-3'>
                            {!!this.props.tag ?
                                <div className={this.props.grid && 'absolute top-0 right-0'}>
                                    <div
                                        className='flex flex-col items-center rounded-lg border-slate-200 border'>
                                        <TagComponent
                                            label={TAGS[this.props.tag]}
                                            type={'reason'}
                                        />
                                    </div>
                                </div>
                                : null}
                        </div>
                        <p className='text-md font-semibold'>
                            {event.name}
                        </p>
                        <div className='mr-a mt-1 flex flex-row items-center gap-2 wrap'>
                            {/*<TagComponent label={'Class of 2024'}/>*/}
                            <TagComponent label={event.type || 'Early Insights Program'}/>
                            <TagComponent label={event.industry}/>
                            {!!event.deprecated &&
                                <Tooltip
                                    toggleComponent={
                                        <TagComponent label={'Expired'}/>
                                    }
                                    label={'This event has been removed from RecruitU. You can no longer navigate to the full event page.'}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-row items-center flex-[.5]'>
                    {!!yearText ?
                        <div className='flex flex-row text-primary items-center gap-1.5 mb-1'>
                            <SvgCalendar className={'w-3.5 h-3.5'}/>
                            <p className='text-sm font-semibold'>
                                {yearText}
                            </p>
                        </div>
                        : null}
                </div>
                <div className='flex flex-row items-center flex-[.5]'>
                    <p className='base-black-50 body3'>
                        {!!deadline.isValid ? deadline.toFormat('MMM d') : '--'}
                    </p>
                </div>
                <div className='flex flex-row text-sm items-center flex-[.75]'>
                    <CompanyLabel
                        hideLogo
                        companyId={event.company_id}
                        companyName={event.company_name}
                    />
                </div>
                {/* <div className='flex flex-row text-sm items-center flex-[.5]'>
                    <p className='body3 base-black-50'>
                        {event.exclusive ? event.company_name : !!event.creator ? "Personal" : "RecruitU"}
                    </p>
                </div> */}
                <div style={{flex: .5}} className='flex flex-row items-center gap-1 body3 base-black-50'>
                    <SvgDocument className={'w-4 h-4'}/>
                    {this.props.tracker?.notes.length || 0}
                </div>
                <div className='flex flex-row items-center flex-[.75]'>
                    <Checkbox
                        checked={this.props.tracker.status}
                        onClick={this.toggleCheckbox}
                        label={'Registered'}
                    />
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(TrackedEventItem)
