import React, { useState, useEffect } from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {wait} from "@testing-library/user-event/dist/utils";
import JobEventQualifications from "./create-event-job/JobEventQualifications";
import {editEvent} from "../../api/recruiter/events";
import CreateEventDetailsStep from "./create-event/CreateEventDetailsStep";
import CreateEventDateAndLocationStep from "./create-event/CreateEventDateAndLocationStep";
import SectionTabs from "../../components/atoms/SectionTabs";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import { EditorState } from "draft-js";
import {convertFromHtml, DefaultEditorState} from "../../utils/richEditor";
import { stateToHTML } from "draft-js-export-html";
import AlertModal from "../../components/atoms/modals/AlertModal";
import { sanitizeHtml } from "../../utils/strings";
import { useQualifiedOnly } from "../../contexts/QualifiedOnlyContext";
import { getQualificationsParams } from "../../utils/getQualificationsParams";
const ZERO_STATE = {
    step: 1,
    eventTitle: '',
    eventDescription: DefaultEditorState,
    industry: null,
    city: '',
    state: '',
    date: null,
    qualifiedOnly: false,
    registerOnRecruit: true,
    startTime: '10:00',
    endTime: '11:00',
    virtual: false,
    meetingLink: '',
    activeTab: 'Event Details',
    role: '',
    rsvpDate: null,
    hasRsvpDate: false,
    scope: "public",
};

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {function} props.closeModal
 * @param {object} props.event
 * @param {object} props.authUser
 * @param {object} props.constants
 */
const RecruiterEditEventModal = (props) => {
    const [eventState, setEventState] = useState(ZERO_STATE);
    const { filters, selectionMode, lists, initQualifiedOnlyState } = useQualifiedOnly();

    const submit = async () => {
        const industry = eventState.role?.endsWith('Consulting') ? 'Consulting' : 'Finance';
        const params = {
            name: eventState.eventTitle,
            description: sanitizeHtml(stateToHTML(eventState.eventDescription.getCurrentContent())),
            location: eventState.virtual ? "Remote" : "In-Person",
            event_date: eventState.date,
            start_time: eventState.startTime,
            end_time: eventState.endTime,
            city: !!eventState.city ? [eventState.city] : [],
            state: !!eventState.state ? [eventState.state] : [],
            link: eventState.meetingLink,
            industry,
            qualified_only: eventState.qualifiedOnly,
            register_direct: eventState.registerOnRecruit,
            rsvp_date: eventState.hasRsvpDate && eventState.rsvpDate ? DateTime.fromJSDate(eventState.rsvpDate).setZone('utc', {keepLocalTime: true}).toISO().replace('Z', '') : "",
            secondary_specialties: [eventState.role],
            specialties: [industry],
            ...getQualificationsParams({ filters, lists, selectionMode, qualifiedOnly: eventState.qualifiedOnly}),
        }
        toast.promise(editEvent(props.authUser, props.event.id, params).then(() => {
            window.location.reload();
        }), {
            pending: "Saving changes...",
            success: "Changes saved successfully!",
            error: "Failed to save changes."
        });
    }

    const setInfo = () => {
        const event = props.event || {};
        setEventState({
            ...ZERO_STATE,
            eventTitle: event.name,
            eventDescription: EditorState.createWithContent(convertFromHtml(event.description)),
            eventType: event.type,
            city: !!event.city ? event.city[0] : "",
            state: !!event.state ? event.state[0] : "",
            workEnvironment: event.work_model,
            meetingLink: event.link,
            role: !!event.secondary_specialties ? event.secondary_specialties[0] : "",
            date: !!event.event_date ? new Date(event.event_date) : null,
            startTime: event.start_time,
            endTime: event.end_time,
            virtual: event.location === "Remote",
            qualifiedOnly: event.qualified_only,
            registerOnRecruit: event.register_direct,
            hasRsvpDate: !!event.rsvp_date,
            rsvpDate: !!event.rsvp_date ? new Date(event.rsvp_date) : null,
            scope: event.scope,
        });
        initQualifiedOnlyState(event)
    }

    useEffect(() => {
        setInfo();
    }, []);

    useEffect(() => {
        if (props.isOpen) {
            wait(10).then(() => {
                setInfo();
            });
        }
    }, [props.isOpen]);

    const handleInputChange = (e, inputName) => {
        setEventState(prev => ({...prev, [inputName]: e.target.value}));
    };

    const handleSelectChange = (inputName, value) => {
        setEventState(prev => ({...prev, [inputName]: value}));
    };


    const handleQualifiedOnlyChange = (boolean) => {
        setEventState(prev => ({...prev, qualifiedOnly: boolean}));
    };

    const toggleInput = (stateKey) => {
        setEventState(prev => ({...prev, [stateKey]: !prev[stateKey]}));
    }

    const handleDateChange = (e, stateKey) => {
        setEventState(prev => ({...prev, [stateKey]: e}));
    };

    const handleTimeChange = (e, stateKey) => {
        setEventState(prev => ({...prev, [stateKey]: e}));
    };

    const setEditorState = (editorState) => {
        setEventState(prev => ({...prev, eventDescription: editorState}));
    }

    const handleQualificationCheckbox = (inputName) => {
        setEventState(prev => ({...prev, [inputName]: !prev[inputName]}));
    }

    const handleVirtualChange = (value) => {
        setEventState(prev => ({...prev, virtual: value}));
    };

    const changeTab = (tab) => {
        setEventState(prev => ({...prev, activeTab: tab}));
    };

    const archive = async () => {
        const params = {
            scope: "expired"
        }
        toast.promise(editEvent(props.authUser, props.event.id, params).then(() => {
            window.location.reload();
        }), {
            pending: "Archiving event...",
            success: "Event archived successfully!",
            error: "Failed to archive event."
        });
    }

    const unarchive = async () => {
        const params = {
            scope: "public"
        }
        toast.promise(editEvent(props.authUser, props.event.id, params).then(() => {
            window.location.reload();
        }), {
            pending: "Unarchiving event...",
            success: "Event unarchived successfully!",
            error: "Failed to unarchive event."
        });
    }

    const TABS = ['Event Details', 'Date and Location', 'Attendees'];
    
    return (
        <ModalComponent
            isOpen={props.isOpen}
            backgroundColor={'white'}
            header={'Edit This Event'}
            headerToggle
            size={'lg'}
            toggle={props.closeModal}
            FooterComponent={
                <div className='flex flex-row items-between gap-3'>
                    <div onClick={props.closeModal} className='secondary-button body1-bold'>
                        {"Cancel"}
                    </div>
                    <AlertModal
                        title={eventState.scope === 'expired' ? 'Unarchive Event' : 'Archive Event'}
                        description={eventState.scope === 'expired' ? 'Are you sure you want to unarchive this event?' : 'Are you sure you want to archive this event?'}
                        onConfirm={eventState.scope === 'expired' ? unarchive : archive}
                    >
                        <div onClick={eventState.scope === 'expired' ? unarchive : archive} className='secondary-button body1-bold'>
                            {eventState.scope === 'expired' ? 'Unarchive' : 'Archive'}
                        </div>
                    </AlertModal>
                    <div onClick={submit} className='primary-button body1-bold'>
                        {"Submit"}
                    </div>
                </div>
            }
            footerAlignment={'right'}
            showScrollBar
        >
            <div id={'add-joblisting-modal'}>
                <div className='mb-5'>
                    <SectionTabs
                        activeTab={eventState.activeTab}
                        setActiveTab={changeTab}
                        tabs={TABS}
                    />
                </div>
                {eventState.activeTab === TABS[0] &&
                    <CreateEventDetailsStep
                        hideTutorial
                        eventTitle={eventState.eventTitle}
                        eventDescription={eventState.eventDescription}
                        role={eventState.role}
                        handleInputChange={handleInputChange}
                        handleSelectChange={handleSelectChange}
                        constants={props.constants}
                        setEditorState={setEditorState}
                        editorState={eventState.eventDescription}
                    />
                }
                {eventState.activeTab === TABS[1] &&
                    <CreateEventDateAndLocationStep
                        hideTutorial
                        date={eventState.date}
                        handleDateChange={handleDateChange}
                        startTime={eventState.startTime}
                        endTime={eventState.endTime}
                        handleTimeChange={handleTimeChange}
                        virtual={eventState.virtual}
                        meetingLink={eventState.meetingLink}
                        state={eventState.state}
                        city={eventState.city}
                        registerOnRecruit={eventState.registerOnRecruit}
                        toggleInput={toggleInput}
                        handleVirtualChange={handleVirtualChange}
                        handleInputChange={handleInputChange}
                        handleSelectChange={handleSelectChange}
                        hasRsvpDate={eventState.hasRsvpDate}
                        rsvpDate={eventState.rsvpDate}
                    />
                }
                {eventState.activeTab === TABS[2] &&
                    <JobEventQualifications
                        hideTutorial
                        qualifiedOnly={eventState.qualifiedOnly}
                        handleQualifiedOnlyChange={handleQualifiedOnlyChange}
                        event
                        handleQualificationCheckbox={handleQualificationCheckbox}
                        editing
                    />
                }
            </div>
        </ModalComponent>
    );
}

export default RecruiterEditEventModal;
