import React, {PureComponent} from "react";
import SvgSearch from "../icons/SvgSearch";
import {connect} from "react-redux";
import SvgClose from "../icons/SvgClose";
import {filteredPersonSearch} from "../../utils/typesense";
import JobBoardLoading from "../loading/JobBoardLoading";
import PageHeaderWrapper from "../wrappers/PageHeaderWrapper";
import DiscoverPersonItem from "./DiscoverPersonItem";
import {Button} from "../atoms/Button";
import NetworkingPeek from "../networkingpage/NetworkingPeek";
import {NUM_PEOPLE_SHOWN, TOAST_OPTIONS} from "../../utils/constants";
import {toast} from "react-toastify";
import FadeInOnScroll from "../atoms/FadeInOnScroll";
import ScrapedNetworkingPeek from "../networkingpage/ScrapedNetworkingPeek";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        applications: state.userReducer.applications,
        networks: state.userReducer.networks
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateNetwork: (network) => {
            dispatch({
                type: "UPDATE_NETWORK",
                network,
            })
        },
        deleteNetwork: (personId) => {
            dispatch({
                type: "DELETE_NETWORK",
                personId,
            })
        }
    }
}

class DiscoverPeople extends PureComponent {
    constructor(props) {
        super(props);
        this.headingRef = React.createRef(); // Create a ref to access the component's DOM node
        this.state = {
            search: '',
            people: [],
            grade: null,
            companyId: null,
            companyName: null,
            school: null,
            peopleLoading: false,
            morePeopleLoading: false,
            grid: false,
            page: 1,
            hideMorePeople: false,
            industry: [],
            networkingPeekOpen: false,
            selectedPersonId: "",
            selectedPerson:{},
            position: [],
        };
    }

    openPeek = (personId, person) => {
        this.setState({selectedPersonId: personId,  selectedPerson:person,  networkingPeekOpen: true})
    }

    closePeek = () => {
        this.setState({networkingPeekOpen: false, scrapedNetworkingPeekOpen: false});
    }

    handleSearchChange = (event) => {
        const text = event.target.value;
        this.setState({search: text});

        this.search(text);
    }

    componentDidMount = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const companyId = queryParams.get("companyId")
        const companyName = queryParams.get("companyName")
        if (!!companyId) {
            this.setState({companyId, companyName})
            this.search("", null, [], companyId);
        } else if (!!this.props.user.college) {
            const numResults = await this.search("", null, [], null, this.props.user.college);
            if (!numResults) {
                this.search("");
            }
        } else {
            this.search("");
        }
    }


    search = async (text, grade = null, industry = [], companyId = this.state.companyId, school = this.state.school, position = this.state.position) => {
        const personResults = await filteredPersonSearch(text, [], 1, grade, industry, "apollo", companyId, school, position)
        this.setState({
            people: personResults.results,
            loading: false,
            hideMorePeople: personResults.results.length < NUM_PEOPLE_SHOWN,
        });
        return personResults.results.length
    }

    getNextPeopleInfo = async () => {
        this.setState({morePeopleLoading: true});
        const newPeople = await filteredPersonSearch(this.state.search, [], this.state.page + 1, this.state.grade, this.state.industry, "apollo", this.state.companyId, this.state.school);
        this.setState({
            people: [...this.state.people, ...newPeople.results],
            morePeopleLoading: false,
            page: this.state.page + 1,
            hideMorePeople: newPeople.results.length < NUM_PEOPLE_SHOWN,
        });
    }

    updateIndustry = (selectedIndustry) => {
        const industryArray = [...this.state.industry];

        let updatedIndustry;
        if (industryArray.includes(selectedIndustry)) {
            updatedIndustry = industryArray.filter((item) => item !== selectedIndustry);
        } else if (selectedIndustry === null) {
            updatedIndustry = [];
        } else {
            updatedIndustry = [...industryArray, selectedIndustry]
        }

        this.setState({industry: updatedIndustry, page: updatedIndustry.length === 0 ? 1 : this.state.page});
        this.search(this.state.search, null, updatedIndustry);
    };

    updatePosition = (selectedPosition) => {
        const industryArray = [...this.state.position];

        let updatedPosition;
        if (industryArray.includes(selectedPosition)) {
            updatedPosition = industryArray.filter((item) => item !== selectedPosition);
        } else if (selectedPosition === null) {
            updatedPosition = [];
        } else {
            updatedPosition = [...industryArray, selectedPosition]
        }

        this.setState({position: updatedPosition, page: updatedPosition.length === 0 ? 1 : this.state.page});
        this.search(this.state.search, null, this.state.industry, this.state.companyId, this.state.school, updatedPosition);
    };

    clearFilters = () => {
        this.setState({
            location: null,
            industry: [],
            companyId: null,
            companyName: null,
            school: null,
            page: 1
        });
        this.setState({people: this.state.originalEvents});
    }

    setCompany = (result) => {
        if (!result.id) {
            this.setState({page: 1})
        }
        this.setState({
            companyId: result.id,
            companyName: result.name,
        })
        this.search(this.state.search, this.state.grade, this.state.industry, result.id);
    }

    setSchool = (result) => {
        if (!result) {
            this.setState({page: 1})
        }
        this.setState({
            school: result,
        })
        this.search(this.state.search, this.state.grade, this.state.industry, this.state.companyId, result);
    }

    render() {
        const clearFiltersVisible = this.state.location || this.state.industry?.length >= 1 || this.state.companyId;
        return (
            <>
                <PageHeaderWrapper
                    className={'pt-2'}
                    search={{
                        placeholder: 'Search people...',
                        value: this.state.search,
                        onChange: this.handleSearchChange,
                    }}
                    selects={[
                        // {
                        //     label: 'Industry',
                        //     value: this.state.industry,
                        //     setValue: this.updateIndustry,
                        //     options: [{title: "Investment Banking",}, {title: "Sales & Trading"}, {title: "Equity Research"}, {title: "Private Equity / Venture Capital",}, {title: "Asset Management",}, {title: "Management Consulting",}, {title: "Wealth Management",}, {title: "Quant",}, {title: "Other",},],
                        //     multiSelect: true,
                        // },
                        {
                            label: 'Position',
                            value: this.state.position,
                            setValue: this.updatePosition,
                            options: [{title: "Analyst"}, {title: "Associate",}, {title: "Vice President",}, {title: "Managing Director",},],
                            multiSelect: true,
                        },
                    ]}
                    setCompany={this.setCompany}
                    setSchool={this.setSchool}
                    company={{id: this.state.companyId, name: this.state.companyName}}
                    clearFilters={clearFiltersVisible &&
                        <div
                            onClick={this.clearFilters}
                            className='body2 base-white-30 hover:text-neutral-50 flex flex-row items-center ml-3 cursor-pointer'>
                            <SvgClose className={'w-5 h-5 mr-1'}/> Clear
                        </div>

                    }
                />
                <div className='overflow-hidden flex-1'>
                    <div className={'overflow-y-scroll hide-scrollbar w-full flex-1 pb-5 px-5'}>
                        {this.state.peopleLoading ?
                            <JobBoardLoading/>
                            :
                            this.state.people.length === 0 ?
                                <div className='my-8 flex text-neutral-400 flex-col items-center justify-center flex-1'>
                                    <SvgSearch/>
                                    <p className='body2 mt-2 mb-6'>
                                        No people found...
                                    </p>
                                </div>
                                :
                                <div className={`flex flex-col ${this.state.grid && 'grid grid-cols-4 gap-4'}`}>
                                    {this.state.people.map((item, index) =>
                                        <div onClick={() => this.openPeek(item.id, item)}>
                                            <FadeInOnScroll key={item.id} triggerImmediately
                                                            delay={(index * 50) + 10}>
                                                <DiscoverPersonItem item={item} list={!this.state.grid} openPeek={this.openPeek}
                                                                    updateNetwork={this.props.updateNetwork}/>
                                            </FadeInOnScroll>
                                        </div>
                                    )}
                                </div>
                        }

                        {(!this.state.hideMorePeople && (!this.state.peopleLoading && this.state.people.length !== 0)) &&
                            <div className='flex flex-row items-center justify-center py-8 body2-bold'
                                 onClick={this.getNextPeopleInfo}>
                                <Button loading={this.state.morePeopleLoading} variant={'ghost'}>
                                    Load more networks
                                </Button>
                            </div>
                        }
                    </div>
                    <ScrapedNetworkingPeek
                        open={this.state.networkingPeekOpen}
                        network={this.props.networks[this.state.selectedPersonId]}
                        isSaved={(this.state.selectedPersonId in this.props.networks)}
                        person={this.state.selectedPerson}
                        close={this.closePeek}
                        deleteNetwork={this.props.deleteNetwork}
                        updateNetwork={this.props.updateNetwork}
                    />
                </div>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverPeople);
