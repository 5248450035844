import axios from '../axios';

export const getJob = async (job_id) => {
    try {
        const config = {
            headers: {mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {job_id}
        };
        const res = await axios.get("/jobs/public", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const editJob = async (user, job_id, params_to_edit) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit,
            job_id,
        }
        const res = await axios.put("/jobs", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const deleteJob = async (user, job_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {job_id}
        };
        const res = await axios.delete("/jobs", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getCompanyJobs = async (user, company_id) => {
 
    try {
        const config = {
            headers: {mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {company_id}
        };
        if (user) {
            const token = await user.getIdToken();
            config.headers.Authorization = `Bearer ${token}`;
        }
        const res = await axios.get("/jobs/company", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getRecommendedJobs = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/jobs/recommended", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const suggestJob = async (user, link) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const params = {
            link
        }
        const res = await axios.post("/jobs/suggest", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const createJob = async (user, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/admin/jobs", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const discoverJobs = async (user, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/jobs/discover", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}