import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Spinner from "../../components/Spinner";
import { Button } from "../../components/atoms/Button";
import SvgPlus from "../../components/icons/SvgPlus";
import { ClubAdminApplicationPreviewCard } from "../ClubAdminApplicationPreviewCard";
import { getApplicationsByClub } from "../../api/clubAdmin/applications";
import CreateApplicationModal from "../../components-club-admin/modals/CreateApplicationModal";
import { getClub } from "../../api/student/clubs";
import { getClubPosts } from "../../api/student/posts";
import {
  PencilSquareIcon,
  RectangleStackIcon,
} from "@heroicons/react/24/outline";
import EditPostModal from "../../components/atoms/modals/EditPostModal";
import ClubFeedTab from "../../components/clubpage/tabs/ClubFeedTab";
import CreateJobOrEventModal from "../modals/CreateJobOrEventModal";

function mapStateToProps(state) {
  return {
    authUser: state.userReducer.authUser,
    user: state.userReducer.user,
  };
}

const ClubAdminEngagePage = ({ club, authUser, user, history }) => {
  const [loading, setLoading] = useState(false);
  const [createApplicationModalOpen, setCreateApplicationModalOpen] =
    useState(false);
  const [applicationToEdit, setApplicationToEdit] = useState(null);
  const [clubData, setClubData] = useState(club);
  const [applications, setApplications] = useState([]);
  const [applicationQuestions, setApplicationQuestions] = useState([]);
  const [applicationQuestionsToEdit, setApplicationQuestionsToEdit] = useState(
    []
  );
  const [createPostModalOpen, setCreatePostModalOpen] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [editPostModalOpen, setEditPostModalOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [submissionCount, setSubmissionCount] = useState(0);
  const [tabs, setTabs] = useState([
    { name: "Jobs", alias: "jobs", count: 0 },
    { name: "Events", alias: "events", count: 0 },
    { name: "Applications", alias: "applicants", count: 0 },
  ]);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    const applicantTab = tabs.find((tab) => tab.alias === "applicants");
    applicantTab.count = submissionCount;
  }, [submissionCount]);

  useEffect(() => {
    setApplicationQuestionsToEdit(
      applicationQuestions.filter(
        (question) => question.application_id == applicationToEdit?.id
      )
    );
  }, [applicationToEdit]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!clubData?.id) {
        setLoading(false);
        console.log("empty id");
        return;
      }
      const { posts } = await getClubPosts(authUser, clubData.id);
      const jobData = posts.filter((post) => !!post.job_id);
      const eventData = posts.filter((post) => !post.job_id);

      const jobTab = tabs.find((tab) => tab.alias === "jobs");
      const eventTab = tabs.find((tab) => tab.alias === "events");
      jobTab.count = jobData.length;
      eventTab.count = eventData.length;

      setEvents(eventData);
      setJobs(jobData);
      const { applications, application_questions } =
        await getApplicationsByClub(authUser, clubData.id);
      const applicationTab = tabs.find((tab) => tab.alias === "applicants");
      applicationTab.count = applications.length;
      setApplicationQuestions(application_questions);
      setApplications(applications);
      setLoading(false);
    };
    setClubData(club);
    fetchData();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const activeTab = queryParams.get("activeTab");
    if (!!activeTab)
      changeTab(
        activeTab === "jobs"
          ? tabs[0]
          : activeTab === "events"
            ? tabs[1]
            : tabs[2]
      );
  }, []);

  const getClubInfo = async () => {
    setLoading(true);
    const { club } = await getClub(authUser, clubData.id);
    setClubData(club);
    setLoading(false);
  };

  const addApplication = (application) => {
    const idx = applications.findIndex((app) => app.id === application.id);

    const newApplications =
      idx !== -1
        ? applications.map((app, i) => (i === idx ? application : app))
        : [application, ...applications];

    setApplications(newApplications);
  };

  const openEventPage = (selectedEvent) => {
    history.push(`/event/${selectedEvent.id}`);
  };

  const openEditPostModal = (post) => {
    setCurrentPost(post);
    setEditPostModalOpen(true);
  };

  const changeTab = (tab) => {
    setActiveTab(tab);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("activeTab", tab.alias);

    const params = `activeTab=${tab.alias}`;
    const newUrl = `${window.location.pathname}?${params}`;
    history.replace(newUrl);
  };

  if (loading) {
    return (
      <div className="flex flex-col flex-1 w-full items-center justify-center h-screen">
        <Spinner size={24} />
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1 p-5 gap-2 max-w-4xl mx-auto w-full mt-2">
      <div className="flex flex-row items-center justify-between h-12">
        <div className="flex flex-row items-center gap-3">
          {tabs.map((tab) => (
            <div
              className={`${activeTab.alias === tab.alias ? "bg-primary" : "bg-slate-100 hover:bg-slate-200"} cursor-pointer px-4 py-2.5 rounded-lg`}
              key={tab.alias}
            >
              <div
                className={`flex flex-row items-center gap-1 ${activeTab.alias === tab.alias ? "text-white" : "text-neutral-700 hover:text-primary"}`}
              >
                <h3
                  onClick={() => changeTab(tab)}
                  className={`mt-0 text-sm font-semibold`}
                >
                  {tab.name} ({tab.count})
                </h3>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-row items-center gap-2">
          {activeTab.alias === "applicants" && (
            <Button
              variant={"default"}
              icon={SvgPlus}
              onClick={() => {
                
                setCreateApplicationModalOpen(true);
              }}
            >
              New Application
            </Button>
          )}
          {(activeTab.alias === "jobs" || activeTab.alias === "events") && (
            <Button
              variant={"default"}
              icon={SvgPlus}
              onClick={() => {
                
                setCreatePostModalOpen(true);
              }}
            >
              New {activeTab.alias === "jobs" ? "Job" : "Event"}
            </Button>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-3 w-full mx-auto">
        {activeTab.alias === "applicants" && (
          <div>
            {applications.length ? (
              <>
                {applications.map((application) => (
                  <ClubAdminApplicationPreviewCard
                    application={application}
                    setApplicationToEdit={setApplicationToEdit}
                    setCreateApplicationModalOpen={
                      setCreateApplicationModalOpen
                    }
                    user={user}
                    setSubmissionCount={setSubmissionCount}
                    application_questions={applicationQuestions.filter(
                      (question) => question.application_id === application.id
                    )}
                    authUser={authUser}
                    club_id={clubData.id}
                    application_id={application.id}
                    history={history}
                  />
                ))}
              </>
            ) : (
              <div className="text-center mt-48">
                <PencilSquareIcon className="mx-auto h-20 w-20 text-slate-400" />
                <h3 className="mt-2 text-lg font-semibold text-slate-900">
                  No application found
                </h3>
                <p className="mt-1 text-sm text-slate-500">
                  Allow students to apply to your club by creating an
                  application
                </p>
                <div className="mt-6">
                  <Button
                    variant={"default"}
                    icon={SvgPlus}
                    onClick={() => {
                      
                      setCreateApplicationModalOpen(true);
                    }}
                  >
                    New Application
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
        {activeTab.alias === "jobs" &&
          (jobs.length ? (
            <ClubFeedTab
              loading={loading}
              posts={jobs}
              club={clubData}
              isAdmin={true}
              getClubInfo={getClubInfo}
              openEventPage={openEventPage}
              editPost={openEditPostModal}
              containerClassName={"px-[0px]"}
            />
          ) : (
            <div className="text-center mt-48">
              <RectangleStackIcon className="mx-auto h-20 w-20 text-slate-400" />
              <h3 className="mt-2 text-lg font-semibold text-slate-900">
                No jobs found
              </h3>
              <p className="mt-1 text-sm text-slate-500">
                Start by creating a job posting
              </p>
              <div className="mt-6">
                <Button
                  variant={"default"}
                  icon={SvgPlus}
                  onClick={() => {
                    
                    setCreatePostModalOpen(true);
                  }}
                >
                  New Job
                </Button>
              </div>
            </div>
          ))}
        {activeTab.alias === "events" &&
          (events.length ? (
            <ClubFeedTab
              loading={loading}
              posts={events}
              isAdmin={true}
              club={clubData}
              getClubInfo={getClubInfo}
              openEventPage={openEventPage}
              editPost={openEditPostModal}
              containerClassName={"px-[0px]"}
            />
          ) : (
            <div className="text-center mt-48">
              <RectangleStackIcon className="mx-auto h-20 w-20 text-slate-400" />
              <h3 className="mt-2 text-lg font-semibold text-slate-900">
                No events found
              </h3>
              <p className="mt-1 text-sm text-slate-500">
                Start by creating an event post
              </p>
              <div className="mt-6">
                <Button
                  variant={"default"}
                  icon={SvgPlus}
                  onClick={() => {
                    
                    setCreatePostModalOpen(true);
                  }}
                >
                  New Event
                </Button>
              </div>
            </div>
          ))}
      </div>
      <CreateApplicationModal
        isOpen={createApplicationModalOpen}
        applicationToEdit={applicationToEdit}
        applicationQuestionsToEdit={applicationQuestionsToEdit}
        closeModal={() => setCreateApplicationModalOpen(false)}
        authUser={authUser}
        addApplicationToList={addApplication}
        clubId={club.id}
      />
      <CreateJobOrEventModal
        type={activeTab.alias === "jobs" ? "job" : "event"}
        isOpen={createPostModalOpen}
        closeModal={() => setCreatePostModalOpen(false)}
        club={clubData}
        getClubInfo={getClubInfo}
      />
      <EditPostModal
        isOpen={editPostModalOpen}
        closeModal={() => setEditPostModalOpen(false)}
        club={clubData}
        post={currentPost}
        getClubInfo={getClubInfo}
      />
    </div>
  );
};

export default connect(mapStateToProps)(ClubAdminEngagePage);
